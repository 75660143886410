import { useLocation } from "react-router-dom";

export function IsHomePage() {
  const { pathname } = useLocation();
  return  false;// pathname === '/';
}

export function IsAsterizmPage() {
  const { pathname } = useLocation();
  return  pathname === '/';
}
