import { useDispatch, useSelector } from "react-redux";
import { setConnectingWallet } from "../../features/walletService/walletService";
import { WalletList } from "../../utils/NetworkUtil";

export default function ConnectWalletWaiting({}) {
  let walletService = useSelector((state) => state.walletServiceProvider);
  let dispatch = useDispatch();

  const loadingWallet = WalletList.find(
    (v) => v.key == walletService.connectingWalletkey
  );

  const openInstallPage = (loadingWallet) => {
    window.open(loadingWallet.installUrl, "_blank");
    dispatch(
      setConnectingWallet({
        status: false,
        providerType: loadingWallet.key,
      })
    );
  };

  return (
    <div
      className={
        "connect-to-a-wallet-popup popup dark " +
        (walletService.connectingWallet ? "active" : "")
      }
      id="connectWalletPopup"
    >
      <div className="popup__container">
        {loadingWallet && (
          <a
            className="popup__close"
            href="#"
            onClick={() =>
              dispatch(
                setConnectingWallet({
                  status: false,
                  providerType: loadingWallet.key,
                })
              )
            }
          ></a>
        )}

        <h1 className="connect-to-a-wallet-popup__title popup__title">
          Connect to the wallet
        </h1>
        <p className="connect-to-a-wallet-popup__description">
          Go to the provider's website and install the wallet
        </p>

        {/* <div className="connect-to-a-wallet-popup__wallet-cnt">
          <span className="connect-to-a-wallet-popup__initializing">
            <span className="loader-white"></span>
            Initializing
          </span>
        </div> */}

        {loadingWallet && (
          <div className="connect-to-a-wallet-popup__wallet-cnt">
            <img src={loadingWallet.smallImg} width="40" height="40" alt="" />
            Opening {loadingWallet.title}
            <span className="connect-to-a-wallet-popup__waiting">
              <span className="loader-white"></span>
              <span className="connect-to-a-wallet-popup__waiting-caption">
                Waiting for connection
              </span>
            </span>
          </div>
        )}

        {loadingWallet && !loadingWallet.isInstalled() && (
          <button
            className="connect-to-a-wallet-popup__cta btn btn_dark"
            onClick={() => openInstallPage(loadingWallet)}
          >
            Install MetaMask
          </button>
        )}
      </div>
    </div>
  );
}
