import { BigNumber, ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import { ROUND_DECIMAL_CNT } from "../constants/TransferConstants";
import ERC20Abi from "../utils/ERC20Abi";
import { getNetworks } from "../utils/NetworkUtil";
import { TRANSFER_SENDED } from "../constants/ProcessSteps";
import {
  checkNetworkWalletType,
  isEverWallet,
  isEvmWallet,
  isVenomWallet,
} from "../utils/WalletUtil";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
} from "../constants/ProviderTypes";
import { EverWalletWebProvider } from "../services/everWallet/everWalletWebProvider";
import { VenomWalletWebProvider } from "../services/venom/venomWalletWebProvider";
import { EvmAbiContractAbi } from "../abis/EvmContractAbi";

export function GetContractAmounts(incomeNetworks) {
  // let abi = [
  //   {
  //     inputs: [],
  //     name: "maxUsdAmount",
  //     outputs: [
  //       {
  //         internalType: "uint256",
  //         name: "",
  //         type: "uint256",
  //       },
  //     ],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  // ];

  let cnt = useRef(0);
  useEffect(() => {
    if (incomeNetworks.length > 0) getAmounts();
  }, [incomeNetworks]);

  const getAmounts = async () => {
    if (cnt.current > 0) return;
    cnt.current += 1;
    incomeNetworks.forEach((currentNetwork) => {
      if (isEvmWallet(currentNetwork.route)) {
        getEVMAmount(currentNetwork.route);
      }   else if (isEverWallet(currentNetwork.route)) {
        getEverAmount(currentNetwork.route);
      }
    });
  };

  const getEVMAmount = async (currentNetwork) => {
    // if (currentNetwork.id != 21) return;
    console.log(currentNetwork, "currentNetwork");
    const provider = new ethers.providers.JsonRpcProvider(
      currentNetwork.rpcUrls[0]
    );
    const signer = provider.getSigner(0);

    const customContract = new ethers.Contract(
      currentNetwork.gasSenderAddress,
      EvmAbiContractAbi,
      provider
    );

    let amount = await customContract.minUsdAmountPerChain();
    console.log(amount);
  };

  const getEverAmount = async (
    network
  ) => {
      let balance = await EverWalletWebProvider.getContractMin(
        network.gasSenderAddress
      );

  };
  

  return;
}
