import { configureStore } from "@reduxjs/toolkit";
import notificationPopupSlice from "../features/dialogs/notificationPopupSlice";
import errorService from "../features/errorService";
import mobileMenuService from "../features/walletService/mobileMenuService";
import walletServiceProvider from "../features/walletService/walletService";
import switchNetworkSlice from "../features/dialogs/switchNetworkSlice";
import menuSlice from "../features/menu/menuSlice";
import selectedNetworkSlice from "../features/selectedNetworkSlice";
import venomwalletService from "../features/walletService/venomwalletService";
import cookiePopupSlice from "../features/cookiePopupSlice";
import PopupManagerSlice from "../features/PopupManagerSlice";
import TransactionLimitsManagerSlice from "../features/TransactionLimitsManagerSlice";

export default configureStore({
  reducer: {
    walletServiceProvider: walletServiceProvider,
    mobileMenuProvide: mobileMenuService,
    errorServiceProvider: errorService,
    menuManagment: menuSlice,
    switchNetworkPopup: switchNetworkSlice,
    notificationPopupManager: notificationPopupSlice,
    selectedNetworkManager: selectedNetworkSlice,
    venomWalletManager: venomwalletService,
    cookiePopupManager: cookiePopupSlice,
    popupManager: PopupManagerSlice,
    limitManager: TransactionLimitsManagerSlice,
  },
});
