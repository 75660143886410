import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../constants/ChainTypes";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_VENOM,
} from "../constants/ProviderTypes";
import { initialState } from "../features/walletService/walletService";

export const isNetworkWalletConnected = (connectedWallets, currentNetwork) => {
  let mainWallet = connectedWallets.find( connectedWallet=> currentNetwork.wallets.includes(connectedWallet.providerType))
  return mainWallet ? true : false;
};

export const getNetworkWalletAddress = (connectedWallets, currentNetwork) => {
  let mainWallet = connectedWallets.find( connectedWallet=> currentNetwork.wallets.includes(connectedWallet.providerType))
  // console.log((mainWallet && (currentNetwork.chainType == CHAIN_TYPE_EVM || (currentNetwork.type == CHAIN_TYPE_TVM && mainWallet.networkId == currentNetwork.id) )),currentNetwork.chainType ,mainWallet?.networkId , currentNetwork.id,mainWallet?.accountAddress,'currentNetwork.id')
  return (mainWallet && (currentNetwork.chainType == CHAIN_TYPE_EVM || (currentNetwork.chainType == CHAIN_TYPE_TVM && mainWallet.networkId == currentNetwork.id) )) ? mainWallet?.accountAddress :  null;
};

export const getNetworkWallet = (connectedWallets, currentNetwork) => {
  let wallet = initialState.walletInfo;

  if(!currentNetwork){
    return wallet;
  }

  let mainWallet = connectedWallets.find( connectedWallet=> currentNetwork.wallets.includes(connectedWallet.providerType))

  return mainWallet || wallet;
};

export const getWalletType = (networkType) =>{
  let walletTypes = [];
  switch (networkType) {
    case CHAIN_TYPE_EVM: walletTypes = [PROVIDER_METAMASK]; break;
    case CHAIN_TYPE_TVM: walletTypes = [PROVIDER_EVERWALLET]; break;
    default:
      break;
  }
  return walletTypes;
}

export const checkNetworkWalletType = (network,walletTypes) =>{
  return network.wallets.some( walletProvider => walletTypes.includes(walletProvider));
}

export const isEvmWallet = (network) =>{
  return checkNetworkWalletType(network,[PROVIDER_METAMASK]);
}

export const isEverWallet = (network) =>{
  return checkNetworkWalletType(network,[PROVIDER_EVERWALLET]);
}

export const isVenomWallet = (network) =>{
  return checkNetworkWalletType(network,[PROVIDER_VENOM]);
}