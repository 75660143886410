export const DEFAULT_TOKEN_SYMBOL = "USDT";
export const ETH_CHAIN_ID = "1";
export const BSC_CHAIN_ID = "56";
export const BSC_TESTNET_CHAIN_ID = "97";
export const POLYGON_CHAIN_ID = "137";
export const POLYGON_TESTNET_CHAIN_ID = "80001";
export const TOKEN_DIRECTION_FROM = "From";

export const COMMISION_AMOUNT_PERCENT = 5;
// export const MIN_TRANSFER_USDT = 100;
// export const MAX_TRANSFER_USDT = 300;
// export const MIN_TRANSFER_USDT_PER_NETWORK = 5;

export const ROUND_DECIMAL_CNT = 7;
export const SHOW_DECIMAL_CNT = 13;

export const IS_MINUS_FEE_FROM_AMOUNT = false;
export const IS_REQUIRED_CHECK_TRANSACTION_ID = true;

export const STABLE_DECIMALS = 6;

export const EVM_NETWORKS_COUNT = 9;
export const NON_EVM_NETWORKS_COUNT = 0;

export const MIN_NETWORK_TRANSFER_AMOUNT = 5;
export const MAX_NETWORK_TRANSFER_AMOUNT = 50;
export const MIN_TOTAL_TRANSFER_AMOUNT = 5;
export const MAX_TOTAL_TRANSFER_AMOUNT = 200;