import { BigNumber, ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import { ROUND_DECIMAL_CNT } from "../constants/TransferConstants";
import ERC20Abi from "../utils/ERC20Abi";
import { getNetworks } from "../utils/NetworkUtil";
import { TRANSFER_SENDED } from "../constants/ProcessSteps";
import { checkNetworkWalletType, isEverWallet, isEvmWallet, isVenomWallet } from "../utils/WalletUtil";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
} from "../constants/ProviderTypes";
import { EverWalletWebProvider } from "../services/everWallet/everWalletWebProvider";
import { VenomWalletWebProvider } from "../services/venom/venomWalletWebProvider";

export function ListenCoinReceive(incomeNetworks, step, updateStateIncomes,venomProvider) {
  let cnt = useRef(0);

  // const inCoinsRecieved = useRef([]);
  const [inCoinsRecieved, setInCoinsReceived] = useState([]);

  const coinReceiveEvent = "CoinsReceivedEvent";
  let abi = [
    "event CoinsReceivedEvent(uint amount, uint _transactionId, address dstAddress)",
  ];

  useEffect(() => {
    if (step == TRANSFER_SENDED) {
      cnt.current += 1;
    }
    if (cnt.current == 1 && step == TRANSFER_SENDED) {
      incomeNetworks.forEach((network) => {
        if(network.isChecked){
          listenNetworkEvent(network.route);
        }
      });
    }

    if (step != TRANSFER_SENDED) {
      cnt.current = 0;
      setInCoinsReceived([]);
    }
  }, [step, incomeNetworks]);

  useEffect(() => {
    if (inCoinsRecieved.length > 0) {
      let isRealted = updateStateIncomes(
        inCoinsRecieved,
        inCoinsRecieved[inCoinsRecieved.length - 1].transactionId
      );
      // console.log(isRealted,inCoinsRecieved,'isRealted')
      if (isRealted) {
        
        const network = getNetworks().find(
          (v) => v.id == inCoinsRecieved[inCoinsRecieved.length - 1].networkId
        );

        if(isEvmWallet(network)){
          inCoinsRecieved[
            inCoinsRecieved.length - 1
          ].customContract.removeAllListeners(coinReceiveEvent);
        }

        if(isEverWallet(network) || isVenomWallet(network)){
          inCoinsRecieved[
            inCoinsRecieved.length - 1
          ].customContract.stopProducer();
        }

      }
    }
  }, [inCoinsRecieved]);

  const listenNetworkEvent = async (currentNetwork) => {
    if (isEvmWallet(currentNetwork)) {
      listenNetworkEvmEvent(currentNetwork);
    }
    if (isEverWallet(currentNetwork)) {
      listenNetworkEverEvent(currentNetwork);
    }
    if (isVenomWallet(currentNetwork)) {
      listenNetworkVenomEvent(currentNetwork);
    }
  };

  const listenNetworkEverEvent = async (currentNetwork) => {
    const customContract = EverWalletWebProvider.getContractListener(currentNetwork.gasSenderAddress);

    customContract.on((event) => {
      if (event && event.event && event.event == coinReceiveEvent) {
        let newEventItem = {
          networkId: currentNetwork.id,
          chainId: currentNetwork.chainId,
          amount: event.data.amount,
          transactionId: BigNumber.from(event.data._transactionId).toString(),
          dstAddress: event.data._dstAddress,
          transactionHash: event.transaction.id.hash,
          customContract: customContract,
        };
        setInCoinsReceived((inArray) => [...inArray, newEventItem]);
      }
    });
  };

  const listenNetworkVenomEvent = async (currentNetwork) => {
    const customContract = VenomWalletWebProvider.getContractListener(currentNetwork.gasSenderAddress,venomProvider);

    customContract.on((event) => {
      console.log(event,coinReceiveEvent,'coinReceiveEvent');
      if (event && event.event && event.event == coinReceiveEvent) {
        let newEventItem = {
          networkId: currentNetwork.id,
          chainId: currentNetwork.chainId,
          amount: event.data.amount,
          transactionId: BigNumber.from(event.data._transactionId).toString(),
          dstAddress: event.data._dstAddress,
          transactionHash: event.transaction.id.hash,
          customContract: customContract,
        };
        setInCoinsReceived((inArray) => [...inArray, newEventItem]);
      }
    });
  };

  const listenNetworkEvmEvent = async (currentNetwork) => {
    const provider = new ethers.providers.JsonRpcProvider(
      currentNetwork.rpcUrls[0]
    );

    const customContract = new ethers.Contract(
      currentNetwork.gasSenderAddress,
      abi,
      provider
    );

    customContract.on(
      coinReceiveEvent,
      (amount, _transactionId, dstAddress, event) => {
        let newEventItem = {
          networkId: currentNetwork.id,
          chainId: currentNetwork.chainId,
          amount: amount,
          transactionId: BigNumber.from(_transactionId).toString(),
          dstAddress: dstAddress,
          transactionHash: event.transactionHash,
          customContract: customContract,
        };
        setInCoinsReceived((inArray) => [...inArray, newEventItem]);
        // console.log(inCoinsRecieved,newEventItem.transactionId,'CoinsReceived')
      }
    );
  };

  return inCoinsRecieved.current;
}
