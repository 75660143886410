import { useContext, useEffect, useRef, useState } from "react";
import { getWalletTokenAmounts } from "../features/walletService/balanceService";
import { VenomContext } from "../App";

export function GetTokenListByNetwork(selectedNetwork,walletAccountAddress) {
  const [tokenList, setTokenList] = useState([]);
  const [isTokenLoading, setTokenLoading] = useState(false);
  const lastLoadTokenId = useRef([]);
  const loadedBalances = useRef([]);
  const onProcessBalance = useRef([]);
  const venomProvider = useContext(VenomContext);

  useEffect(() => {
    makeCryptoList();
  }, [selectedNetwork,walletAccountAddress]);

  const makeCryptoList = async () => {
    if(!selectedNetwork){
      updateTokenList([]);
      return;
    }
    setTokenLoading(true);

    const uniqueId = Math.floor(Math.random() * 100) + ":" + selectedNetwork.id;
    lastLoadTokenId.current = uniqueId;

    let list = [];

    let networkBalanceList = loadedBalances.current.find(
      (v) =>
        v.walletAddress == walletAccountAddress &&
        v.id == selectedNetwork.id
    );

    selectedNetwork.cryptos.forEach((crypto) => {
      
      let balance = "";
      let isBalanceLoaded = false;

      if (networkBalanceList) {

        let token = networkBalanceList.tokens.find(
          (v) => v.contractAddress == crypto.contractAddress
        );
        if (token) {
          balance = token.balance;
          isBalanceLoaded = true;
        }
      }

      list.push({
        logoURI: crypto.logoURI,
        name: crypto.name,
        symbol: crypto.symbol,
        balance: balance || 0,
        decimals: crypto.decimals,
        contractAddress: crypto.contractAddress,
        isBalanceLoaded: isBalanceLoaded,
        formatedBalance: balance != 0 ? balance : null,
      });
    });
    
    updateTokenList(list);

    if (walletAccountAddress && !networkBalanceList && !onProcessBalance.current.includes(selectedNetwork.id)) {
      onProcessBalance.current.push(selectedNetwork.id)
      handLoadBalance(list,uniqueId);
    }

  };

  const updateTokenList = (tokenList = []) => {
    setTokenList(sorrtingList(tokenList));
    setTokenLoading(false);
  };

  const handLoadBalance = async (list,uniqueId) =>{
    list = await loadBalancesFromNetwork(list);
    if (lastLoadTokenId.current == uniqueId) {
      updateTokenList([...list]);
    }
  }
  const loadBalancesFromNetwork = async (list) => {
    let networkBalances = await getWalletTokenAmounts(
      selectedNetwork,
      list,
      walletAccountAddress,
      venomProvider
    );
    if(networkBalances)
    loadedBalances.current.push(networkBalances);
    return list;
  };

  const sorrtingList = (inTokens) => {
    let tokens = [];
    tokens = [
      ...inTokens
        // .filter((v) => v.balance != 0)
        .sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance)),
    ];

    return tokens;
  };

  return [tokenList,isTokenLoading];
}
