import { useEffect } from "react";
import { useRef } from "react";

export function MouseClickOutside(ref, isOpen, onClickOutsideHandle) {
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        onClickOutsideHandle();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isOpen, onClickOutsideHandle]);
}
