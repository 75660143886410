import { createSlice } from '@reduxjs/toolkit'

export const selectedNetworkSlice = createSlice({
  name: 'SelectedNetwork',
  initialState: {
    network: null,
  },
  reducers: {
    setNetwork: (state,action) => {
      state.network = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setNetwork } = selectedNetworkSlice.actions

export default selectedNetworkSlice.reducer
