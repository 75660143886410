export const ERROR_AMOUNT_LESS_TOTAL_NETWORK_TRANSFER = 10;
export const ERROR_AMOUNT_LESS_TOTAL_TRANSFER = 11;
export const ERROR_AMOUNT_OVER_TOTAL_TRANSFER = 12;
export const ERROR_INCORRECT_AMOUNT_FORMAT = 13;
export const ERROR_AMOUNT_OVER_PER_NETWORK_CODE = 14;

export const ERROR_AMOUNT_LESS_TOTAL_TRANSFER_TEXT = 'Transfer amount is less then %AMOUNT%';
export const ERROR_AMOUNT_OVER_TOTAL_TRANSFER_TEXT = 'Amount big then max amount %AMOUNT%';
export const ERROR_AMOUNT_LESS_TOTAL_NETWORK_TRANSFER_TEXT ="For %CNT% networks minimum is %AMOUNT%";
export const ERROR_INCORRECT_AMOUNT_FORMAT_TEXT ="Incorrect amount format";
export const ERROR_AMOUNT_OVER_PER_NETWORK ="For each network max amount is %AMOUNT%";
