const mobileMenuItems = [
  {
    url: "/",
    title: "Finding a bridge",
  },
  {
    url: "/reports",
    title: "Analytics",
  },
  {
    url: "/",
    title: "Networks",
  },
  {
    url: "/",
    title: "Cashback",
    liClass: "main-menu__item_marked",
  },
  // {
  //   url: "/",
  //   title: "Reports",
  //   liClass: "main-menu__item_disabled main-menu__item_soon",
  // },
  {
    url: "/",
    title: "More",
  },
];

const desctopMenuItems = [
  {
    url: "https://chainspot.io/",
    title: "Portal",
    isDisabled: false,
    isExternal: false,
  },
  {
    url: "https://app.chainspot.io/",
    title: "Router",
    isDisabled: false,
    isExternal: false,
  },
  {
    url: "/",
    title: "Gas Charger",
    isDisabled: false,
  },
  {
    url: "#",
    title: "Wallet Health",
    isDisabled: true,
    isExternal: false,
    liClass: "soon", //main-menu__item_new
  },
  {
    url: "https://bit.ly/3rAmDSw",
    title: "🔥 Free VPN",
    isDisabled: false,
    isExternal: true,
  },
  // {
  //   url: "https://forms.gle/eReSdyEo1jKsHhFB7",
  //   title: "Whitelist",
  //   isDisabled: false,
  //   isExternal: true
  // },
  // {
  //   url: "https://www.chainspot.io/report",
  //   title: "Reports",
  //   isDisabled: false,
  //   isExternal: true,
  //   liClass: "main-menu__item_soon", //main-menu__item_new
  // },
];
export function getMenuItems(isMobile = false) {
  return isMobile ? mobileMenuItems : desctopMenuItems;
}
