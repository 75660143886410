import { useEffect } from "react";
import { ymQuizNo, ymQuizYes } from "../../utils/YandexUtl";

export default function GasQuizStep1({ setQuizStep }) {

  const onOkhandle = () =>{
    ymQuizYes();
    setQuizStep(2)
  }

  const onNohandle = () =>{
    ymQuizNo();
    setQuizStep(3)
  }
  return (
    <div className="quiz__step-1">
      <span className="quiz__text">Do you have friends in web3 community?</span>
      <div className="quiz__cta">
        <button className="btn btn_dark" onClick={() => onOkhandle()}>
          Yes
        </button>
        <button className="btn btn_gray" onClick={() => onNohandle()}>
          No
        </button>
      </div>
    </div>
  );
}
