import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import {
  INIT,
  TRANSFER_SENDED,
  TRANSFER_STARTED,
} from "../constants/ProcessSteps";
import { getNetworks } from "../utils/NetworkUtil";
import {
  checkNetworkWalletType,
  isEverWallet,
  isEvmWallet,
  isVenomWallet,
} from "../utils/WalletUtil";
import { EverWalletWebProvider } from "../services/everWallet/everWalletWebProvider";
import { VenomWalletWebProvider } from "../services/venom/venomWalletWebProvider";

export function MessageSentEvent(
  currentNetwork,
  step,
  sendNetworksCnt,
  transactionHash,
  setTransactionIdIncomes,
  venomProvider
) {
  let cnt = useRef(0);
  const transactionList = useRef([]);
  const listeningContract = useRef(null);

  const eventSendName = "GasSendEvent";
  const abi = [
    // "event MessageSent(uint64 _dstChainId, address _destination, uint _transactionId, bool _forceOrdered, bytes _payload)",
    "event GasSendEvent(uint64 _dstChainId, uint _transactionId, bytes _payload)",
  ];

  const clear = () => {
    stopListeningContract();
    cnt.current = 0;
    // transactionList.current = [];
    // listeningContract.current = null;
  };

  const stopListeningContract = () => {
    if (listeningContract.current) {
      if (isEvmWallet(currentNetwork)) {
        listeningContract.current?.removeAllListeners(eventSendName);
      }
      if (isEverWallet(currentNetwork) || isVenomWallet(currentNetwork)) {
        listeningContract.current?.stopProducer();
      }
    }
  };

  const startListing = () => {
    if (isEvmWallet(currentNetwork)) {
      startEvmListing();
    }
    if (isEverWallet(currentNetwork)) {
      startEverListing();
    }
    if (isVenomWallet(currentNetwork)) {
      startVenomListing();
    }
  };

  const startEvmListing = () => {
    const provider = new ethers.providers.JsonRpcProvider(
      currentNetwork.rpcUrls[0]
    );
    // console.log(currentNetwork,'currentNetwork startListing')
    listeningContract.current = new ethers.Contract(
      currentNetwork.gasSenderAddress,
      abi,
      provider
    );

    const curListener = listeningContract.current.on(
      eventSendName,
      (dstChainId, _transactionId, payload, event) => {
        addMessage(dstChainId,_transactionId,event ? event.transactionHash : null)
      }
    );
  };

  const addMessage = (dstChainId,transactionId,transactionHash) => {
    const sendNetwork = getNetworks().find( v=> v.sendChainId == dstChainId);
    transactionList.current = [
      ...transactionList.current,
      {
        networkId: sendNetwork.id,
        chainId: dstChainId,
        transactionId: transactionId,
        transactionHash: transactionHash,
      },
    ];
    setTransactionIdIncomes(transactionList.current);

  }

  const startEverListing = () => {
    listeningContract.current = EverWalletWebProvider.getContractListener(
      currentNetwork.gasSenderAddress
    );

    listeningContract.current.on((event) => {
      if (event && event.event && event.event == eventSendName) {
        addMessage(event.data._dstChainId,event.data._transactionId,event.transaction?.id.hash)
      }
    });
  };

  const startVenomListing = () => {
    listeningContract.current = VenomWalletWebProvider.getContractListener(
      currentNetwork.gasSenderAddress,
      venomProvider
    );

    listeningContract.current.on((event) => {
      if (event && event.event && event.event == eventSendName) {
        addMessage(event.data._dstChainId,event.data._transactionId,event.transaction?.id.hash)
      }
    });
  };

  useEffect(() => {
    if (currentNetwork && step == TRANSFER_STARTED) {
      cnt.current += 1;
    }
    if (cnt.current == 1 && step == TRANSFER_STARTED) {
      startListing();
      return;
    }
    if (transactionHash) {
      const sendedCtn = transactionList.current.filter(
        (v) =>
          (typeof transactionHash == "string" &&
            v.transactionHash == transactionHash) ||
          (Array.isArray(transactionHash) &&
            transactionHash.includes(v.transactionHash))
      ).length;
      if (sendedCtn == sendNetworksCnt) {
        clear();
      }
    }
  }, [currentNetwork, step, sendNetworksCnt, transactionHash,transactionList.current]);
  return [transactionList.current];
}
