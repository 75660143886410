import { ym } from "./BrowserUtil";

export function ymSwapClick() {
  ym(94298147, "reachGoal", "click_swap");
}

export function ymTransSubmit() {
  ym(94298147, "reachGoal", "transaction_submitted");
}

export function ymTransPending() {
  ym(94298147, "reachGoal", "transaction_pending");
}

export function ymTransInComed() {
  ym(94298147, "reachGoal", "transaction_completed");
}

export function ymTransSendError() {
  ym(94298147, "reachGoal", "transaction_rejected");
}

export function ymQuizYes() {
  ym(94298147, "reachGoal", "click_yes");
}

export function ymQuizNo() {
  ym(94298147, "reachGoal", "click_no");
}

export function ymTwitterYes() {
  ym(94298147, "reachGoal", "click_twitter_yes");
}

export function ymTwitterNo() {
  ym(94298147, "reachGoal", "click_twitter_no");
}

export function ymTgYes() {
  ym(94298147, "reachGoal", "click_tg_yes");
}

export function ymTgNo() {
  ym(94298147, "reachGoal", "click_tg_no");
}

export function ymDiscordYes() {
  ym(94298147, "reachGoal", "click_disk_yes");
}

export function ymDiscordNo() {
  ym(94298147, "reachGoal", "click_disk_no");
}

export function ymSwapAgain() {
  ym(94298147, "reachGoal", "click_swap_again");
}

export function ymCopyTxId() {
  ym(94298147, "reachGoal", "click_tx_id");
}

export function ymClickTxScan() {
  ym(94298147,'reachGoal','click_tx_scan')
}

export function ymTwitterInfo() {
  ym(94298147,'reachGoal','click_twitter')
}

export function ymTgInfo() {
  ym(94298147,'reachGoal','click_tg')
}

export function ymlTransactionCancel() {
  ym(94298147,'reachGoal','transaction_canceled')
}


