import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNetworks } from "../utils/NetworkUtil";
import { MetamaskWebProvider } from "../services/metamask/MetamaskWebProvider";
import { MAX_NETWORK_TRANSFER_AMOUNT, MAX_TOTAL_TRANSFER_AMOUNT, MIN_NETWORK_TRANSFER_AMOUNT, MIN_TOTAL_TRANSFER_AMOUNT } from "../constants/TransferConstants";
import { CHAIN_TYPE_EVM } from "../constants/ChainTypes";

const initialState = {
  minUsdAmount: MIN_TOTAL_TRANSFER_AMOUNT,
  maxUsdAmount: MAX_TOTAL_TRANSFER_AMOUNT,
  minUsdAmountPerChain: MIN_NETWORK_TRANSFER_AMOUNT,
  maxUsdAmountPerChain: MAX_NETWORK_TRANSFER_AMOUNT,
};

export const TransactionLimitsManagerSlice = createSlice({
  name: " TransactionLimitsManagerSlice",
  initialState,
  reducers: {
    setLimits: (state, action) => {
      state.minUsdAmount = action.payload.minUsdAmount;
      state.maxUsdAmount = action.payload.maxUsdAmount;
      state.minUsdAmountPerChain = action.payload.minUsdAmountPerChain;
      state.maxUsdAmountPerChain = action.payload.maxUsdAmountPerChain;
    },
  },
});



export const { setLimits } = TransactionLimitsManagerSlice.actions;

export default TransactionLimitsManagerSlice.reducer;

export const loadLimits = createAsyncThunk(
  "loadLimits/contract",
  async (network, { dispatch }) => {
    if(network.chainType == CHAIN_TYPE_EVM){
      let limits = await MetamaskWebProvider.getNetworkLimits(network);
      dispatch(setLimits(limits));
    }
  }
);
