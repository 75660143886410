import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialState,
  walletConnectDialogToggle,
} from "../../features/walletService/walletService";
import { MouseClickOutside } from "../../hooks/MouseClickOutside";
import { getNetworkImageByChainId, WalletList } from "../../utils/NetworkUtil";
import { WalletListPopup } from "./WalletListPopup";
import { isMobileDevice } from "../../utils/BrowserUtil";

function WallectConnectButtonBlock() {
  let dispatch = useDispatch();

  let show = useSelector(
    (state) => state.walletServiceProvider.showWalletConnect
  );

  const isMobile = isMobileDevice();
  return (
    <button
      className={
        isMobile
          ? "wallet-info-button-mobile dark select-button"
          : "btn-select__btn btn btn_dark"
      }
      onClick={() => dispatch(walletConnectDialogToggle())}
    >
      {!isMobile && "Connect wallets"}
      {isMobile && (
        <>
          <span>Connect</span>
          <img src="/img/connect-btn-arrow.svg" />
        </>
      )}
    </button>
  );
}

export function WallectConnectedButtonBlock() {
  let dispatch = useDispatch();

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  // let walletInfo = useSelector(
  //   (state) => state.walletServiceProvider.walletInfo
  // );
  const isMobile = isMobileDevice();
  return (
    <>
      <button
        className={
          "wallet-info-button dark select-button wallet-info-button_multiple "
        }
        onClick={() => dispatch(walletConnectDialogToggle())}
      >
        {connectedWallets.map((wallet) => (
          <div className="wallet-info-button__logo" key={wallet.providerType}>
            <img
              className="wallet-info-button__main-logo"
              src={WalletList.find((v) => v.key == wallet.providerType)?.img}
              alt=""
            />
            <img
              className="wallet-info-button__network-logo"
              src={getNetworkImageByChainId(wallet.networkChainId)}
              alt=""
            />
          </div>
        ))}
        {!isMobile && connectedWallets.length == 1 && (
          <div className="wallet-info-button__wallet-num">
            <div>
              <span className="wallet-info-button__num">{`${connectedWallets[0].accountAddress.slice(
                0,
                6
              )}...${connectedWallets[0].accountAddress.slice(-4)}`}</span>
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#003980"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M22.0002 10.9692V13.0292C22.0002 13.5792 21.5602 14.0292 21.0002 14.0492H19.0402C17.9602 14.0492 16.9702 13.2592 16.8802 12.1792C16.8202 11.5492 17.0602 10.9592 17.4802 10.5492C17.8502 10.1692 18.3602 9.94922 18.9202 9.94922H21.0002C21.5602 9.96922 22.0002 10.4192 22.0002 10.9692Z" />
              <path d="M20.47 15.55H19.04C17.14 15.55 15.54 14.12 15.38 12.3C15.29 11.26 15.67 10.22 16.43 9.48C17.07 8.82 17.96 8.45 18.92 8.45H20.47C20.76 8.45 21 8.21 20.97 7.92C20.75 5.49 19.14 3.83 16.75 3.55C16.51 3.51 16.26 3.5 16 3.5H7C6.72 3.5 6.45 3.52 6.19 3.56C3.64 3.88 2 5.78 2 8.5V15.5C2 18.26 4.24 20.5 7 20.5H16C18.8 20.5 20.73 18.75 20.97 16.08C21 15.79 20.76 15.55 20.47 15.55ZM13 9.75H7C6.59 9.75 6.25 9.41 6.25 9C6.25 8.59 6.59 8.25 7 8.25H13C13.41 8.25 13.75 8.59 13.75 9C13.75 9.41 13.41 9.75 13 9.75Z" />
            </svg>
          </div>
        )}
      </button>
    </>
  );
}

function WalletConnectButton() {
  // let walletInfo = useSelector(
  //   (state) => state.walletServiceProvider.walletInfo
  // );

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );


  const isConnected = connectedWallets.filter( v=> v.accountAddress != null).length > 0; 

  let show = useSelector(
    (state) => state.walletServiceProvider.showWalletConnect
  );
  const wrapperRef = useRef(null);

  let dispatch = useDispatch();

  const onClickOutsideHandle = () => {
    dispatch(walletConnectDialogToggle());
  };

  // if(show){
  MouseClickOutside(wrapperRef, show, onClickOutsideHandle);

  return (
    <div className="header__cta" ref={wrapperRef}>
      <div className={"btn-select " + (show ? "active" : "")}>
        {!isConnected ? (
          <WallectConnectButtonBlock />
        ) : (
          <WallectConnectedButtonBlock />
        )}
        <WalletListPopup />
      </div>
    </div>
  );
}
export default WalletConnectButton;
