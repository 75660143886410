import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChooseWalletItem from "./ChooseWalletItem";
import { WalletList } from "../../../utils/NetworkUtil";
import { connectWallet, showAgreementDialog, walletTypeDialogToggle } from "../../../features/walletService/walletService";
// import Cookies from 'universal-cookie';

function ChooseWallet() {
  const dispatch = useDispatch();
  let showWalletTypePopup = useSelector(
    (state) => state.walletServiceProvider.showWalletTypePopup
  );
  let connectingWallet = useSelector(
    (state) => state.walletServiceProvider.connectingWallet
  );

  const [isChecked, setChecked] = useState(false);

  const walletServiceList = WalletList;

  const onWalletSelect = (walletKey) => {
    // deleteAgreed()
    dispatch(connectWallet(walletKey));
  };

  return (
    <div
      className={
        "choose-wallet-popup popup " + (showWalletTypePopup ? "active" : "")
      }
      id="chooseWalletPopup"
    >
      <div className="popup__container">
        <div className="choose-wallet-popup__header">
          <a
            className="popup__close"
            href="#"
            onClick={() => dispatch(walletTypeDialogToggle())}
          ></a>
          <h1 className="choose-wallet-popup__title popup__title">
            Connect wallet
          </h1>
        </div>
        {connectingWallet && (
          <div className="choose-wallet-popup__loading">
            <span className="loader-large"></span>
            <span>Loading...</span>
          </div>
        )}

        {!connectingWallet && (
          <>
            <div
              className={
                "choose-wallet-popup__wallet-list " +
                (isChecked  ? "" : "disabled")
              }
            >
              {walletServiceList &&
                walletServiceList.map((walletService) => (
                  <ChooseWalletItem
                    key={walletService.key}
                    walletService={walletService}
                    onSelect={onWalletSelect}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default ChooseWallet;
