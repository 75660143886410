import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../../features/dialogs/notificationPopupSlice";

export default function NotificationPopup() {
  const notificationState = useSelector(
    (state) => state.notificationPopupManager
  );
  const dispatch = useDispatch();

  return (
    <>
      {notificationState.show && (
        <div
          className={`alert  ${notificationState.alertType} ${
            notificationState.show ? "active" : ""
          }`}
        >
          {notificationState.title && (
            <h3 className="alert__title">{notificationState.title}</h3>
          )}
          <p
            className="alert__caption"
            dangerouslySetInnerHTML={{ __html: notificationState.caption }}
          ></p>
          {notificationState.strBlocks &&
            Array.isArray(notificationState.strBlocks) &&
            notificationState.strBlocks.map((block,index) => (
              <p
                className="alert__caption"
                key={index}
                dangerouslySetInnerHTML={{ __html: block }}
              ></p>
            ))}
          <button
            className="alert__close"
            onClick={() => dispatch(closeNotification())}
          ></button>

        </div>
      )}
    </>
  );
}
