import React, { useState } from "react";
import { getMenuItems } from "../../data/menu";
import { MenuList } from "../Header/TopMenu/MenuList";
import { Socials } from "./Socials";

export default function Footer() {

  const links = getMenuItems(false);

    return (
      <footer className="footer footer_dark">
        <div className="wrap">
          <div className="footer__cnt">
            <a className="footer__logo" href="/" target={"_blank"}>
              <img src="/img/chainspot-logo_dark.png" alt="" />
            </a>
            <div className="footer__menu-block">
              <MenuList links={links} navClass={"footer"} />
              <Socials/>
            </div>
            <div className="footer__copyrights-block">
              <span className="footer__copyrights">
                © {new Date().getFullYear()} Chainspot. All rights reserved
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
}

