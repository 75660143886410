import { useEffect, useState } from "react";
import {
  STATE_ATTENTION,
  STATE_REJECTED,
  STATE_SUCCESS,
  STATE_WAITING,
} from "../../constants/GasFormState";
import {
  APPROVE_STARTED,
  TRANSFER_CONFIRMED,
  TRANSFER_SENDED,
  TRANSFER_FINISHED,
} from "../../constants/ProcessSteps";
import { copyToBuffer } from "../../utils/TransferApiUtil";
import { ymCopyTxId } from "../../utils/YandexUtl";

export default function GasTransactionHash({ transactionHash }) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const onCopy = () => {
    ymCopyTxId();
    copyToBuffer(transactionHash);
    setIsCopied(true);
  };

  if (!transactionHash) {
    return;
  }
  return (
    <>
      <div className="id-block">
        <span className="id-block__title">TX ID:</span>
        <span className="id-block__wallet">
          {transactionHash &&
            (transactionHash.length > 40
              ? transactionHash.substring(1, 40) + ".."
              : transactionHash)}
        </span>
        <button className="copy-btn" onClick={() => onCopy()}></button>
        {isCopied && (
          <div className="id-block__success active">
            <img src="img/ok-icon.svg" alt="" />
            ID was coppied
          </div>
        )}
      </div>
    </>
  );
}
