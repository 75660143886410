import { useEffect } from "react";
import { DISCORD_ADDRESS, TELEGRAM_GROUP, TWITTER_LINK, TWITTER_LINK_HOME } from "../../constants/SocialLinks";
import { ymDiscordNo, ymTgNo, ymTwitterNo } from "../../utils/YandexUtl";

export default function GasQuizStep3() {
  useEffect(() => {
    console.log('init 3');
  }, []);

  return (
    <div className="quiz__step-3">
      <span className="quiz__text">
        Find like-minded people in our community:
      </span>
      <div className="quiz__cta">
        <a href={TWITTER_LINK_HOME} className="btn btn_twitter" target="_blank" onClick={() => ymTwitterNo()}>
          <img src="img/twitter-icon_alt.svg" alt="" />
          Share your experience
        </a>
        <a href={TELEGRAM_GROUP} className="blue-social-link" target="_blank" onClick={() => ymTgNo()}>
          <img src="img/telegram-icon_blue.svg" alt="" />
        </a>
        <a href={DISCORD_ADDRESS} className="blue-social-link" target="_blank" onClick={() => ymDiscordNo()}>
          <img src="img/discord-icon_blue.svg" alt="" />
        </a>
      </div>
    </div>
  );
}
