//Вместо HECO и Bitgert ставим BOBA и CELO
//Минус BOBA, CELO, Everscale и Arbitrum

import { CHAIN_TYPE_EVM } from "./ChainTypes";
import { PROVIDER_METAMASK } from "./ProviderTypes";

//Ethereum,Polygon,Fantom,Optimism,BNB,Avalanche
export const DEF_MAINNET_CHAINS = [
  // {
  //   chainId: 1,
  //   name: "Ethereum",
  //   sendChainId: 1,
  //   chainName: "Ethereum",
  //   img: "ethereum-24x24.svg",
  //   formClass: "ethereum",

  //   hasContract: false,
  //   ownerAddress: "",
  //   translatorAddress: "",
  //   initializerAddress: "",
  //   gasSenderAddress: "",

  //   server:{
  //     symbol:"ETH",
  //     title:"Ethereum"
  //   },

  //   rpcUrls: [
  //     "https://eth.llamarpc.com",
  //     "https://ethereum.blockpi.network/v1/rpc/public",
  //   ],
  //   nativeCurrency: {
  //     name: "ETH",
  //     symbol: "ETH", // 2-6 characters long
  //     decimals: 18,
  //   },
  //   cryptos: [
  //     {
  //       name: "USDT",
  //       symbol: "USDT",
  //       contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  //       decimals: 6,
  //       logoURI: null,
  //     },
  //     {
  //       name: "USDC",
  //       symbol: "USDC",
  //       contractAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  //       decimals: 6,
  //       logoURI: null,
  //     },
  //     {
  //       name: "DAI",
  //       symbol: "DAI",
  //       contractAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  //       decimals: 18,
  //       logoURI: null,
  //     },
  //     {
  //       name: "BUSD",
  //       symbol: "BUSD",
  //       contractAddress: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
  //       decimals: 18,
  //       logoURI: null,
  //     },
  //   ],

  //   blockExplorerUrls: ["https://etherscan.io"],
  //   scannName: "Etherscan",
  // },
  {
    id: 21,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 137,
    name: "Polygon",
    sendChainId: 137,
    chainName: "Polygon",
    img: "polygon-24x24.svg",
    smallImg: "polygon-14x14.svg",
    formClass: "polygon",

    server: {
      symbol: "POL",
      title: "Polygon",
    },

    hasContract: true,
    gasSenderAddress: "0xC9ad96EF0037899CFC0505cf7A9c3eE3E54026Ab",

    rpcUrls: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.maticvigil.com/",
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      // {
      //   name: "DAI",
      //   symbol: "DAI",
      //   contractAddress: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      //   decimals: 18,
      //   logoURI: null,
      //   isShowOnSend: true,
      // },
      {
        name: "BUSD",
        symbol: "BUSD",
        contractAddress: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://polygonscan.com"],
    scannName: "Polygonscan",
  },
  {
    id: 22,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 250,
    name: "Fantom",
    sendChainId: 250,
    chainName: "Fantom",
    img: "fantom-24x24.svg",
    smallImg: "fantom-24x24.svg",
    formClass: "fantom",

    server: {
      symbol: "FTM",
      title: "Fantom",
    },

    hasContract: true,
    gasSenderAddress: "0x01561f34D1C2085bafAb6fED29507c91E1Ce2573",

    rpcUrls: ["https://rpc.ftm.tools/", "https://rpcapi.fantom.network"],
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    cryptos: [
      // {
      //   name: "DAI",
      //   symbol: "DAI",
      //   contractAddress: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
      //   decimals: 18,
      //   logoURI: null,
      //   isShowOnSend: true,
      // },
      // {
      //   name: "USDC",
      //   symbol: "USDC",
      //   contractAddress: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      //   decimals: 6,
      //   logoURI: null,
      //   isShowOnSend: true,
      // },
    ],
    blockExplorerUrls: ["https://ftmscan.com"],
    scannName: "Fantomscan",
  },
  {
    id: 23,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 10,
    name: "Optimism",
    sendChainId: 10,
    chainName: "Optimism",
    img: "Optimism-24x24.svg",
    smallImg: "Optimism-24x24.svg",
    formClass: "optimism",

    server: {
      symbol: "OPT",
      title: "Optimism",
    },

    hasContract: true,
    gasSenderAddress: "0x2f526ea87dAc16EB82e07dFa09FaF7973D12d869",

    rpcUrls: [
      "https://1rpc.io/op",
      "https://endpoints.omniatech.io/v1/op/mainnet/public",
      "https://mainnet.optimism.io/",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      // {
      //   name: "DAI",
      //   symbol: "DAI",
      //   contractAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      //   decimals: 18,
      //   logoURI: null,
      //   isShowOnSend: true,
      // },
    ],
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
    scannName: "Optimismscan",
  },
  {
    id: 24,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 56,
    name: "BNB",
    sendChainId: 56,
    chainName: "BNB",
    img: "bsc-24x24.svg",
    smallImg: "bsc-24x24.svg",
    formClass: "bnb",

    server: {
      symbol: "BSC",
      title: "BSC",
    },

    hasContract: true,
    gasSenderAddress: "0xf3EC4C24Aee3099aA2C8168822B13E98d05335ec",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
      "https://bsc-dataseed.binance.org/",
      "https://bsc-dataseed1.defibit.io/",
      "https://bsc-dataseed1.ninicoin.io/",
    ],
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x55d398326f99059fF775485246999027B3197955",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
      // {
      //   name: "DAI",
      //   symbol: "DAI",
      //   contractAddress: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      //   decimals: 18,
      //   logoURI: null,
      //   isShowOnSend: true,
      // },
      // {
      //   name: "BUSD",
      //   symbol: "BUSD",
      //   contractAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      //   decimals: 18,
      //   logoURI: null,
      //   isShowOnSend: true,
      // },
    ],
    blockExplorerUrls: ["https://bscscan.com"],
    scannName: "Bscscan",
  },
  {
    id: 25,
    isInUse: true,
    isComingSoon: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 43114,
    name: "Avalanche",
    sendChainId: 43114,
    chainName: "Avalanche",
    img: "avalanche-subnets-24x24.svg",
    smallImg: "avalanche-subnets-14x14.svg",
    formClass: "avalanche",

    server: {
      symbol: "AVA",
      title: "Avalanche",
    },

    hasContract: true,
    gasSenderAddress: "0x4f21a1AfFfE94C3DB0BbA0FA0B0d09082788b18D",

    rpcUrls: [
      "https://avalanche.blockpi.network/v1/rpc/public",
      "https://avalanche-evm.publicnode.com",
      "https://1rpc.io/avax/c",
    ],
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://snowtrace.io"],
    scannName: "Avalanchescan",
  },
  {
    id: 26,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 42161,
    name: "Arbitrum",
    sendChainId: 42161,
    chainName: "Arbitrum",
    img: "Arbitrum One-24x24.svg",
    smallImg: "Arbitrum One-24x24.svg",
    formClass: "arbitrum",

    server: {
      symbol: "ARB",
      title: "Arbitrum",
    },

    hasContract: true,
    gasSenderAddress: "0x78Ac64e3a9bAD5975f19a7a649380532039C6926",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/arbitrum/one/public",
      "https://arbitrum.blockpi.network/v1/rpc/public",
      "https://arb1.arbitrum.io/rpc",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      // {
      //   name: "DAI",
      //   symbol: "DAI",
      //   contractAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      //   decimals: 18,
      //   logoURI: null,
      //   isShowOnSend: true,
      // },
    ],
    blockExplorerUrls: ["https://arbiscan.io"],
    scannName: "Arbitrumscan",
  },
  {
    id: 27,
    isInUse: true,
    isComingSoon: false,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 1313161554,
    name: "Aurora",
    sendChainId: 1313161554,
    chainName: "Aurora",
    img: "aurora-24x24.svg",
    smallImg: "aurora-24x24.svg",
    formClass: "aurora",

    server: {
      symbol: "AUR",
      title: "Aurora",
    },

    hasContract: true,
    gasSenderAddress: "0xD8094a1591E9Fa348d719311d613742f40574a36",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/aurora/mainnet/public",
      "https://mainnet.aurora.dev",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xB12BFcA5A55806AaF64E99521918A4bf0fC40802",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x4988a896b1227218e4a686fde5eabdcabd91571f",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://explorer.aurora.dev"],
    scannName: "Aurorascan",
  },
  {
    id: 28,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 42220,
    name: "Celo",
    sendChainId: 42220,
    chainName: "Celo",
    img: "celo-24x24.svg",
    smallImg: "celo-14x14.svg",
    formClass: "celo",

    server: {
      symbol: "CEL",
      title: "Celo",
    },

    hasContract: true,
    gasSenderAddress: "0x7d65a556822BA3D780956e2A9D610E2A2cd8a043",

    rpcUrls: ["https://forno.celo.org"],
    nativeCurrency: {
      name: "CELO",
      symbol: "CELO",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x617f3112bf5397D0467D315cC709EF968D9ba546",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xef4229c8c3250C675F21BCefa42f58EfbfF6002a",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "cUSD",
        symbol: "cUSD",
        contractAddress: "0x765DE816845861e75A25fCA122bb6898B8B1282a",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://celoscan.io"],
    scannName: "Celoscan",
  },
  {
    id: 29,
    isInUse: true,
    isComingSoon: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 288,
    name: "BOBA",
    sendChainId: 288,
    chainName: "BOBA",
    img: "boba-24x24.svg",
    smallImg: "boba-24x24.svg",
    formClass: "boba",

    server: {
      symbol: "BOB",
      title: "Boba",
    },

    hasContract: true,
    gasSenderAddress: "0x8A78bd7BF2cC2Cbc6f666C35d37f75aa201E312A",

    rpcUrls: ["https://mainnet.boba.network"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://bobascan.com"],
    scannName: "Bobascan",
  },
  // {
  //   id: 20,
  //   isInUse: true,
  //   isComingSoon: true,
  //   wallets: [PROVIDER_METAMASK],
  //   chainType: CHAIN_TYPE_EVM,
  //   chainId: 2000,
  //   name: "Nibiru",
  //   sendChainId: 1101,
  //   chainName: "Nibiru",
  //   img: "nibiru-24x24.svg",
  //   smallImg: "nibiru-24x24.svg",
  //   formClass: "zkevm",

  //   server: {
  //     symbol: "Nibiru",
  //     title: "Nibiru",
  //   },

  //   hasContract: true,
  //   gasSenderAddress: "ss",

  //   rpcUrls: ["https://rpc.ankr.com/polygon_zkevm"],
  //   nativeCurrency: {
  //     name: "NIBI",
  //     symbol: "NIBI",
  //     decimals: 18,
  //   },
  //   cryptos: [],
  //   blockExplorerUrls: ["https://zkevm.polygonscan.com"],
  //   scannName: "Polygon zkEVMscan",
  // },
  {
    id: 19,
    isInUse: true,
    isComingSoon: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 1101,
    name: "Polygon ZkEVM",
    sendChainId: 1101,
    chainName: "Polygon ZkEVM",
    img: "zkevm-24x24.svg",
    smallImg: "zkevm-14x14.svg",
    formClass: "zkevm",

    server: {
      symbol: "PZK",
      title: "Polygon zkEVM",
    },

    hasContract: true,
    gasSenderAddress: "0x2da63B662823d39d8AC022281C8C66EBB03B0253",

    rpcUrls: ["https://zkevm-rpc.com"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x1E4a5963aBFD975d8c9021ce480b42188849D41d",
        decimals: 6,
        logoURI: null,
        isShowOnSend: false,
      },
    ],
    blockExplorerUrls: ["https://zkevm.polygonscan.com"],
    scannName: "Polygon zkEVMscan",
  },
  {
    id: 18,
    isInUse: false,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 32520,
    name: "Bitgert",
    sendChainId: 32520,
    chainName: "Bitgert",
    img: "bitgert-24x24.svg",
    smallImg: "bitgert-24x24.svg",
    formClass: "bitgert",

    server: {
      symbol: "BTG",
      title: "BitGert",
    },

    hasContract: false,
    gasSenderAddress: "0x36D72b295BE459b0a3E4E8c39cb9a9e15aC750eB",

    rpcUrls: ["https://rpc.icecreamswap.com"],
    nativeCurrency: {
      name: "Brise",
      symbol: "Brise",
      decimals: 18,
    },
    cryptos: [],
    blockExplorerUrls: ["https://brisescan.com"],
    scannName: "Bitgertscan",
  },
  {
    id: 17,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 5165,
    name: "Bahamut",
    sendChainId: 5165,
    chainName: "Bahamut",
    img: "fastex-24x24.svg",
    smallImg: "fastex-14x14.svg",
    formClass: "fastex",

    server: {
      symbol: "FSX",
      title: "Bahamut",
    },

    hasContract: true,
    gasSenderAddress: "0xA67865d45d1ee4C9e71474f913f2E372764336Ba",

    rpcUrls: ["https://rpc1.sahara.bahamutchain.com"],
    nativeCurrency: {
      name: "FTN",
      symbol: "FTN",
      decimals: 18,
    },
    cryptos: [],
    blockExplorerUrls: ["https://ftnscan.com"],
    scannName: "Ftnscan",
  },
  {
    id: 15,
    isInUse: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 59144,
    name: "Linea",
    sendChainId: 59144,
    chainName: "Linea",
    img: "linea-24x24.svg",
    smallImg: "linea-14x14.svg",
    formClass: "linea",

    server: {
      symbol: "LNX",
      title: "Linea",
    },

    hasContract: true,
    gasSenderAddress: "0xA67865d45d1ee4C9e71474f913f2E372764336Ba",

    rpcUrls: ["https://rpc.linea.build"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [],
    blockExplorerUrls: ["https://lineascan.build/"],
    scannName: "Lineascan",
  },
  {
    id: 31,
    isInUse: true,
    isComingSoon: true,
    wallets: [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 8192,
    name: "Torus",
    sendChainId: 8192,
    chainName: "Torus",
    img: "TQF-24x24.svg",
    smallImg: "TQF-14x14.svg",
    formClass: "venidium",

    server: {
      symbol: "XVM",
      title: "Torus",
    },

    hasContract: true,
    gasSenderAddress: "0xA67865d45d1ee4C9e71474f913f2E372764336Ba",

    rpcUrls: ["https://rpc.linea.build"],
    nativeCurrency: {
      name: "TQF",
      symbol: "TQF",
      decimals: 18,
    },
    cryptos: [],
    blockExplorerUrls: ["https://toruscan.com"],
    scannName: "Toruscan",
  },
];
