import {  ethers } from "ethers";
import { ALERT_WARNING } from "../../constants/AlertTypes";
import { showNotifaction } from "../../features/dialogs/notificationPopupSlice";
import { setError } from "../../features/walletService/walletService";

const ERROR_TRON_NOT_INSTALLED = "TronLink extension is not installed";
const ERROR_TRON_LOCKED = "Tron is locked. Please unlock";

export const TronLinkWebProvider = {
  tronWeb: window.tronWeb,
  tronLink: window.tronLink,
  isConnected: function () {
    return window.tronWeb && window.tronLink;
  },
  isUnlocked: function () {
    return window.tronWeb && window.tronWeb.defaultAddress.base58;
  },
  getBalanceByAddress: async function (address) {
    //if wallet installed and logged , getting TRX token balance
    return this.isConnected()
      ? await window.tronWeb.trx.getBalance(address)
      : 0;
  },
  getFrozenBalance: function (tempBalance) {
    let tempFrozenBalance = 0;
    //checking if any frozen balance exists
    if (
      !tempBalance.frozen &&
      !tempBalance.account_resource?.frozen_balance_for_energy
    ) {
      tempFrozenBalance = 0;
    } else {
      if (
        tempBalance.frozen &&
        tempBalance.account_resource?.frozen_balance_for_energy
      ) {
        tempFrozenBalance =
          tempBalance.frozen[0].frozen_balance +
          tempBalance.account_resource.frozen_balance_for_energy.frozen_balance;
      }
      if (
        tempBalance.frozen &&
        !tempBalance.account_resource.frozen_balance_for_energy
      ) {
        tempFrozenBalance = tempBalance.frozen[0].frozen_balance;
      }
      if (
        !tempBalance.frozen &&
        tempBalance.account_resource.frozen_balance_for_energy
      ) {
        tempFrozenBalance =
          tempBalance.account_resource.frozen_balance_for_energy.frozen_balance;
      }
    }
    return tempFrozenBalance;
  },
  transferToAddress: async function (
    fromChainId,
    tronTokenAddress,
    toAddress,
    amount,
    decimal,
    maxFeeLimit = 10000000
  ) {
    const node = this.getNodeInfo();
    if (node == null) {
      return;
    }
    if (Number(node.configNodeInfo.p2pVersion) === fromChainId) {
      // get contract, TRON-TOKEN-ADDRESS is Tron token address
      const contract = await window.tronLink.tronWeb
        .contract()
        .at(tronTokenAddress);

      // use contract tranfer to send transaction
      // must install ethers package for use ethers.utils.parseUnits
      const result = await contract
        .transfer(
          toAddress, //address _to
          parseInt(
            ethers.utils.parseUnits(
              amount, // must string
              decimal // must number
            )._hex
          ) //amount
        )
        .send({
          feeLimit: maxFeeLimit,
        })
        .then((output) => {
          // this output is transaction hash, do something ....
        })
        .catch((e) => {
          console.error(e, "erro");
          // if transfer error .......
        });
    } else {
      // if node not correct .....
    }
  },
  getNodeInfo: async function () {
    return await window.tronLink.tronWeb.trx.getNodeInfo();
  },
  getAccountDetail: async function () {
    const res = await window.tronLink.request({
      method: "tron_requestAccounts",
    });
    if (!res) {
      // if tronlink is not connected .....
    } else if (res.code === 200) {
      // get tronlink current chains config
    }
  },
  getDetails: async function () {
    let tempBalance = this.getBalanceByAddress(window.tronWeb.defaultAddress.base58);
    let tempFrozenBalance = 0; // this.getFrozenBalance(tempBalance);
    if(window.tronWeb.defaultAddress.base58){
      return {
        name: window.tronWeb.defaultAddress.name,
        address: window.tronWeb.defaultAddress.base58,
        balance: tempBalance.balance / 1000000,
        frozenBalance: tempFrozenBalance / 1000000,
        network: window.tronWeb.fullNode.host,
      };
    }else{
      return null
    }
  },
  delay:async function(){
    await new Promise(resolve => setTimeout(() => {
      resolve()
    }, 500))
  },
  // autoConnect: async function (dispatch, walletInfo) {
  //   if (!window.tronWeb) {
  //     dispatch(setError(ERROR_TRON_NOT_INSTALLED));
  //     return walletInfo;
  //   }
  //   this.getAccountDetail();
  //   const detail = await this.getDetails();
  //   walletInfo.accountAddress = detail.address;
  //   walletInfo.networkChainId = detail.network;
  //   walletInfo.isConnected = true;
  //   return walletInfo;
  // },
  connect: async function (dispatch, walletInfo) {
    await this.delay();
    if (!this.isConnected()) {
      dispatch(setError(ERROR_TRON_NOT_INSTALLED));
      return walletInfo;
    }
    if (!this.isUnlocked()) {
      dispatch(setError(ERROR_TRON_NOT_INSTALLED));
      return walletInfo;
    }
    
    if (!this.isConnected()) {
      dispatch(showNotifaction({alertType:ALERT_WARNING,caption:ERROR_TRON_LOCKED}))
      return null;
    }
    await this.getAccountDetail();

    // try {
    const detail = await this.getDetails();
    if(detail){
      walletInfo.accountAddress = detail.address;
      walletInfo.networkChainId = detail.network;
      walletInfo.isConnected = true;
  
    }
    // } catch (error) {
    //   dispatch(setError(error.message));
    // }
    return walletInfo;
  },
  getNativeBalance: async function (address) {},
  requestApprove: async function (tokenAddress, accountAddress, amount) {},
  // getBalance: async function (token, walletInfo, network) {},
  getTokenBalance: async function (token, walletInfo) {},
  addChain: async function (selectedNetwork, dispatch) {},
  changeChain: async function (walletInfo, dispatch, network) {},
  sendTransaction: async function (transactionData, dispatch) {
    dispatch(setError(null));
  },
};
