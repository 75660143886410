import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import WalletConnectButton, {
  WallectConnectedButtonBlock,
} from "../../../components/WalletConnectButton/WalletConnectButton";
import { getMenuItems } from "../../../data/menu";
import { toggleMobileMenu } from "../../../features/menu/menuSlice";
import {
  APP_URL,
  BLOG_LINK,
  TELEGRAM_GROUP,
  TWITTER_LINK,
} from "../../../constants/SocialLinks";
import {
  connectWallet,
  walletConnectDialogToggle,
} from "../../../features/walletService/walletService";

function MobileMenu() {
  let links = getMenuItems(false);
  links = links.filter(v=> v.title != 'Whitelist');

  // const [links] = useState(getMenuItems(false));
  const isShowMenu = useSelector((state) => state.menuManagment.showMobileMenu);
  let dispatch = useDispatch();

  const activeClass = (route) => {
    return window.location.pathname === route ? "active" : null;
  };
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  return (
    <>
      <div className={"mobile-menu dark " + (isShowMenu ? "active" : "")}>
        <div className="mobile-menu__header">
          <a className="logo-link" href="#">
            <img className="logo" src="img/chainspot-logo_dark.png" alt="" />
          </a>
          <button
            className="mobile-menu__close"
            onClick={() => dispatch(toggleMobileMenu())}
          ></button>
        </div>
        <nav className="main-menu dark mobile">
          <ul className="main-menu__items">
            {links.map((item, index) => (
              <li
                key={item.title}
                className={`main-menu__item  ${
                  !item.isDisabled && activeClass(item.url) ? "active" : ""
                } ${item.isDisabled ? "main-menu__item_disabled" : ""} ${
                  item.liClass ? item.liClass : ""
                } `}
              >
                <NavLink
                  to={item.url}
                  className="main-menu__link"
                  target="_blank"
                >
                  {item.url == "/gas" && (
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.0002 11.4809V13.541C22.0002 14.091 21.5602 14.5409 21.0002 14.5609H19.0402C17.9602 14.5609 16.9702 13.7709 16.8802 12.6909C16.8202 12.0609 17.0602 11.4709 17.4802 11.0609C17.8502 10.6809 18.3602 10.4609 18.9202 10.4609H21.0002C21.5602 10.4809 22.0002 10.9309 22.0002 11.4809Z"
                        stroke="#142438"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 11.0078V9.00781C2 6.28781 3.64 4.38781 6.19 4.06781C6.45 4.02781 6.72 4.00781 7 4.00781H16C16.26 4.00781 16.51 4.0178 16.75 4.0578C19.33 4.3578 21 6.26781 21 9.00781V10.4578H18.92C18.36 10.4578 17.85 10.6778 17.48 11.0578C17.06 11.4678 16.82 12.0578 16.88 12.6878C16.97 13.7678 17.96 14.5578 19.04 14.5578H21V16.0078C21 19.0078 19 21.0078 16 21.0078H13.5"
                        stroke="#142438"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.80644 17.8084H5.73769V22.3084C5.73769 23.3584 6.30644 23.5709 7.00019 22.7834L11.7314 17.4084C12.3127 16.7521 12.0689 16.2084 11.1877 16.2084H9.25644V11.7084C9.25644 10.6584 8.68769 10.4459 7.99394 11.2334L3.26269 16.6084C2.68769 17.2709 2.93144 17.8084 3.80644 17.8084Z"
                        stroke="#142438"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  {item.title == "Whitelist" && (
                    <div className="main-menu__link-icon-cnt">
                      <img src="img/whitelist-icon.svg" alt="" />
                    </div>
                  )}
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="mobile-menu__socials">
          <a href={TELEGRAM_GROUP} target="_blank">
            <img src="img/telegram-icon.svg" alt="" />
          </a>
          <a href={TWITTER_LINK} target="_blank">
            <img src="img/twitter-icon.svg" alt="" />
          </a>
        </div>
        <div className="mobile-menu__cta">
          {connectedWallets.length == 0 && (
            <a
              className="btn btn_dark"
              href="#"
              onClick={() => dispatch(walletConnectDialogToggle())}
            >
              Connect wallet
            </a>
          )}
          {connectedWallets.length > 0 && <WallectConnectedButtonBlock />}
        </div>
      </div>
    </>
  );
}
export default MobileMenu;
