import { useDispatch, useSelector } from "react-redux";
import { setConnectingWallet } from "../../features/walletService/walletService";
import { WalletList } from "../../utils/NetworkUtil";
import { toggleInstallingPopup } from "../../features/walletService/walletService";

export default function WalletInstallPopup({}) {
  let walletService = useSelector((state) => state.walletServiceProvider);
  let dispatch = useDispatch();

  const installWallet = WalletList.find(
    (v) => v.key == walletService.installWalletKey
  );

  if (!installWallet) {
    return;
  }

  const openInstallPage = () => {
    window.open(installWallet.installUrl, "_blank");
    dispatch(toggleInstallingPopup());
  };

  return (
    <div className={"connect-to-a-wallet-popup popup dark active"}>
      <div className="popup__container">
        <a className="popup__close" href="#" onClick={() => dispatch(toggleInstallingPopup())}></a>

        <h1 className="connect-to-a-wallet-popup__title popup__title">
          Connect to a wallet
        </h1>
        <p className="connect-to-a-wallet-popup__description">
          Go to the provider's website and install the wallet
        </p>

        <button
          className="connect-to-a-wallet-popup__cta btn btn_dark"
          onClick={() => openInstallPage()}
        >
          Install {installWallet.title}
        </button>
      </div>
    </div>
  );
}
