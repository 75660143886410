import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSwitchNetworkDialog } from "../../../features/dialogs/switchNetworkSlice";
// import { closeSwitchNetworkDialog } from "../../../../features/dialogs/switchNetworkSlice";
import { getNetworkImageByChainId } from "../../../utils/NetworkUtil";

function SwitchNetworkDialog() {
  const networks = useSelector(
    (state) => state.switchNetworkPopup.networks
  );
  const isActive = useSelector(
    (state) => state.switchNetworkPopup.showDialog
  );

  const selectedRoute = useSelector(
    (state) => state.switchNetworkPopup.selectedRoute
  );

  
  let dispatch = useDispatch();
  const sortedNetworks = ([...networks]).sort((a, b) => parseFloat(a.chainId) - parseFloat(b.chainId))
 
  return (
    <div className={"switch-network-popup inner-popup "+(isActive ? 'active': '')}>
      <div className="switch-network-popup__header">
        <button className="switch-network-popup__back popup-back-btn inner-popup-close" onClick={() => {
                dispatch(closeSwitchNetworkDialog(null))
              }}>
          Back
        </button>
        <h2 className="switch-network-popup__title popup__title">Switch network</h2>
        <button className="switch-network-popup__close inner-popup-close" onClick={() => {
                dispatch(closeSwitchNetworkDialog(null))
              }}></button>
      </div>
      <div className="switch-network-popup__networks-list">
        {sortedNetworks.map((route) => (
            <a
              className={`select__item ${
                selectedRoute && selectedRoute.chainId == route.chainId
                  ? "select__item_active"
                  : ""
              }`}
              key={route.id}
              onClick={() => {
                dispatch(closeSwitchNetworkDialog(route))
              }}
            >
              <span className="select__item-logo">
                <img
                  src={getNetworkImageByChainId(route.chainId)}
                  alt={route.name}
                />
              </span>
              <span className="select__item-name">{route.name}</span>
            </a>
          ))}
      </div>
    </div>
  );
}
export default SwitchNetworkDialog;
