import { DISCORD_ADDRESS, TELEGRAM_GROUP } from "../../constants/SocialLinks";

export default function GasHelp() {

  return (
    <div className="need-help">
    Need help?
    <div className="need-help__links">
      <a href={TELEGRAM_GROUP} target="_blank">
        <img src="/img/telegram-icon_help.svg" alt=""/>
      </a>
      <a href={DISCORD_ADDRESS} target="_blank">
        <img src="/img/discord-icon_help.svg" alt=""/>
      </a>
    </div>
  </div>
);
}
