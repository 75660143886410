import { useDispatch, useSelector } from "react-redux";
import { DISCORD_ADDRESS, TELEGRAM_GROUP } from "../../constants/SocialLinks";
import { getNetworks } from "../../utils/NetworkUtil";
import GetNetworkRow from "./GasNetworksRow";
import { useState } from "react";
import { CHAIN_TYPE_TVM } from "../../constants/ChainTypes";

export default function GasNetworks({
  formData,
  incomeNetworks,
  setIncomeNetworks,
  onNetworkChecked,
  updateAddress,
  updateItems,
}) {
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const dispatch = useDispatch();

  const networks = getNetworks();
  const [allChecked, setAllCecked] = useState(false);

  const openEditAddress = (network) => {
    const itemList = [...incomeNetworks];
    const findedNetwork = itemList.find((v) => v.route.id == network.route.id);
    findedNetwork.isEditOpen = true;
    setIncomeNetworks(itemList);
  };

  const toggleAllCheck = () => {
    setAllCecked(!allChecked);
    updateItems(formData.amountFrom, null, null, allChecked ? 2 : 1);
  };

  return (
    <section className="gas-station__networks">
      {/* <span className="gas-station__networks-caption">
        <img src="/img/networks-icon.svg" alt="" />
        <span>
          Select from{" "}
          <span className="gas-station__networks-caption-value">{EVM_NETWORKS_COUNT} EVM</span>{" "}
          and{" "}
          <span className="gas-station__networks-caption-value">{NON_EVM_NETWORKS_COUNT} Non-EVM</span>{" "} 
          networks to receive the gas tokens
        </span>
      </span> */}
      <span className="gas-station__networks-caption">
        <span>
          <span className="blue-gradient-text">Join our chat</span> and share
          your opinion about the product!
        </span>
        <span className="gas-station__networks-caption-socials">
          <a
            href={TELEGRAM_GROUP}
            className="blue-social-link"
            target={"_blank"}
          >
            <img src="/img/telegram-icon_blue.svg" alt="" />
          </a>
          <a
            href={DISCORD_ADDRESS}
            className="blue-social-link"
            target={"_blank"}
          >
            <img src="/img/discord-icon_blue.svg" alt="" />
          </a>
        </span>
      </span>

      <div className="networks-filters-mobile">
        <button className="filter-btn" onClick={() => toggleAllCheck()}>
          {allChecked ? "Reset all" : "Select all"}
        </button>
        {/* <button className="filter-btn">
          <img src="img/sort-arrows-icon.svg" alt="" />
          Non-EVM first
        </button> */}
      </div>

      <div className="gas-station__networks-table-header">
        <label className="gas-station__networks-table-header-checkbox">
          <input
            className="checkbox__input"
            type="checkbox"
            name=""
            id=""
            checked={allChecked}
            onChange={() => toggleAllCheck()}
          />
          <span className="checkbox__button"></span>
        </label>
        <span className="gas-station__networks-table-header-item i-asset">
          Asset
        </span>
        <span className="gas-station__networks-table-header-item i-type">
          {/* <img src="/img/filter-icon.svg" alt="" /> */}
          Network type
        </span>
        <span className="gas-station__networks-table-header-item i-amount">
          Amount in fiat
        </span>
        <span className="gas-station__networks-table-header-item i-address">
          Wallet address
        </span>
      </div>
      <div className="gas-station__networks-table">
        {incomeNetworks.map((network) => (
          <GetNetworkRow
            network={network}
            openEditAddress={openEditAddress}
            key={network.route.id}
            onNetworkChecked={onNetworkChecked}
            updateAddress={updateAddress}
          />
        ))}
      </div>
    </section>
  );
}
