import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOKEN_DIRECTION_FROM } from "../constants/TransferConstants";
import { GetTokenListByNetwork } from "../hooks/GetTokenListByNetwork";
import SwitchNetworkDialog from "../Layout/Header/SwitchNetworkDialog/SwitchNetworkDialog";
import { getNetworkImageByChainId, getTokenImg } from "../utils/NetworkUtil";
import { getNetworkWallet } from "../utils/WalletUtil";

export default function ChooseTokenPopup(props) {
  const [filtertext, setFilterText] = useState("");
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [avaliableNetworks, setAvialableNetworks] = useState([]);
  const chooseTokenPopupElement = useRef();

  let allWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  let dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--tokens-list-height",
      chooseTokenPopupElement.current.offsetHeight - 164 + "px"
    );
  }, []);

  useEffect(() => {
    if (props.show) {
      //setTokenList([]);
      setAvialableNetworks(props.routes);
      setSelectedRoute(props.selectedTokenRoute);
      document.body.classList.add("fixed");
    } else {
      setSelectedRoute(null);
      document.body.classList.remove("fixed");
    }
  }, [props.show]);

  function setRoute(route) {
    if (route.chainId != selectedRoute.chainId) {
      setSelectedRoute(route);
    }
  }

  const walletInfo = getNetworkWallet(allWallets, selectedRoute);

  const [tokenList, isLoading] = GetTokenListByNetwork(
    selectedRoute,
    walletInfo.accountAddress
  );

  return (
    <div
      className={`top-up-popup popup dark ${props.show ? "active" : ""}`}
      id="chooseTokenPopup"
      ref={chooseTokenPopupElement}
    >
      <div className="popup__container">
        <a
          className="popup__close"
          href="#"
          onClick={() => {
            props.closePopup();
          }}
        ></a>
        <div className="top-up-popup__header">
          <h1 className="top-up-popup__title popup__title">Top up from</h1>
        </div>

        <div className="top-up-popup__tokens-cnt">
          <div className="top-up-popup__source-tokens">
            {avaliableNetworks
              .filter((network) =>
                network.cryptos.some((crypto) => crypto.isShowOnSend)
              )
              .sort((a, b) => parseFloat(a.chainId) - parseFloat(b.chainId))
              .map((route) => (
                <a
                  className={`top-up-popup__source-tokens-item ${
                    selectedRoute && selectedRoute.chainId == route.chainId
                      ? "active"
                      : ""
                  }`}
                  key={route.chainId}
                  onClick={() => {
                    if (selectedRoute || selectedRoute.chainId != route.chainId)
                      setRoute(route);
                  }}
                >
                  <img
                    src={getNetworkImageByChainId(route.chainId)}
                    alt={route.name}
                    style={{ width: "24px" }}
                  />
                  {route.name}
                </a>
              ))}
          </div>

          <div className="top-up-popup__tokens-list">
            {isLoading && <span>Loading...</span>}
            {!isLoading &&
              selectedRoute &&
              tokenList.filter((v) =>
                v.symbol.toLowerCase().startsWith(filtertext.toLowerCase())
              ).length === 0 && (
                <div className="top-up-popup__tokens-list-empty">
                  <img src="./images/empty-list-icon.svg" alt="" />
                  <span>Token not found</span>
                  <span>
                    Try again with a different wording, or change the network
                  </span>
                </div>
              )}

            <div className="top-up-popup__all-tokens">
              {selectedRoute &&
                tokenList
                  .filter((v) =>
                    v.symbol.toLowerCase().startsWith(filtertext.toLowerCase())
                  )
                  // .sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance))
                  .map((token) => (
                    <div
                      className="tokens-list-item"
                      key={token.symbol}
                      onClick={() => {
                        setFilterText("");
                        props.closePopup({
                          route: selectedRoute,
                          token: token,
                        });
                      }}
                    >
                      <div className="tokens-list-item__left">
                        <img
                          className="tokens-list-item__logo"
                          src={getTokenImg(token)}
                          alt=""
                        />
                        <p className="tokens-list-item__title">
                          <span className="tokens-list-item__name">
                            {token.symbol}
                          </span>
                          <span className="tokens-list-item__network">
                            on {selectedRoute.name} Network
                          </span>
                        </p>
                      </div>
                      <div className="tokens-list-item__right">
                        <span className="tokens-list-item__value">
                          {token.formatedBalance}
                        </span>
                        {/* {!token.isBalanceLoaded && (
                          <div className="dot-flashing"></div>
                        )} */}
                        <span
                          className="tokens-list-item__currency"
                          style={{ visibility: "hidden" }}
                        >
                          $1.25
                        </span>
                        {props.directType != TOKEN_DIRECTION_FROM && (
                          <span className="tokens-list-item__currency">
                            {token.isLiqvid ? "liquid" : "non-liquid"}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <SwitchNetworkDialog />
    </div>
  );
}
