import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes/routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  accountChanged,
  autoConnectWallet,
  networkChanged,
} from "./features/walletService/walletService";
import Layout from "./Layout/Layout";
import "./assets/scss/style.scss";
import { EverWalletWebProvider } from "./services/everWallet/everWalletWebProvider";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "./constants/ChainTypes";
import { VenomProviderActions } from "./hooks/VenomProviderActions";
import React from "react";
import { useRef } from "react";
import { isCoockieSeted, isDisclaimerRead } from "./utils/BrowserUtil";
import {
  TYPE_DISCLAIMER,
  popupToggleByName,
} from "./features/PopupManagerSlice";

export const VenomContext = React.createContext(null);

function App() {
  let dispatch = useDispatch();

  const isFirstLoading = useRef(0);
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        dispatch(
          networkChanged({
            networkType: CHAIN_TYPE_EVM,
            networkChainId: _chainId,
          })
        );
      });
      window.ethereum.on("accountsChanged", (accounts) => {
        if (typeof accounts == "object" && accounts.length > 0)
          dispatch(accountChanged(accounts[0]));
      });

      listenEver();
      // window.ethereum.on('disconnect', () => {
      //   window.location.reload();
      // })
    }

      // callback function to call when event triggers
      const onPageLoad = () => {
        dispatch(autoConnectWallet());
        console.log('page loaded');
        // do something else
      };
  
      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
  
  }, []);

  const listenEver = async () => {
    const everNetworkSubscriber =
      await EverWalletWebProvider.listenNetworChanges();
    everNetworkSubscriber.on("data", (event) => {
      dispatch(
        networkChanged({
          networkType: CHAIN_TYPE_TVM,
          networkChainId: event.networkId,
          connection: event.selectedConnection,
        })
      );

      // track changes in the network id
      // if (event.networkId === 42 && currentProviderState.selectedConnection === 'testnet') {
      // } else {
      // }
    });
  };

  // window.onload = (event) => {
  //   dispatch(autoConnectWallet());
  // };

  useEffect(() => {
    if (isFirstLoading.current == 0) {
      isFirstLoading.current += 1;
      if (!isCoockieSeted(isDisclaimerRead)) {
        dispatch(
          popupToggleByName({
            isShow: true,
            type: TYPE_DISCLAIMER,
          })
        );
      }
    }
  }, [dispatch]);

  const venomProvider = VenomProviderActions();

  return (
    <VenomContext.Provider value={venomProvider}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            {routes.map((route, key) => (
              <Route key={key} path={route.path} element={<route.element />} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </VenomContext.Provider>
  );
}

export default App;
