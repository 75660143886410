export const EverContractAbi = {
  "ABI version": 2,
  version: "2.3",
  header: ["pubkey", "time", "expire"],
  functions: [
    {
      name: "constructor",
      inputs: [],
      outputs: [],
    },
    {
      name: "withdrawCoins",
      inputs: [
        { name: "_target", type: "address" },
        { name: "_amount", type: "uint128" },
      ],
      outputs: [],
    },
    {
      name: "withdrawTokens",
      inputs: [
        { name: "_tokenRoot", type: "address" },
        { name: "_target", type: "address" },
        { name: "_amount", type: "uint128" },
      ],
      outputs: [],
    },
    {
      name: "setMinUsdAmount",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "setMaxUsdAmount",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "setMinUsdAmountPerChain",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "setMaxUsdAmountPerChain",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "addStableCoin",
      inputs: [
        { name: "_tokenRoot", type: "address" },
        { name: "_decimals", type: "uint8" },
      ],
      outputs: [],
    },
    {
      name: "onStableCoinWalletDeployed",
      inputs: [{ name: "_walletAddress", type: "address" }],
      outputs: [],
    },
    {
      name: "removeStableCoin",
      inputs: [{ name: "_tokenAddress", type: "address" }],
      outputs: [],
    },
    {
      name: "buildGasPayload",
      inputs: [
        { name: "_chainIds", type: "uint64[]" },
        { name: "_amounts", type: "uint256[]" },
        { name: "_receivers", type: "uint256[]" },
      ],
      outputs: [],
    },
    {
      name: "onAcceptTokensTransfer",
      inputs: [
        { name: "_tokenRoot", type: "address" },
        { name: "_amount", type: "uint128" },
        { name: "_sender", type: "address" },
        { name: "_senderWallet", type: "address" },
        { name: "_remainingGasTo", type: "address" },
        { name: "_payload", type: "cell" },
      ],
      outputs: [],
    },
    {
      name: "addTrustedAddress",
      inputs: [
        { name: "_chainIds", type: "uint64" },
        { name: "_trustedAddresses", type: "uint256" },
      ],
      outputs: [],
    },
    {
      name: "addTrustedAddresses",
      inputs: [
        { name: "_chainIds", type: "uint64[]" },
        { name: "_trustedAddresses", type: "uint256[]" },
      ],
      outputs: [],
    },
    {
      name: "removeTrustedSourceAddress",
      inputs: [{ name: "_chainId", type: "uint64" }],
      outputs: [],
    },
    {
      name: "updateChainsList",
      inputs: [],
      outputs: [],
    },
    {
      name: "onUpdateChainsListCallback",
      inputs: [
        {
          components: [
            { name: "exists", type: "bool" },
            { name: "chainType", type: "uint8" },
          ],
          name: "_chains",
          type: "map(uint64,tuple)",
        },
      ],
      outputs: [],
    },
    {
      name: "initAsterizmTransfer",
      inputs: [
        { name: "_dstChainId", type: "uint64" },
        { name: "_txId", type: "uint256" },
        { name: "_transferHash", type: "uint256" },
        { name: "_transferFeeValue", type: "uint128" },
        { name: "_payload", type: "cell" },
      ],
      outputs: [],
    },
    {
      name: "onInitAsterizmTransferCallback",
      inputs: [
        { name: "_transferHash", type: "uint256" },
        { name: "_flag", type: "bool" },
        { name: "_method", type: "uint8" },
      ],
      outputs: [],
    },
    {
      name: "asterizmIzReceive",
      inputs: [
        {
          components: [
            { name: "srcChainId", type: "uint64" },
            { name: "srcAddress", type: "uint256" },
            { name: "dstChainId", type: "uint64" },
            { name: "dstAddress", type: "uint256" },
            { name: "nonce", type: "uint256" },
            { name: "txId", type: "uint256" },
            { name: "transferHash", type: "uint256" },
            { name: "payload", type: "cell" },
          ],
          name: "_dto",
          type: "tuple",
        },
      ],
      outputs: [],
    },
    {
      name: "asterizmClReceive",
      inputs: [
        { name: "_srcChainId", type: "uint64" },
        { name: "_srcAddress", type: "uint256" },
        { name: "_nonce", type: "uint256" },
        { name: "_txId", type: "uint256" },
        { name: "_transferHash", type: "uint256" },
        { name: "_payload", type: "cell" },
      ],
      outputs: [],
    },
    {
      name: "onAsterizmReceiveCallback",
      inputs: [
        { name: "_transferHash", type: "uint256" },
        { name: "_flag", type: "bool" },
        { name: "_method", type: "uint8" },
      ],
      outputs: [],
    },
    {
      name: "stableCoins",
      inputs: [],
      outputs: [
        {
          components: [
            { name: "exists", type: "bool" },
            { name: "deployed", type: "bool" },
            { name: "walletAddress", type: "address" },
            { name: "balance", type: "uint256" },
            { name: "decimals", type: "uint8" },
          ],
          name: "stableCoins",
          type: "map(address,tuple)",
        },
      ],
    },
    {
      name: "minUsdAmount",
      inputs: [],
      outputs: [{ name: "minUsdAmount", type: "uint256" }],
    },
    {
      name: "maxUsdAmount",
      inputs: [],
      outputs: [{ name: "maxUsdAmount", type: "uint256" }],
    },
    {
      name: "minUsdAmountPerChain",
      inputs: [],
      outputs: [{ name: "minUsdAmountPerChain", type: "uint256" }],
    },
    {
      name: "maxUsdAmountPerChain",
      inputs: [],
      outputs: [{ name: "maxUsdAmountPerChain", type: "uint256" }],
    },
    {
      name: "_await_IInitializerSender_getLocalChainId",
      inputs: [{ name: "value0", type: "uint64" }],
      outputs: [],
    },
    {
      name: "_await_IInitializerSender_getInitializerTransferCode",
      inputs: [{ name: "value0", type: "cell" }],
      outputs: [],
    },
    {
      name: "_await_IInitializerSender_getClientTransferCode",
      inputs: [{ name: "value0", type: "cell" }],
      outputs: [],
    },
    {
      name: "_await_IInitializerSender_getChainsList",
      inputs: [
        {
          components: [
            { name: "exists", type: "bool" },
            { name: "chainType", type: "uint8" },
          ],
          name: "value0",
          type: "map(uint64,tuple)",
        },
      ],
      outputs: [],
    },
  ],
  data: [
    { key: 1, name: "owner_", type: "address" },
    { key: 2, name: "initializerLib_", type: "address" },
    { key: 3, name: "useForceOrder_", type: "bool" },
    { key: 4, name: "disableHashValidation_", type: "bool" },
    { key: 5, name: "hashVersion_", type: "uint8" },
    { key: 6, name: "nonce_", type: "uint256" },
  ],
  events: [
    {
      name: "CoinsReceivedEvent",
      inputs: [
        { name: "_amount", type: "uint256" },
        { name: "_transactionId", type: "uint256" },
        { name: "_dstAddress", type: "address" },
      ],
      outputs: [],
    },
    {
      name: "GasSendEvent",
      inputs: [
        { name: "_dstChainId", type: "uint64" },
        { name: "_transactionId", type: "uint256" },
        { name: "_payload", type: "cell" },
      ],
      outputs: [],
    },
    {
      name: "AddStableCoinEvent",
      inputs: [
        { name: "_rootAddress", type: "address" },
        { name: "_walletAddress", type: "address" },
      ],
      outputs: [],
    },
    {
      name: "RemoveStableCoinEvent",
      inputs: [{ name: "_address", type: "address" }],
      outputs: [],
    },
    {
      name: "SetMinUsdAmountEvent",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "SetMaxUsdAmountEvent",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "SetMinUsdAmountPerChainEvent",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "SetMaxUsdAmountPerChainEvent",
      inputs: [{ name: "_amount", type: "uint256" }],
      outputs: [],
    },
    {
      name: "WithdrawCoinsEvent",
      inputs: [
        { name: "_target", type: "address" },
        { name: "_amount", type: "uint128" },
      ],
      outputs: [],
    },
    {
      name: "WithdrawTokensEvent",
      inputs: [
        { name: "_token", type: "address" },
        { name: "_target", type: "address" },
        { name: "_amount", type: "uint256" },
      ],
      outputs: [],
    },
    {
      name: "WithdrawNotExistsTokensEvent",
      inputs: [
        { name: "_token", type: "address" },
        { name: "_target", type: "address" },
        { name: "_amount", type: "uint256" },
      ],
      outputs: [],
    },
    {
      name: "BuildGasPayloadEvent",
      inputs: [{ name: "_payload", type: "cell" }],
      outputs: [],
    },
    {
      name: "SetInitializerEvent",
      inputs: [{ name: "_initializerAddress", type: "address" }],
      outputs: [],
    },
    {
      name: "SetLocalChainIdEvent",
      inputs: [{ name: "_localChainId", type: "uint64" }],
      outputs: [],
    },
    {
      name: "InitiateTransferEvent",
      inputs: [
        { name: "_dstChainId", type: "uint64" },
        { name: "_dstAddress", type: "uint256" },
        { name: "_txId", type: "uint256" },
        { name: "_transferHash", type: "uint256" },
        { name: "_payload", type: "cell" },
      ],
      outputs: [],
    },
    {
      name: "PayloadReceivedEvent",
      inputs: [
        { name: "_srcChainId", type: "uint64" },
        { name: "_srcAddress", type: "uint256" },
        { name: "_nonce", type: "uint256" },
        { name: "_txId", type: "uint256" },
        { name: "_transferHash", type: "uint256" },
        { name: "_payload", type: "cell" },
      ],
      outputs: [],
    },
    {
      name: "AddTrustedSourceAddressEvent",
      inputs: [
        { name: "_chainId", type: "uint64" },
        { name: "_address", type: "uint256" },
      ],
      outputs: [],
    },
    {
      name: "RemoveTrustedSourceAddressEvent",
      inputs: [
        { name: "_chainId", type: "uint64" },
        { name: "_address", type: "uint256" },
      ],
      outputs: [],
    },
    {
      name: "SetUseEncryptionEvent",
      inputs: [{ name: "_flag", type: "bool" }],
      outputs: [],
    },
    {
      name: "SetUseForceOrderEvent",
      inputs: [{ name: "_flag", type: "bool" }],
      outputs: [],
    },
    {
      name: "SetDisableHashValidationEvent",
      inputs: [{ name: "_flag", type: "bool" }],
      outputs: [],
    },
    {
      name: "TransferContractDeployEvent",
      inputs: [{ name: "_address", type: "address" }],
      outputs: [],
    },
    {
      name: "TransferErrorEvent",
      inputs: [
        { name: "_transferHash", type: "uint256" },
        { name: "_errorCode", type: "uint16" },
      ],
      outputs: [],
    },
    {
      name: "UpdateChainsListEvent",
      inputs: [],
      outputs: [],
    },
    {
      name: "SuccessTransferExecutedEvent",
      inputs: [{ name: "_transferHash", type: "uint256" }],
      outputs: [],
    },
    {
      name: "ErrorTransferExecutedEvent",
      inputs: [
        { name: "_transferHash", type: "uint256" },
        { name: "_errorCode", type: "uint16" },
      ],
      outputs: [],
    },
  ],
  fields: [
    { name: "_pubkey", type: "uint256" },
    { name: "_timestamp", type: "uint64" },
    { name: "_constructorFlag", type: "bool" },
    { name: "_await", type: "optional(cell)" },
    { name: "owner_", type: "address" },
    { name: "trustedAddresses", type: "map(uint64,uint256)" },
    {
      components: [
        { name: "exists", type: "bool" },
        { name: "chainType", type: "uint8" },
      ],
      name: "chains",
      type: "map(uint64,tuple)",
    },
    {
      components: [
        { name: "exists", type: "bool" },
        { name: "successIzReceive", type: "bool" },
        { name: "successClReceive", type: "bool" },
        { name: "successClExecute", type: "bool" },
        { name: "msgValue", type: "uint128" },
        { name: "dto", type: "cell" },
      ],
      name: "inboundTransfers",
      type: "map(uint256,tuple)",
    },
    {
      components: [
        { name: "exists", type: "bool" },
        { name: "successIzReceive", type: "bool" },
        { name: "successClReceive", type: "bool" },
        { name: "successClExecute", type: "bool" },
        { name: "msgValue", type: "uint128" },
        { name: "dto", type: "cell" },
      ],
      name: "outboundTransfers",
      type: "map(uint256,tuple)",
    },
    { name: "trustedAddressCount", type: "uint256" },
    { name: "txId", type: "uint256" },
    { name: "localChainId", type: "uint64" },
    { name: "initializerTransferCode", type: "cell" },
    { name: "clientTransferCode", type: "cell" },
    { name: "initializerLib_", type: "address" },
    { name: "useForceOrder_", type: "bool" },
    { name: "disableHashValidation_", type: "bool" },
    { name: "hashVersion_", type: "uint8" },
    {
      components: [
        { name: "exists", type: "bool" },
        { name: "deployed", type: "bool" },
        { name: "walletAddress", type: "address" },
        { name: "balance", type: "uint256" },
        { name: "decimals", type: "uint8" },
      ],
      name: "stableCoins",
      type: "map(address,tuple)",
    },
    { name: "minUsdAmount", type: "uint256" },
    { name: "maxUsdAmount", type: "uint256" },
    { name: "minUsdAmountPerChain", type: "uint256" },
    { name: "maxUsdAmountPerChain", type: "uint256" },
    { name: "nonce_", type: "uint256" },
  ],
};
