import { VenomConnect } from "venom-connect";
import { PROVIDER_VENOM } from "../../constants/ProviderTypes";
import { Address, ProviderRpcClient } from "everscale-inpage-provider";
import { setError } from "../../features/walletService/walletService";
import { EverscaleStandaloneClient } from "everscale-standalone-client";
import { EverTokenWalletAbi } from "../../abis/EverTokenWalletAbi";
import { EverTokenRootAbi } from "../../abis/EverTokenRootAbi";
import { BigNumber, utils } from "ethers";
import { EverContractAbi } from "../../abis/EvetContract";
import { getNetworks } from "../../utils/NetworkUtil";
import { MIN_NETWORK_TRANSFER_AMOUNT } from "../../constants/TransferConstants";


const VENOM_DEVNET = 1002;
export const VENOM_TESTNET = 1000;
const VENOM_MAINNET = 1;

const NETWORKS = {
  venom: {
    name: "Venom Mainnet",
    checkNetworkId: 1,
    connection: {
      id: 1,
      group: "venom_mainnet",
      type: "jrpc",
      data: {
        endpoint: "https://jrpc.venom.foundation/rpc",
      },
    },
  },
  venomTestnet: {
    name: "Venom Testnet",
    checkNetworkId: 1000,
    connection: {
      id: 1000,
      group: "venom_testnet",
      type: "jrpc",
      data: {
        endpoint: "https://jrpc-testnet.venom.foundation/rpc",
      },
    },
  },
  venomDevnet: {
    name: "Venom Devnet",
    checkNetworkId: VENOM_DEVNET,
    connection: {
      id: VENOM_DEVNET,
      group: "venom_testnet",
      type: "jrpc",
      data: {
        endpoint: "https://jrpc-testnet.venom.foundation/rpc",
      },
    },
  },
};
const getNetworkData = (checkNetworkId, field) => {
  switch (checkNetworkId) {
    case VENOM_TESTNET:
      return NETWORKS.venomTestnet[field];
    case VENOM_DEVNET:
      return NETWORKS.venomDevnet[field];
      case VENOM_MAINNET:
    default:
      return NETWORKS.venom[field];
  }
};

export const VENOM_DEFAULT_NETWORK = VENOM_TESTNET;

export const VenomWalletWebProvider = {
  standaloneFallback: function (checkNetworkId = VENOM_DEFAULT_NETWORK) {
    return EverscaleStandaloneClient.create({
      connection: getNetworkData(checkNetworkId, "connection"),
    });
  },
  getConnectProvider: function (checkNetworkId = VENOM_DEFAULT_NETWORK) {
    return new VenomConnect({
      checkNetworkId: checkNetworkId,
      checkNetworkName: 'Venom Devnet',
      providersOptions: {
        venomwallet: {
          walletWaysToConnect: [
            {
              // NPM package
              package: ProviderRpcClient,
              packageOptions: {
                fallback:
                  VenomConnect.getPromise("venomwallet", "extension") ||
                  (() => {
                    return Promise.reject();
                  }),
                forceUseFallback: true,
              },
              // packageOptionsStandalone: {
              //   fallback: this.standaloneFallback(),
              //   forceUseFallback: true,
              // },

              // Setup
              id: "extension",
              type: "extension",
            },
          ],
          defaultWalletWaysToConnect: [
            // List of enabled options
            'extension'
            // "mobile",
            // "ios",
            // "android",
          ],
        },
      },
    });
  },
  isConnected: async function () {},
  getWalletInfo: async function (_provider, networkChainId = VENOM_DEFAULT_NETWORK) {
    const _address = _provider ? await this.getAddress(_provider) : undefined;
    const _balance =
      _provider && _address
        ? await this.getBalance(_provider, _address)
        : undefined;
    const _publicKey = _provider
      ? await this.getPublicKey(_provider)
      : undefined;

    const providerState = await _provider?.getProviderState?.();
    const loadedNetwork = getNetworks().find(
      (v) => v.wallets.includes(PROVIDER_VENOM) && v.chainId == networkChainId && networkChainId == providerState.networkId
    );

    return {
      networkId: loadedNetwork?.id,
      providerType: PROVIDER_VENOM,
      accountAddress: _address,
      networkChainId: providerState.networkId,
      balance: _balance ? parseFloat(utils.formatUnits(BigNumber.from(_balance),9)) : _balance,
    };
  },
  getAddress: async function (provider) {
    const providerState = await provider?.getProviderState?.();
    const address =
      providerState?.permissions.accountInteraction?.address.toString();

    return address;
  },
  getPublicKey: async function (provider) {
    const providerState = await provider?.getProviderState?.();

    const publicKey =
      providerState?.permissions.accountInteraction?.publicKey.toString();

    return publicKey;
  },
  getBalance: async function (provider, _address) {
    try {
      const providerBalance = await provider?.getBalance?.(_address);

      return providerBalance;
    } catch (error) {
      return undefined;
    }
  },
  callStandalone: async function () {
    const venomConnect = await this.getConnectProvider();
    setTimeout(async () => {
      const standalone = await venomConnect?.getStandalone("venomwallet");
    }, 400);
  },
  getTokenBalance: async function (contractAddress, provider) {
    // let contractAddress =
    //   "0:8c6dcaa30727458527e99a479dae92a92a51c24e235e5b531659e201204d79ee";

    let balance = 0;

    const TokenRootContractAddress = new Address(contractAddress);
    const tokenRootContract = new provider.Contract(
      EverTokenRootAbi,
      TokenRootContractAddress
    );

    const userAddress = await this.getAddress(provider);

    const { value0: userTokenWalletAddress } = await tokenRootContract.methods
      .walletOf({ answerId: 0, walletOwner: userAddress })
      .call();

    const userTokenWalletContract = new provider.Contract(
      EverTokenWalletAbi,
      userTokenWalletAddress
    );

    const { state } = await userTokenWalletContract.getFullState();
    const isDeployed = state?.isDeployed;
    if (isDeployed) {
      const { value0: tokenBalance } = await userTokenWalletContract.methods
        .balance({ answerId: 0 })
        .call();
      balance = tokenBalance;
    }
    return balance;
  },
  isWalletConnected: async function (venomProvider) {
    if (!venomProvider) {
      return false;
    }
    let isConnected = false;
    try {
      const currentProviderState = await venomProvider.getProviderState();
      isConnected = currentProviderState.permissions.accountInteraction.address
        ? true
        : false;
    } catch (error) {}
    return isConnected;
  },
  getContractBalance: async function (contractAddress, venomProvider) {
    const TokenRootContractAddress = new Address(contractAddress);
    let resp = await venomProvider.getBalance(TokenRootContractAddress);
    return BigNumber.from(resp);
  },
  makePayload: async function (chainIds, amounts, receivers, venomProvider) {
    const payload = (
      await venomProvider.packIntoCell({
        data: {
          _chainIds: chainIds,
          _amounts: amounts,
          _receivers: receivers,
        },
        structure: [
          { name: "_chainIds", type: "uint64[]" },
          { name: "_amounts", type: "uint256[]" },
          { name: "_receivers", type: "uint256[]" },
        ],
      })
    ).boc;
    return payload;
  },
  makeSendTransaction: async function (
    chainIds,
    amounts,
    receivers,
    contractAddress,
    gasAddress,
    amountOfTokens,
    venomProvider
  ) {
    const currentProviderState = await venomProvider.getProviderState();
    const userAddress =
      currentProviderState.permissions.accountInteraction.address;
    const publicKey =
      currentProviderState.permissions.accountInteraction.publicKey;

    const TokenRootContractAddress = new Address(contractAddress);
    const tokenRootContract = new venomProvider.Contract(
      EverTokenRootAbi,
      TokenRootContractAddress
    );

    const { value0: userTokenWalletAddress } = await tokenRootContract.methods
      .walletOf({ answerId: 0, walletOwner: userAddress })
      .call();

    const payload = await this.makePayload(
      chainIds,
      amounts,
      receivers,
      venomProvider
    );
    const userTokenWalletContract = new venomProvider.Contract(
      EverTokenWalletAbi,
      userTokenWalletAddress
    );

    const initTransaction = await userTokenWalletContract.methods
      .transfer({
        amount: amountOfTokens,
        recipient: gasAddress,
        deployWalletValue: "100000000", //0.1 ever
        remainingGasTo: userAddress,
        notify: true,
        payload: payload,
      })
      .send({
        amount: "500000000",
        from: userAddress,
      })
      .catch((e) => {
        if (e.code === 3) {
          // rejected by a user
          return Promise.resolve(null);
        } else {
          // The message has expired or some other
          // perform any necessary error handling
          return Promise.reject(e);
        }
      });

    return initTransaction;
      // let events = await userTokenWalletContract.decodeTransactionEvents({transaction: playTx})
    // console.log(events,'events')
  },
  getTransactionInfo: async function (provider, hash) {
    await provider.ensureInitialized();

    const _transaction = await provider.getTransaction({ hash: hash });
    return _transaction;
  },
  checkTransactionState: function (transactionState) {
    return transactionState &&  !transactionState?.aborted && transactionState.endStatus == "active";
  },
  waitTransactionEnd: async function (transaction, provider) {
    const subscriber = new provider.Subscriber();
    let subTransactions = [];
    await subscriber
      .trace(transaction)
      .tap((tx_in_tree) => {
        subTransactions.push(tx_in_tree.id.hash)
      })
      .finished();
    return subTransactions;
  },
  getContractListener: function (contractAddress, provider) {
    const subscriber = new provider.Subscriber();

    const exampleContract = new provider.Contract(
      EverContractAbi,
      contractAddress
    );

    return exampleContract.events(subscriber);
  },
  showPastEvents: async function (contractAddress, provider) {
    const subscriber = new provider.Subscriber();

    const exampleContract = new provider.Contract(
      EverContractAbi,
      contractAddress
    );

    const contractEvents = exampleContract.events(subscriber);

    // console.log(contractEvents,'contractEvents')
    // contractEvents.unsubscribe();

    // const pastEvents = await exampleContract.getPastEvents({
    //   // filter: 'StateChanged',
    //   // range: {
    //   //   fromUtime: 1682651393814,
    //   // },
    // });
    // console.log(pastEvents, "pastEvents");
  },
  getNetworkMinAmount: async function (currentNetwork,provider) {

    const gasContract = new provider.Contract(
      EverContractAbi,
      currentNetwork.gasSenderAddress
    );

    let amount = MIN_NETWORK_TRANSFER_AMOUNT;

    try {
      const {minUsdAmountPerChain:networkAmount } = await gasContract.methods
      .minUsdAmountPerChain()
      .call();
      amount = networkAmount;
    } catch (error) {
      
    }

    return amount;
  },
};
