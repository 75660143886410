import { useDispatch, useSelector } from "react-redux";
import { cookieTogglePopup } from "../../features/cookiePopupSlice";
import {
  TYPE_DISCLAIMER,
  popupToggleByName,
} from "../../features/PopupManagerSlice";
import { useState } from "react";
import { isDisclaimerRead, setCookieForever } from "../../utils/BrowserUtil";

export default function DisclaimerPopup({}) {
  const popupManager = useSelector((state) => state.popupManager);
  let dispatch = useDispatch();

  const state = popupManager.find((v) => v.type == TYPE_DISCLAIMER);

  const [isAcvtiveAgree, setIsAcvtiveAgree] = useState(false);

  const onScrollHandler = (e) => {
    let obj = e.target;
    if (obj.scrollTop + obj.offsetHeight >= obj.scrollHeight - 2 ) {
      setIsAcvtiveAgree(true);
    }
  };

  const onAgreeHandler = () => {
    dispatch(
      popupToggleByName({
        isShow: false,
        type: TYPE_DISCLAIMER,
      })
    );
    setCookieForever(isDisclaimerRead);
  };
  if (!state || !state.show) {
    return null;
  }
  return (
    <div className="disclaimer-popup active popup dark">
      <div className="popup__container">
        <h3 className="popup__title">Disclaimer</h3>
        <div
          className="disclaimer-popup__cnt"
          onScroll={(e) => onScrollHandler(e)}
        >
          <div className="disclaimer-popup__content">
            <p>
              The protocol is an open-source, blockchain-based software tool
              made available by the Chainspot Labs that seeks to provide an
              ability to top up wallets with zero coin balance in many chains.
              Your use of the Protocol is entirely at your own risk.
            </p>

            <p>
              The Protocol is available on an “as is” basis without warranties
              of any kind, either express or implied, including, but not limited
              to, warranties of merchantability, title, fitness for a particular
              purpose and non-infringement.
            </p>

            <p>
              You assume all risks associated with using the Protocol, and
              digital assets and decentralized systems generally, including but
              not limited to, that:
            </p>

            <p>
              (a) digital assets and decentralized systems are highly volatile;
            </p>

            <p>
              (b) using digital assets and decentralized systems is inherently
              risky due to both features of such assets and decentralized
              systems and the potential unauthorized acts of third parties;
            </p>

            <p>
              (c) you may not have ready access to digital assets and
              decentralized systems; and
            </p>

            <p>
              (d) you may lose some or all of your tokens or other digital
              assets. You agree that you will have no recourse against anyone
              else, including the Chainspot or any entities related to the
              Protocol, for any losses due to the use of the Protocol. For
              example, these losses may arise from or relate to:
            </p>

            <p>(i) incorrect information;</p>

            <p>(ii) software or network failures;</p>

            <p>(iii) corrupted digital wallet files;</p>

            <p>(iv) unauthorized access;</p>

            <p>(v) errors, mistakes, or inaccuracies;</p>

            <p>(vi) third-party activities; or</p>

            <p>(vii) revocation of your access to the Protocol.</p>
          </div>
        </div>
        <button
          className="disclaimer-popup__btn btn btn_primary"
          onClick={() => onAgreeHandler()}
          disabled={!isAcvtiveAgree}
        >
          I agree
        </button>
      </div>
    </div>
  );
}
