import { ethers } from "ethers";
import axios from "../app/axios";

export async function getDataByUrl(url) {
  let statusInfo = {
    hasError: true,
    errorText: "",
    data: null,
  };

  try {
    let res = await axios.get(url);
    if (res && res.status == 200 && res.data) {
      statusInfo.data = res.data;
      statusInfo.hasError = false;
    } else {
      statusInfo.errorText = res.data?.message || res.data;
    }
  } catch (error) {
    statusInfo.errorText = error.response ? error.message : error;
  }
  return statusInfo;
}

// export async function getAvialableNetworks(networkFromId, cryptoFromId) {
//   let res;

//   let statusInfo = {
//     hasError: false,
//     errorText: "",
//     data: null,
//   };

//   try {
//     res = await axios.get("router/get-available-networks", {
//       params: {
//         networkId: networkFromId,
//         cryptoId: cryptoFromId,
//       },
//     });
//   } catch (error) {
//     statusInfo.hasError = true;
//     if (error.response.data.message) {
//       statusInfo.errorText = error.response.data.message;
//     }
//     return statusInfo;
//   }

//   if (res && res.status == 200 && res.data) {
//     if (res.data.hasOwnProperty("success") && !res.data.success) {
//       statusInfo.hasError = true;
//       statusInfo.errorText = res.data.message;
//     } else {
//       statusInfo.data = res.data;
//     }
//     statusInfo.data = res.data.data;
//   } else {
//     statusInfo.hasError = true;
//     statusInfo.errorText = res.data?.message || res.data;
//   }

//   return statusInfo;
// }

export const copyToBuffer = (text) => {
  navigator.clipboard.writeText(text);
};

export const formatAmountToUint = (amount, cryptoDecimals) => {
  return ethers.utils.parseUnits(amount.toString(), cryptoDecimals);
};
