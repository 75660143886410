import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import TopMenu from "./Header/TopMenu/TopMenu";
// import FilterMobilePanel from "../FilterMobile/FilterMobilePanel";
import PopupBlock from "./Header/PopupBlock/PopupBlock";
import { IsAsterizmPage } from "../utils/RouteUtils";
import { useSelector } from "react-redux";

function Layout() {

  const currentNetwork = useSelector(
    (state) => state.selectedNetworkManager.network
  );

  return (
    <>
      <Header />
      {/* <FilterMobilePanel /> */}
      <div
        className={"container  container_dark " + "container_"+currentNetwork?.formClass}
      >
        <TopMenu />
        <Outlet />
        <Footer />
        <PopupBlock />
      </div>
    </>
  );
}
export default Layout;
