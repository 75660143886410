import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import {
  ERROR_AMOUNT_LESS_TOTAL_NETWORK_TRANSFER,
  ERROR_AMOUNT_LESS_TOTAL_NETWORK_TRANSFER_TEXT,
  ERROR_AMOUNT_LESS_TOTAL_TRANSFER,
  ERROR_AMOUNT_LESS_TOTAL_TRANSFER_TEXT,
  ERROR_AMOUNT_OVER_PER_NETWORK,
  ERROR_AMOUNT_OVER_PER_NETWORK_CODE,
  ERROR_AMOUNT_OVER_TOTAL_TRANSFER,
  ERROR_AMOUNT_OVER_TOTAL_TRANSFER_TEXT,
  ERROR_INCORRECT_AMOUNT_FORMAT,
  ERROR_INCORRECT_AMOUNT_FORMAT_TEXT,
} from "../constants/ValidationErrorType";
import { useSelector } from "react-redux";

export function FormValidationHook(formData, incomeNetworks,rates) {
  const [validationError, setFormValidationError] = useState({
    hasError: false,
    errorText: null,
    code: null,
  });

  const limits = useSelector(
    (state) => state.limitManager
  );

  const validate = () => {
    if (formData.amountFrom && !/^[+-]?\d+(\.\d+)?$/.test(formData.amountFrom)) {
      return setFormValidationError({
        hasError: true,
        errorText: ERROR_INCORRECT_AMOUNT_FORMAT_TEXT,
        code: ERROR_INCORRECT_AMOUNT_FORMAT,
      });
    }

    if (
      formData.amountFrom != "" &&
      parseFloat(formData.amountFrom) < limits.minUsdAmount
    ) {
      return setFormValidationError({
        hasError: true,
        errorText: ERROR_AMOUNT_LESS_TOTAL_TRANSFER_TEXT.replace(
          "%AMOUNT%",
          limits.minUsdAmount
        ),
        code: ERROR_AMOUNT_LESS_TOTAL_TRANSFER,
      });
    }

    if (
      formData.amountFrom != "" &&
      parseFloat(formData.amountFrom) > limits.maxUsdAmount
    ) {
      return setFormValidationError({
        hasError: true,
        errorText: ERROR_AMOUNT_OVER_TOTAL_TRANSFER_TEXT.replace(
          "%AMOUNT%",
          limits.maxUsdAmount
        ),
        code: ERROR_AMOUNT_OVER_TOTAL_TRANSFER,
      });
    }

    const selectedNetworks = incomeNetworks.filter((v) => v.isChecked);

    let minTotalSelectedNetworks = 0;
    selectedNetworks.forEach( network=>{
      let rate  = rates.find(rate => rate.networkId == network.route.id)
      if(rate){
        minTotalSelectedNetworks += rate.minAmount;
      }else{
        minTotalSelectedNetworks += limits.minUsdAmountPerChain; 
      }
    });

    // const minTotalSelectedNetworks =
    //   selectedNetworks * MIN_NETWORK_TRANSFER_AMOUNT;

    if (
      formData.amountFrom != "" &&
      parseFloat(formData.amountFrom) < minTotalSelectedNetworks
    ) {
      let err = ERROR_AMOUNT_LESS_TOTAL_NETWORK_TRANSFER_TEXT.replace(
        "%CNT%",
        selectedNetworks.length
      );
      err = err.replace("%AMOUNT%", minTotalSelectedNetworks);
      return setFormValidationError({
        hasError: true,
        errorText: err,
        code: ERROR_AMOUNT_LESS_TOTAL_NETWORK_TRANSFER,
      });
    }

    if (
      formData.amountFrom != "" && selectedNetworks.length > 0 &&
      (parseFloat(formData.amountFrom) / selectedNetworks.length) > limits.maxUsdAmountPerChain
    ) {
      let err = ERROR_AMOUNT_OVER_PER_NETWORK.replace(
        "%AMOUNT%",
        limits.maxUsdAmountPerChain
      );
      return setFormValidationError({
        hasError: true,
        errorText: err,
        code: ERROR_AMOUNT_OVER_PER_NETWORK_CODE,
      });
    }

    return setFormValidationError({
      hasError: false,
      errorText: null,
      code: null,
    });
  };

  useEffect(() => {
    validate();
  }, [incomeNetworks, formData]);

  return validationError;
}
