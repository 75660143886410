import { useEffect } from "react";
import { DISCORD_ADDRESS, TELEGRAM_GROUP, TWITTER_LINK_HOME } from "../../constants/SocialLinks";
import { ymDiscordYes, ymTgYes, ymTwitterYes } from "../../utils/YandexUtl";

export default function GasQuizStep2() {

  // const onOkhandle = () =>{
  //   ymQuizYes();
  //   setQuizStep(2)
  // }

  return (
    <div className="quiz__step-2">
      <span className="quiz__text">
        Share with your friends the experience of interacting with Gas Charger,
        probably this tool will also be useful to them:
      </span>
      <div className="quiz__cta">
        <a href={TWITTER_LINK_HOME} className="btn btn_twitter" target="_blank" onClick={() => ymTwitterYes()}>
          <img src="img/twitter-icon_alt.svg" alt="" />
          Share your experience
        </a>
        <a href={TELEGRAM_GROUP} className="blue-social-link" target="_blank" onClick={() => ymTgYes()}>
          <img src="img/telegram-icon_blue.svg" alt="" />
        </a>
        <a href={DISCORD_ADDRESS} className="blue-social-link" target="_blank" onClick={() => ymDiscordYes()}>
          <img src="img/discord-icon_blue.svg" alt="" />
        </a>
      </div>
    </div>
  );
}
