import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../../features/dialogs/notificationPopupSlice";
import { showWelcomeConnectDialog } from "../../features/walletService/walletService";
import { setWelcomRead } from "../../utils/BrowserUtil";

export default function GasWelcomePopup() {
  const isShow = useSelector(
    (state) => state.walletServiceProvider.showWelcomePopup
  );
  // const isShow = true;
  const [isClosing, setClose] = useState(true);
  const dispatch = useDispatch();
  const [openPopups, setOpenPopups] = useState([]);
  const items = [
    {
      title:'Welcome',
      text:`Use Gas Charger to top up your wallets on different networks with gas
      tokens via a single transaction!`,
      isShow: false,
    },
    {
      title: null,
      text:`Select the token you want in the form on the left, connect your
      wallet, and enter the amount you want to spend on topping up your
      wallets with gas tokens.`,
      isShow: false,
    },
    {
      title:null,
      text:`Select the networks you want to refill with gas tokens in the box on
      the right. You will see how many gas tokens you will receive in each
      network.`,
      isShow: false,
    },
    {
      title: null,
      text:`Once you are ready, click Swap, approve the token transfer, and then
      confirm the transaction. In a few minutes, the gas tokens will appear
      in your wallets.`,
      isShow: false,
    },
    {
      title: null,
      text:`We use public rpc networks. If there is a failure on the rpc side and you don't receive your transaction for a long time, please reach out to us in Discord or Telegram.`,
      isShow: false,
    },
  ]
  const heights = [
    '','275px','415px','540px','680px'
   ]
   
  useEffect(() => {
    if(isShow){
      setWelcomRead();
      setOpenPopups(items.map(v=>{ v.isShow = true; return v;}));
    }
    console.log(items.map(v=>{ v.isShow = true; return v;}))
  }, [isShow]);


  if (!isShow) {
    return;
  }

  const close = (num) => {
    let dialogs = [...openPopups];
    let toglers =  dialogs.map( (v,index)=> {
      if(index == num){
        v.isShow = false;
      }
      return v;
    })
    setOpenPopups(toglers);
    setClose(true);
  };

  return (
    <>
    {openPopups.map((item,index) => (
      <>
      {item.isShow && <div
        key={index} 
        className={`alert alertWelcome active alert`+(index+1)}
        style={{ top: heights[index] }}
        // onAnimationEnd={() =>
        //   isClosing ? dispatch(showWelcomeConnectDialog()) : null
        // }
      >
        {item.title && <h3 className="alert__title">{item.title}</h3>}
        <button className="alert__close" onClick={() => close(index)}></button>
        <p className="alert__caption">
          {item.text}
        </p>
      </div>
      }
      </>
    ))}

      {/* <div
        className={`alert alertWelcome alert2 ${openPopups[2] ?  "active" : ''}`}
        style={{ top: "275px" }}
        onAnimationEnd={() =>
          isClosing ? dispatch(showWelcomeConnectDialog()) : null
        }
      >
        <button className="alert__close" onClick={() => close(2)}></button>
        <p className="alert__caption">
          Select the token you want in the form on the left, connect your
          wallet, and enter the amount you want to spend on topping up your
          wallets with gas tokens.
        </p>
      </div>

      <div
        className={`alert alertWelcome alert3 ${openPopups[3] ?  "active" : ''}`}
        style={{ top: "415px" }}
        onAnimationEnd={() =>
          isClosing ? dispatch(showWelcomeConnectDialog()) : null
        }
      >
        <button className="alert__close" onClick={() => close(3)}></button>
        <p className="alert__caption">
          Select the networks you want to refill with gas tokens in the box on
          the right. You will see how many gas tokens you will receive in each
          network.
        </p>
      </div>

      <div
        className={`alert alertWelcome alert4 ${openPopups[4] ?  "active" : ''}`}
        style={{ top: "540px" }}
        onAnimationEnd={() =>
          isClosing ? dispatch(showWelcomeConnectDialog()) : null
        }
      >
        <button className="alert__close" onClick={() => close(4)}></button>
        <p className="alert__caption">
          Once you are ready, click Swap, approve the token transfer, and then
          confirm the transaction. In a few minutes, the gas tokens will appear
          in your wallets.
        </p>
      </div> */}
    </>
  );
}
