import { useDispatch, useSelector } from "react-redux";
import { INIT } from "../../constants/ProcessSteps";
import {
  openSwitchNetwork,
  switchNetworkPopupToggle,
  walletConnectDialogToggle,
  walletTypeDialogToggle,
} from "../../features/walletService/walletService";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { getNetworks } from "../../utils/NetworkUtil";
import {
  getNetworkWallet,
  isNetworkWalletConnected,
} from "../../utils/WalletUtil";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../../constants/ChainTypes";
export default function GasButton({ formData, startProcess, isBtnDisabled }) {
  // let walletInfo = useSelector(
  //   (state) => state.walletServiceProvider.walletInfo
  // );
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getNetworkWallet(connectedWallets, formData.routeFrom);

  const dispatch = useDispatch();

  const switchNetwork = async () => {
    dispatch(openSwitchNetwork(formData.routeFrom));
  };

  const currentNetwork = getNetworks().find(
    (v) => v.id == formData.routeFrom?.id
  );

  if (!walletInfo.accountAddress) {
    return (
      <button
        type="button"
        className="gas-station__form-submit btn btn_primary btn_dark"
        onClick={() => dispatch(walletConnectDialogToggle())}
      >
        Connect your wallet
      </button>
    );
  }

  return (
    <>
      {formData.routeFrom &&
        walletInfo.networkChainId != formData.routeFrom.chainId && (
          <button
            type="button"
            className="gas-station__form-submit btn btn_primary btn_dark"
            onClick={() => switchNetwork()}
          >
            Switch to the {formData.routeFrom.name} network
          </button>
        )}

      {formData.routeFrom &&
        walletInfo.networkChainId == formData.routeFrom.chainId &&
        formData.transactionStep == INIT && (
          <button
            type="button"
            className="gas-station__form-submit btn btn_primary btn_dark"
            onClick={() => startProcess()}
            disabled={isBtnDisabled}
          >
            Swap in single transaction
          </button>
        )}
      {formData.routeFrom &&
        walletInfo.networkChainId == formData.routeFrom.chainId &&
        formData.transactionStep != INIT && (
          <button
            type="button"
            className="gas-station__form-submit btn btn_primary btn_dark"
          >
            Swap started
          </button>
        )}
    </>
  );
}
