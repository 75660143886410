import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export function MenuList({ links, navClass = "main-menu_header" }) {
  const activeClass = (route) => {
    return window.location.pathname === route ? "active" : null;
  };

  return (
    <nav className={"main-menu dark " + navClass}>
      <ul className="main-menu__items">
        {links.map((item, index) => (
          <li
            key={navClass + index}
            className={`main-menu__item   ${
              item.isDisabled ? "disabled" : ""
            } ${item.liClass ? item.liClass : ""} `}
          >
            {item.isDisabled ? (
              <a className="main-menu__link">
                {item.title}
                {item.title == "Reports" && <span></span>}
              </a>
            ) : item.isExternal ? (
              <a
                href={item.url}
                className="main-menu__link"
                target={"_blank"}
                rel="noreferrer"
              >
                {item.title == "Whitelist" && (
                  <div className="main-menu__link-icon-cnt">
                    <img src="img/whitelist-icon.svg" alt="" />
                  </div>
                )}

                {item.title}
              </a>
            ) : (
              <NavLink to={item.url} className="main-menu__link">
                {item.title}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}
