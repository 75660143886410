import React from "react";
import { useState } from "react";
import { TELEGRAM_GROUP, TWITTER_LINK } from "../../constants/SocialLinks";
import { IsAsterizmPage } from "../../utils/RouteUtils";
import { ymTgInfo, ymTwitterInfo } from "../../utils/YandexUtl";
export function Socials() {

  return (
    <div className="footer__socials">
      Follow us:
      <a href={TELEGRAM_GROUP} target={"_blank"} onClick={() => ymTgInfo()}>
        <img src="../img/telegram-icon_white.svg" alt="" />
      </a>
      <a href={TWITTER_LINK} target={"_blank"} onClick={() => ymTwitterInfo()}>
        <img src="../img/twitter-icon_white.svg" alt="" />
      </a>
    </div>
  );
}
