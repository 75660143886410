import GasQuiz from "./GasQuiz";
import GasTransfersRow from "./GasTransfersRow";

export default function GasTransfersBlock({ incomeNetworks }) {
  return (
    <section className="gas-station__networks">
      <GasQuiz />
      <div className="gas-station__check-table">
        <div className="gas-station__networks-table">
          {incomeNetworks &&
            incomeNetworks
              .filter((v) => v.isChecked && v.isCanUse)
              .map((network) => (
                <GasTransfersRow transfer={network} key={network.route.id} />
              ))}
        </div>
      </div>
    </section>
  );
}
