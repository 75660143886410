import {
  COMMISION_AMOUNT_PERCENT,
  IS_MINUS_FEE_FROM_AMOUNT,
} from "../constants/TransferConstants";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../constants/ChainTypes";
import {
  getNetworkWalletAddress,
  isNetworkWalletConnected,
} from "../utils/WalletUtil";
import { getContractNetworks } from "../utils/NetworkUtil";
import { PROVIDER_METAMASK, PROVIDER_VENOM } from "../constants/ProviderTypes";

export function useIncomeNetworks(
  incomeNetworks,
  connectedWallets,
  rates,
  setIncomeNetworks,
  checkRateLoading,
  formData
) {
  const evmWallet = connectedWallets.find(
    (v) => v.providerType == PROVIDER_METAMASK
  );

  const venomWallet = connectedWallets.find(
    (v) => v.providerType == PROVIDER_VENOM
  );

  const toggleAllNetworksCnt = () => {
    return incomeNetworks.filter((v) => {
      let canChange = true;
      if (
        v.route.chainType == CHAIN_TYPE_TVM &&
        !connectedWallets.some((wallet) => wallet.networkId == v.route.id)
      ) {
        canChange = false;
      }
      if(v.route.chainType == CHAIN_TYPE_EVM && isSameWalletNetwork(v.route)){
        canChange = false;
      }
      return canChange;
    }).length;
  };

  const canCheckNetwork = (route) => {
    if (route.isComingSoon) {
      return false;
    }
    if (route.chainType == CHAIN_TYPE_EVM) {
      return true;
    }
    const hasIncorectNetwork = connectedWallets.some(
      (v) => route.wallets.includes(v.providerType) && !v.networkId
    );
    const isConnectedWallet = connectedWallets.some(
      (v) => v.networkId == route.id
    );
    return isConnectedWallet && !hasIncorectNetwork;
  };

  const isSameWalletNetwork = (route) => {
    return evmWallet && route.id == evmWallet.networkId;
  };

  const isSameSendNetwork = (route) => {
    // console.log(formData,'formData')
    return formData.routeFrom && route.id == formData.routeFrom.id;
  };

  const isCanSendNetwork =(route) =>{
    return !isSameSendNetwork(route);
  }

  const getAmountDivided = (amount) => {
    return IS_MINUS_FEE_FROM_AMOUNT
      ? parseFloat(amount * (100 - COMMISION_AMOUNT_PERCENT) * 0.01)
      : amount;
  };

  const getCheckNetworkCnt = () => {
    return incomeNetworks.filter(
      (v) => v.isChecked && isCanSendNetwork(v.route)
    ).length;
  };

  const calcAmountByNetwork = (amount, cnt) => {
    let amountWithoutFee = getAmountDivided(amount);
    let inAmountUsdt = cnt > 0 ? amountWithoutFee / cnt : 0;
    let amountPercent = cnt > 0 ? parseInt(100 / cnt) : null;
    return {
      inAmountUsdt,
      amountPercent,
    };
  };

  const makeNetworkItem = (network, perAmount, perAmountPercent) => {
    return {
      route: network,
      amountUSDT: perAmount,
      amountPercent: perAmountPercent,
      amountNative: null,
      address: getNetworkWalletAddress(connectedWallets, network),
      canEdit: isNetworkWalletConnected(connectedWallets, network),
      isEditOpen: false,
      isChecked: false, //network.chainType == CHAIN_TYPE_EVM,
      transactionId: null,
      isRecieved: false,
      isRejected: false,
      isAmountOverContractBalance: false,
      inTransactionHash: null,
      isCanUse: isCanSendNetwork(network),
    };
  };

  const initializeList = () => {
    let incomesList = [];
    const totalAmount = formData.amountFrom;

    const networks = getContractNetworks();
    networks.sort((a, b) => a.id - b.id); // b - a for reverse sort
    const perNetworkAmount = formData.amountFrom
      ? formData.amountFrom / networks.length
      : 0;
    const perNetworkAmountPercent = formData.amountFrom
      ? parseInt(100 / networks.length)
      : null;

    for (let index = 0; index < networks.length; index++) {
      const network = networks[index];
      incomesList.push(
        makeNetworkItem(network, perNetworkAmount, perNetworkAmountPercent)
      );
    }
    setIncomeNetworks([...incomesList]);
  };

  const updateItems = (
    amount,
    accountAddress = null,
    toggledNetwork = null,
    selectAll = null
  ) => {
    let cnt = getCheckNetworkCnt();

    if (toggledNetwork) {
      cnt += toggledNetwork.isChecked ? -1 : 1;
    }

    if (selectAll == 1) {
      cnt = toggleAllNetworksCnt();
    } else if (selectAll == 2) {
      cnt = 0;
    }

    const { inAmountUsdt, amountPercent } = calcAmountByNetwork(amount, cnt);

    const nextList = incomeNetworks.map((network) => {
      const rate = rates.find((v) => v.networkId == network.route.id);
      const nativeAmount = rate
        ? (rate.usdtRate * inAmountUsdt) /
          Math.pow(10, network.route.nativeCurrency.decimals)
        : null;
      let isChecked = network.isChecked;
      if (toggledNetwork && toggledNetwork.route.id == network.route.id) {
        isChecked = !network.isChecked;
      }
      if (selectAll == 1 && canCheckNetwork(network.route)) {
        isChecked = true;
      }
      if (selectAll == 2) {
        isChecked = false;
      }
      return {
        ...network,
        amountUSDT: parseFloat(inAmountUsdt).toFixed(2),
        amountFiat: rate
          ? ((rate.priceInUSD || 0) * nativeAmount).toFixed(5)
          : 0,
        amountPercent: amountPercent,
        amountNative: nativeAmount,
        address: network.address
          ? network.address
          : getNetworkWalletAddress(connectedWallets, network.route),
        canEdit: true, //isNetworkWalletConnected(connectedWallets, network.route),
        isChecked: isChecked && isCanSendNetwork(network.route),
        isCanUse: isCanSendNetwork(network.route),
        isAmountOverContractBalance: !rate
          ? true
          : inAmountUsdt > rate.balanceUsdt,
      };
    });
    // console.log(walletInfo, "walletInfo");
    // if (walletInfo.networkId) {
    //   let connectedIndex = nextList.findIndex(
    //     (v) => v.route.id == walletInfo.networkId
    //   );
    //   if (connectedIndex != -1) {
    //     nextList.splice(connectedIndex, 1);
    //   }
    //   console.log(nextList, "nextList");
    // }
    setIncomeNetworks([...nextList]);
    checkRateLoading();
  };

  return { updateItems, initializeList };
}
