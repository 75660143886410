import { CHAIN_TYPE_TVM } from "../constants/ChainTypes";
import { IS_DEV } from "../constants/GasStationParams";
import { DEF_MAINNET_CHAINS } from "../constants/ProdNetworks";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_TRON,
  PROVIDER_VENOM,
} from "../constants/ProviderTypes";
import { DEF_TESTNET_CHAINS } from "../constants/TestNetworks";
import { isEverWallet, isEvmWallet, isVenomWallet } from "./WalletUtil";

export function getNetworks() {
  let networks =
    process.env.REACT_APP_IS_TEST == "1"
      ? DEF_TESTNET_CHAINS
      : DEF_MAINNET_CHAINS;
  return networks.filter((v) => v.isInUse);
}

export function getContractNetworks() {
  return getNetworks().filter((v) => v.hasContract && v.isInUse);
}

const networkImageFolder = "/images/network-logos/";

export function getNetworkImageByChainId(networkChainId, imageSize = 'N') {
  let item = getNetworks().find((v) => v.chainId == networkChainId);
  let img = item?.img;
  if(imageSize == 'S'){
    img = item?.smallImg;
  }
  return item ? networkImageFolder + img : "";
}

export function getNetworkByChainIdAndWallet(chainId,walletType,connection = null) {
  let networks = getNetworks();
  return networks.find( v=> v.wallets.includes(walletType) && v.chainId == chainId && (connection == null ||  v.connection == connection));
}

export function getNetworkImage(network) {
  return network?.img ? networkImageFolder + network.img : "/img/currencies/ethereum.png";
}

export function getNetworkType(network) {
  return network.chainType;
}

export function getNetworkSymbol(network) {
  return network.nativeCurrency.symbol;
}

export function getNetworkName(network) {
  return network?.name || "Ethereum";
}

export function getNetworkExplorer(network) {
  return network.blockExplorerUrls[0];
}

export function getNetworkTransactionUrl(network,transactionHash){
  let baseUrl = getNetworkExplorer(network);
  return baseUrl +"/"+ ( network.chainType == CHAIN_TYPE_TVM ? 'transactions' : 'tx' )+"/" + transactionHash;
}

export function getNetworkTransactionListUrl(network,address){
  let baseUrl = getNetworkExplorer(network);
  if(network.chainType == CHAIN_TYPE_TVM){
    return baseUrl +"/"+ "accounts/" + address + "/transactions"; 
  }
  return baseUrl +"/address/" + address;
}

export function getExploreName(network){
  return network.scannName;
}

export function getNetworkTokenSymbol(networkChainId) {
  let item = getNetworks().find((v) => v.chainId == networkChainId);
  return item ? item.nativeCurrency.symbol : "";
}

export function getTokenImg(token) {
  let tokens = [
    { symbol: "USDT", logo: "/img/tokens-logos/token.png" },
    {
      symbol: "USDC",
      logo: "https://static.debank.com/image/coin/logo_url/usdc/e87790bfe0b3f2ea855dc29069b38818.png",
    },
    {
      symbol: "DAI",
      logo: "https://static.debank.com/image/matic_token/logo_url/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063/549c4205dbb199f1b8b03af783f35e71.png",
    },
    {
      symbol: "BUSD",
      logo: "https://static.debank.com/image/bsc_token/logo_url/0xe9e7cea3dedca5984780bafc599bd69add087d56/588ad5043e23b6c46aeda945852c3273.png",
    },
  ];
  let tokenData = tokens.find((v) => v.symbol == token.symbol);
  return token && token.logoURI
    ? token.logoURI
    : tokenData
    ? tokenData.logo
    : "/img/tokens-list-logos/omg.png";
}

export const POPULAR_TOKENS = [
  "USDT",
  "USDC",
  "BUSD",
  "DAI",
  "TUSD",
  "USDP",
  "GUSD",
  "sUSD",
  "FRAX",
  "ETH",
  "BNB",
  "AVAX",
  "WBTC",
  "WETH",
  "MATIC",
];

export const WalletList = [
  {
    title: "Metamask",
    key: PROVIDER_METAMASK,
    img: "./img/metamask-logo.png",
    imgLarge: "./images/metamask-logo-large.png",
    smallImg: "./img/metamask-wallet-icon.png",
    disabled: false,
    isInstalled: () => {
      return window.ethereum && window.ethereum.isMetaMask;
    },
    installUrl: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  },
  {
    title: "Ever Wallet",
    key: PROVIDER_EVERWALLET,
    img: "./img/ever-wallet-icon.png",
    imgLarge: "",
    smallImg: "./img/ever-wallet-icon.png",
    disabled: false,
    isInstalled: () => {
      return true;
    },
    installUrl: 'https://chrome.google.com/webstore/detail/ever-wallet/cgeeodpfagjceefieflmdfphplkenlfk',
  },
  {
    title: "Venom",
    key: PROVIDER_VENOM,
    img: "./img/venom-wallet.png",
    imgLarge: "",
    smallImg: "./img/venom-wallet.png",
    disabled: false,
    isInstalled: () => {
      return true;
    },
    installUrl: 'https://chrome.google.com/webstore/detail/venom-wallet/ojggmchlghnjlapmfbnjholfjkiidbch',
  },
  {
    title: "TronLink",
    key: PROVIDER_TRON,
    img: "./img/tron-wallet-icon.png",
    imgLarge: "https://tron.network/static/images/header/logo-red.svg",
    smallImg: "./img/tron-wallet-icon.png",
    disabled: true,
    isInstalled: () => {
      return true;
    },
    installUrl: 'https://chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec',
  },
];

export function getWalletServiceImg(key) {
  let item = WalletList.find((v) => v.key == key);
  return item ? item.imgLarge : "";
}

export function isCorrectAddress(address,network) {
  if(!address){
    return false;
  }
  let isCorrect = false;
  if(isEvmWallet(network)){
    isCorrect = address.substr(0, 2) == "0x" && address.length == 42;
  }
  if(isEverWallet(network) || isVenomWallet(network)){
    isCorrect = address.substr(0, 2) == "0:" && address.length == 66;
  }
  return isCorrect;
}
