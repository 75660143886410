import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_WARNING } from "../../constants/AlertTypes";
import {
  DEF_MAINNET_CHAINS,
  DEF_TESTNET_CHAINS,
  IS_DEV,
} from "../../constants/GasStationParams";
import {
  BSC_CHAIN_ID,
  BSC_TESTNET_CHAIN_ID,
  COMMISION_AMOUNT_PERCENT,
  DEFAULT_TOKEN_SYMBOL,
  ETH_CHAIN_ID,
  // MAX_TRANSFER_USDT,
  // MIN_TRANSFER_USDT,
  // MIN_TRANSFER_USDT_PER_NETWORK,
  POLYGON_CHAIN_ID,
  POLYGON_TESTNET_CHAIN_ID,
  TOKEN_DIRECTION_FROM,
} from "../../constants/TransferConstants";
import { showNotifaction } from "../../features/dialogs/notificationPopupSlice";
import { getBalanceByJsonRpc } from "../../features/walletService/balanceService";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  getNetworkImageByChainId,
  getNetworks,
  getTokenImg,
} from "../../utils/NetworkUtil";
import { getDataByUrl } from "../../utils/TransferApiUtil";
import ChooseTokenPopup from "../ChooseTokenPopup";
import GasButton from "./GasButton";
import { getNetworkWallet } from "../../utils/WalletUtil";
import { VenomContext } from "../../App";
import { CLAIM_BOT_ADDRESS } from "../../constants/SocialLinks";
import GasHelp from "./GasHelp";

export default function GasForm({
  formData,
  setFormData,
  startProcess,
  isIncomeNetworkSelected,
  hasAmountOverContractBalance,
  hasInCorrectAddress,
  validationError,
}) {
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showTokentPopup, toggleTokenPopup] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0.0);
  const venomProvider = useContext(VenomContext);

  const dispatch = useDispatch();
  let error = useSelector((state) => state.walletServiceProvider.error);
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );
  const limits = useSelector(
    (state) => state.limitManager
  );

  const walletInfo = getNetworkWallet(connectedWallets, formData.routeFrom);
  // let walletInfo = useSelector(
  //   (state) => state.walletServiceProvider.walletInfo
  // );

  useEffect(() => {
    loadRoutes();
  }, []);

  useEffect(() => {
    onRouteLoad();
  }, [routes]);

  useEffect(() => {
    if (walletInfo.networkChainId && formData.routeFrom) {
      getTokenAmount();
    }
    if (!walletInfo.networkChainId) {
      setTokenBalance(0.0);
    }
  }, [formData.cryptoFrom, walletInfo.networkChainId]);

  const loadRoutes = () => {
    setLoading(true);
    let routes = getNetworks();
    setRoutes(routes);
    setDefaultRoute(routes);
    setLoading(false);
  };

  const isTestStend = process.env.REACT_APP_IS_TEST == "1";
  const setDefaultRoute = (networks) => {
    let defRoutChainId =
      process.env.REACT_APP_IS_TEST == "1"
        ? POLYGON_TESTNET_CHAIN_ID
        : BSC_CHAIN_ID;
    let routeFrom = networks.find((v) => v.chainId == defRoutChainId); // [0];
    if (!routeFrom) {
      routeFrom = networks[0];
    }
    if (formData.cryptoFrom && formData.routeFrom) {
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      ...{
        cryptoFrom: routeFrom.cryptos[0],
        routeFrom: routeFrom,
      },
    }));
  };

  const fieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onlyNumber = (e) => {
    if (!/[0-9]|\./.test(e.key)) {
      e.preventDefault();
    }
  };

  function openTokenSelector(type) {
    toggleTokenPopup(true);
  }

  function closeTokenPopup(selectedItem) {
    toggleTokenPopup(false);
    setErrorMsg(null);
    if (selectedItem) {
      setFormData((prevState) => ({
        ...prevState,
        ...{
          amountFromUSD: 0,
          routeFrom: selectedItem.route,
          cryptoFrom: selectedItem.token,
          quoteFrom: 0,
        },
      }));
    }
  }

  const setErrorMsg = (errorText) => {
    if (errorText != error) {
      dispatch(
        showNotifaction({ alertType: ALERT_WARNING, caption: errorText })
      );
    }
    setShowErrorPopup(errorText != null);
  };

  const onRouteLoad = () => {
    if (formData.cryptoFrom && formData.routeFrom) {
      return;
    }
    if (routes.length == 0) return;

    let routeFrom;
    let routeTo;

    if (walletInfo.networkChainId) {
      routeFrom = routes.find(
        (v) => parseInt(v.chainId) === parseInt(walletInfo.networkChainId)
      );
      if (!routeFrom) {
        routeFrom = routes.find((v) => v.chainId == BSC_CHAIN_ID);
      }
    } else {
      routeFrom = routes.find((v) => v.chainId == BSC_CHAIN_ID);
    }
    if (routeFrom) {
      let cryptoFrom = routeFrom.cryptos.find(
        (v) => v.symbol === DEFAULT_TOKEN_SYMBOL
      );

      setFormData((prevState) => ({
        ...prevState,
        ...{
          cryptoFrom: cryptoFrom,
          routeFrom: routeFrom,
          amountFrom: "",
        },
      }));
    }
  };

  const getTokenAmount = async () => {
    let balance = 0;
    if (
      !walletInfo.accountAddress ||
      !formData.routeFrom ||
      !formData.cryptoFrom
    )
      return balance;
    try {
      balance = await getBalanceByJsonRpc({
        rpcUrl: formData.routeFrom.rpcUrls ? formData.routeFrom.rpcUrls[0] : "",
        networkType: formData.routeFrom.chainType,
        contractAddress: formData.cryptoFrom.contractAddress,
        decimals: formData.cryptoFrom.decimals,
        walletAddress: walletInfo.accountAddress,
        network: formData.routeFrom,
        venomProvider: venomProvider,
      });
      setTokenBalance(balance);
    } catch (error) {
      setErrorMsg("Error on get Balance");
    }
    return balance;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const isAmountNotEntered =
    formData.amountFrom == null || formData.amountFrom == "";

  const isLessAmount =
    parseFloat(formData.amountFrom || 0) > parseFloat(tokenBalance);

  const isBigAmountLimit =
    parseFloat(formData.amountFrom || 0) > parseFloat(limits.maxUsdAmount);

  // const isAmountBiggerThanMin =
  //   parseFloat(formData.amountFrom || 0) >= parseFloat(MIN_TRANSFER_USDT);

  const isBtnDisabled =
    isAmountNotEntered ||
    isLessAmount ||
    isBigAmountLimit ||
    !isIncomeNetworkSelected ||
    // !isAmountBiggerThanMin ||
    hasAmountOverContractBalance ||
    hasInCorrectAddress ||
    validationError.hasError;

  let warningblock = "";
  if (walletInfo.accountAddress) {
    if (isLessAmount) {
      warningblock = (
        <span className="error-message">Wallet balance is insufficient</span>
      );
    }else if (validationError.hasError) {
      warningblock = (
        <span className="error-message">{validationError.errorText}</span>
      );
    // } else if (formData.amountFrom && !isAmountBiggerThanMin) {
    //   warningblock = (
    //     <span className="error-message">
    //       Amount less then min amount ${MIN_TRANSFER_USDT}
    //     </span>
    //   );
    } else if (formData.amountFrom && isBigAmountLimit) {
      warningblock = (
        <span className="error-message">
          Amount big then max amount ${limits.maxUsdAmount}
        </span>
      );
    } else if (hasAmountOverContractBalance) {
      warningblock = (
        <span className="error-message">
          Amount is bigger then contract liquidity
        </span>
      );
    } else if (hasInCorrectAddress) {
      warningblock = (
        <span className="error-message">
          Please set adresses in send networks
        </span>
      );
    } 
  }

  const openClaimBot = () => {
    window.open(CLAIM_BOT_ADDRESS, "_blank");
  };
  return (
    <>
      <form className={"gas-station__form "} onSubmit={handleSubmit}>
        <div className="gas-station__input-header">
          <span>Swap</span>
          <span>Balance: {tokenBalance}</span>
        </div>
        <div
          className={
            "gas-station__input-block " + (isLessAmount ? "error" : "")
          }
        >
          <input
            type="text"
            onKeyPress={(e) => onlyNumber(e)}
            placeholder="Enter amount"
            name="amountFrom"
            value={formData.amountFrom}
            onChange={fieldChange}
          />
          <button
            className="choose-token-button choose-token-button_dark"
            onClick={() => openTokenSelector()}
          >
            {formData.cryptoFrom && (
              <>
                <div className="choose-token-button__icons">
                  <img
                    className="choose-token-button__icon choose-token-button__icon_token"
                    src={getTokenImg(formData.cryptoFrom)}
                    alt=""
                  />
                  <img
                    className="choose-token-button__icon choose-token-button__icon_network"
                    src={getNetworkImageByChainId(formData.routeFrom.chainId,'S')}
                    alt=""
                  />
                </div>
                <div className="choose-token-button__value">
                  <span className="choose-token-button__token">
                    {" "}
                    {formData.cryptoFrom.symbol}
                  </span>
                  <span className="choose-token-button__network">
                    on {formData.routeFrom.name}
                  </span>
                </div>
              </>
            )}
          </button>
        </div>
        {warningblock}
        {isTestStend && formData.routeFrom && formData.routeFrom.id == 11  && (
          <div className="gas-station__claim-btn">
            <button className="btn btn_dark" onClick={() => openClaimBot()}>
              Claim ATT token
            </button>
            <span className="gas-station__claim-btn-tooltip">
              Connect VENOM wallet first
            </span>
          </div>
        )}

        <div className="gas-station__form-attention">
          <span>
            The asset you specified will be exchanged for the native coins of
            each selected blockchain(s).
          </span>
        </div>
        <div className="gas-station__form-params">
          <div className="gas-station__form-params-line">
            <span className="gas-station__form-params-name">
              Minimum swap amount per network
            </span>
            <span className="gas-station__form-params-value">
              ${limits.minUsdAmountPerChain}
            </span>
          </div>
          {/* <div className="gas-station__form-params-line">
            <span className="gas-station__form-params-name">
              Commission for liquidity allocation
            </span>
            <span className="gas-station__form-params-value">
              {COMMISION_AMOUNT_PERCENT}%
            </span>
          </div> */}
        </div>
        <GasButton
          formData={formData}
          selectedRoute={formData.routeFrom}
          startProcess={startProcess}
          isBtnDisabled={isBtnDisabled}
        />
        <GasHelp/>
      </form>
      <ChooseTokenPopup
        show={showTokentPopup}
        // tokens={tokens}
        formData={formData}
        directType={TOKEN_DIRECTION_FROM}
        selectedTokenRoute={formData.routeFrom}
        routes={routes}
        closePopup={closeTokenPopup}
      />
    </>
  );
}
