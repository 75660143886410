import {
  STATE_ATTENTION,
  STATE_REJECTED,
  STATE_SUCCESS,
  STATE_WAITING,
} from "../../constants/GasFormState";
import {
  APPROVE_STARTED,
  TRANSFER_CONFIRMED,
  TRANSFER_SENDED,
  TRANSFER_FINISHED,
  APPROVE_REJECTED,
  TRANSFER_REJECTED,
} from "../../constants/ProcessSteps";
import GasTransactionHash from "./GasTransactionHash";

export default function GasFormOnProcess({
  formData,
  handleOk,
  transactionHash,
}) {
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const getWaitingLabel = () => {
    let label = "";
    switch (formData.transactionStep) {
      case APPROVE_STARTED:
        label = "Token approving";
        break;
      case TRANSFER_CONFIRMED:
        label = "Transaction proccessing by network";
        break;
      default:
        label = "Please wait";
        break;
    }
    return label;
  };

  return (
    <>
      <form className={"gas-station__form "} onSubmit={handleSubmit}>
        <div className="gas-station__status-block">
          <h2 className="gas-station__status-title">
            Transaction confirmation
          </h2>

          {formData.processState == STATE_WAITING && (
            <div className="gas-station__status-label">
              <span className="gas-station__status-loader"></span>
              <span className="gas-station__status-caption">
                {getWaitingLabel()}
              </span>
              <GasTransactionHash transactionHash={transactionHash} />
            </div>
          )}
          {formData.processState == STATE_SUCCESS && (
            <div className="gas-station__status-label">
              <img src="/img/status-success-icon.svg" alt="" />
              <span className="gas-station__status-caption">
                Check transactions status
                <br />
                in each chain
              </span>
              <GasTransactionHash transactionHash={transactionHash} />
            </div>
          )}
          {formData.processState == STATE_REJECTED && (
            <div className="gas-station__status-label">
              <img src="/img/status-rejected-icon.svg" alt="" />
              <span className="gas-station__status-caption">
                Transaction rejected
              </span>
            </div>
          )}
          {formData.processState == STATE_ATTENTION && (
            <div className="gas-station__status-label">
              <img src="../img/status-attention-icon.svg" alt="" />
              <span className="gas-station__status-caption">
                You have already initiated transaction,
                <br />
                please check your web3 wallet
              </span>
            </div>
          )}
        </div>
        
        <button
          className="gas-station__form-submit btn btn_primary btn_dark tooltip-cnt"
          onClick={() => handleOk()}
          type="button"
          disabled={![APPROVE_REJECTED, TRANSFER_REJECTED, TRANSFER_FINISHED].includes(formData.transactionStep)}
        >
          {formData.transactionStep == TRANSFER_SENDED && (
            <div className="tooltip-popup">
              <span>Please wait until all transactions are completed</span>
            </div>
          )}
          {formData.transactionStep == TRANSFER_FINISHED
            ? "Swap again"
            : "Okay"}
        </button>
      </form>
    </>
  );
}
