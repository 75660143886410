import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSwitchNetwork,
  disconnect,
  openSwitchNetwork,
  PROVIDER_METAMASK,
  setConnectingWallet,
} from "../../features/walletService/walletService";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  getNetworkImageByChainId,
  getNetworks,
  WalletList,
} from "../../utils/NetworkUtil";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../../constants/ChainTypes";

export default function SwitchNetworkPopup({}) {
  let switchNetwork = useSelector(
    (state) => state.walletServiceProvider.switchNetwork
  );
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  let dispatch = useDispatch();

  // const networks = getNetworks();
  const network = switchNetwork?.network; //networks.find((v) => switchNetwork?.network.chainId == v.chainId);
  const [processState, setProcessState] = useState(false);

  const switchNetworkHandle = async () => {
    setProcessState(true);
    let isChanged = await MetamaskWebProvider.changeChain(
      walletInfo,
      dispatch,
      network
    );
    if (isChanged) {
      dispatch(closeSwitchNetwork());
    } else {
    }
    setProcessState(false);
  };

  if (!network) {
    return null;
  }

  return (
    <div
      className={
        "wrong-network-popup popup dark " +
        (switchNetwork.isOpen ? "active" : "")
      }
      id="wrongNetworkPopup"
    >
      <div className="popup__container">
        <a
          className="popup__close"
          href="#"
          onClick={() => dispatch(closeSwitchNetwork())}
        ></a>
        {network.chainType == CHAIN_TYPE_TVM && (
          <>
            <p className="wrong-network-popup__description">
              Please change to {network.name} network in your wallet.
            </p>
          </>
        )}
        {network.chainType == CHAIN_TYPE_EVM && (
          <>
            <h1 className="wrong-network-popup__title popup__title">
              Switch networks
            </h1>
            <p className="wrong-network-popup__description">
              Wrong network detected, switch or disconnect to continue.
            </p>

            <button
              className="wrong-network-popup__switch-network-btn"
              onClick={() => switchNetworkHandle()}
            >
              <img
                src={getNetworkImageByChainId(network.chainId)}
                width="40"
                height="40"
                alt=""
              />
              {network.name}{" "}
              {processState && (
                <span className="wrong-network-popup__switch-network-btn-confirm">
                  Confirm in wallet
                </span>
              )}
            </button>

            <button
              className="wrong-network-popup__disconnect-btn"
              onClick={() => dispatch(disconnect(PROVIDER_METAMASK))}
            >
              <img
                src="./img/disconnect-icon.svg"
                width="24"
                height="24"
                alt=""
              />
              Disconnect
            </button>
          </>
        )}
      </div>
    </div>
  );
}
