import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IsAsterizmPage } from "../../../utils/RouteUtils";
import { getMenuItems } from "../../../data/menu";
import { HeaderActions } from "./HeaderActions";
import { MenuList } from "./MenuList";
import MobileMenuButton from "./MobileMenuButton";

function TopMenu() {
  let links = getMenuItems(false);
  links = links.filter((v) => v.title != "Whitelist");
  const activeClass = (route) => {
    return window.location.pathname === route ? "active" : null;
  };
  let dispatch = useDispatch();

  return (
    <>
      <header className={`header ` + (IsAsterizmPage() ? "header_dark" : "")}>
        {/* <button className="header__menu-button" onClick={() => dispatch(toggleMobileMenu())}></button> */}
        <NavLink className={"logo-link"} to="https://chainspot.io/">
          <img
            className="logo"
            src={
              "/logo/" +
              (IsAsterizmPage()
                ? "chainspot-logo_dark.png"
                : "chainspot-logo-color.png")
            }
            alt=""
          />
        </NavLink>
        <MenuList links={links} navClass={"main-menu_header"} />
        <HeaderActions />
        <MobileMenuButton />
      </header>
    </>
  );
}
export default TopMenu;
