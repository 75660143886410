export const IS_DEV = false;

// ethereum
// bnbpolygon
// avalanche
// fantom
// aurora
// optimism
// tron
// arbitrum
// harmony
// bitgert
// ton
// near
// evescale
