import { BigNumber, ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import axios from "../app/axios";
import { INIT } from "../constants/ProcessSteps";
import { MIN_NETWORK_TRANSFER_AMOUNT, ROUND_DECIMAL_CNT, STABLE_DECIMALS } from "../constants/TransferConstants";
import ERC20Abi from "../utils/ERC20Abi";
import { getContractNetworks, getNetworks } from "../utils/NetworkUtil";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../constants/ChainTypes";
import { EverWalletWebProvider } from "../services/everWallet/everWalletWebProvider";
import { PROVIDER_METAMASK, PROVIDER_VENOM } from "../constants/ProviderTypes";
import { checkNetworkWalletType, isEverWallet, isEvmWallet, isVenomWallet } from "../utils/WalletUtil";
import { PROVIDER_EVERWALLET } from "../features/walletService/walletService";
import { VenomWalletWebProvider } from "../services/venom/venomWalletWebProvider";
import { CHECK_LOAD_RATE } from "../constants/CheckProcessStep";
import { MetamaskWebProvider } from "../services/metamask/MetamaskWebProvider";

export function LoadNetworkRates(transactionStep,venomProvider, checkProcess) {
  // const rates = useRef([]);
  const STATE_NOT_LOADED = 0;
  const STATE_LOADING = 1;
  const STATE_LOAD_FINISHED = 2;
  const loadingState = useRef(STATE_NOT_LOADED);
  const [apiRates, setApiRates] = useState([]);

  useEffect(() => {
    // let networks = getNetworks();
    // console.log(loadingState.current);
    if( (transactionStep == INIT && loadingState.current == STATE_LOAD_FINISHED) || checkProcess ==  CHECK_LOAD_RATE){
      loadApiRates();
    }
    // console.log(transactionStep,'transactionStep')
    // networks.forEach(async (currentNetwork) => {
    //   loadNetworkRate(currentNetwork);
    // });
  },[transactionStep,venomProvider, checkProcess]);

  useEffect(() => {
    // let networks = getNetworks();
    
    loadApiRates();
    // networks.forEach(async (currentNetwork) => {
    //   loadNetworkRate(currentNetwork);
    // });
  },[]);

  const loadApiRates = async  () =>{
    if(loadingState.current == STATE_LOADING){
      return;
    } 
    loadingState.current =STATE_LOADING;
    let res = await axios.get("rate/get-list");
    let data = res.data.data;
    let newRates = [];
    let networks = getContractNetworks();
    // console.log(data,'data')
    data.forEach(coin =>{
      let network = networks.find( v=> {
        return v.chainId == coin.network.chainId
        // console.log(v.server.symbol == coin.network.symbol && v.server.title == coin.network.title,v.server.symbol , coin.network.symbol,v.server.symbol == coin.network.symbol,v.server.title , coin.network.title,v.server.title == coin.network.title)
        // return v.server.symbol == coin.network.symbol && v.server.title == coin.network.title
      });
      // console.log(network,networks,coin,coin.network.symbol,coin.network.title,'network,coin');
      if(!network){
        return;
      }
      let oneUsdGetWie = BigNumber.from(coin.coinsForUSD);
      let formatedRate = oneUsdGetWie.div(BigNumber.from(10).pow(network.nativeCurrency.decimals - ROUND_DECIMAL_CNT ));
      newRates.push({
        networkId:network.id,
        chainId:network.sendChainId,
        rate: formatedRate.toNumber(),
        usdtRate: coin.coinsForUSD,
        priceInUSD:coin.priceInUSD,
        loadedStep: 1,
        minAmount:MIN_NETWORK_TRANSFER_AMOUNT
      })
      setApiRates(newRates);
      // apiRates.current = [...newRates];
      if(isEvmWallet(network)){
        loadContractEvmBalance(network,newRates);
      }
      if(isEverWallet(network)){
        loadContractEverBalance(network,newRates);
      }
      if(isVenomWallet(network)){
        loadContractVenomBalance(network,newRates);
      }
    })
    loadingState.current = STATE_LOAD_FINISHED;

  }

  const setNetwokrBalance = (newRates,currentNetwork,balance,minAmount) =>{
    return [...newRates].map((v) => {
      if (v.networkId == currentNetwork.id) {
        v.balance = balance;
        let balanceUsdt = BigNumber.from(balance).div(BigNumber.from(v.usdtRate)).toNumber();
        v.balanceUsdt = balanceUsdt;
        v.loadedStep =  2;
        v.minAmount = parseInt(minAmount)
      }
      return v;
    });
  }

  const loadContractEvmBalance =async  (currentNetwork,newRates) =>{
    const provider = new ethers.providers.JsonRpcProvider(
      currentNetwork.rpcUrls[0]
    );

    let balance = await provider.getBalance(currentNetwork.gasSenderAddress);

    let minAmount = await MetamaskWebProvider.getNetworkMinAmount(currentNetwork);

    let list = setNetwokrBalance(newRates,currentNetwork,balance,minAmount);
    setApiRates(list);
  }

  const loadContractVenomBalance =async  (currentNetwork,newRates) =>{
    if(!venomProvider){
      return;
    }
    const isWalletConnected = await VenomWalletWebProvider.isWalletConnected(venomProvider);
    if (!isWalletConnected) {
      return;
    }
  
    let balance = await VenomWalletWebProvider.getContractBalance(currentNetwork.gasSenderAddress,venomProvider)
    let minAmount = await VenomWalletWebProvider.getNetworkMinAmount(currentNetwork,venomProvider);
    let list = setNetwokrBalance(newRates,currentNetwork,balance,minAmount);
    setApiRates(list);
    // apiRates.current = [...list];
  }

  const loadContractEverBalance =async  (currentNetwork,newRates) =>{
    const isWalletConnected = await EverWalletWebProvider.isWalletConnected();
    if (!isWalletConnected) {
      return;
    }
  
    let balance = await EverWalletWebProvider.getContractBalance(currentNetwork.gasSenderAddress)
    let minAmount = await EverWalletWebProvider.getNetworkMinAmount(currentNetwork);

    let list = setNetwokrBalance(newRates,currentNetwork,balance,minAmount);
    setApiRates(list);
  }
  return apiRates;
}
