import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNetworkImage,
  getNetworkName,
  getNetworkSymbol,
  getNetworkType,
  isCorrectAddress,
} from "../../utils/NetworkUtil";

export default function GetNetworkRow({
  network,
  openEditAddress,
  onNetworkChecked,
  updateAddress,
}) {
  if (!network.isCanUse) {
    return;
  }
  if (network.isEditOpen) {
    return (
      <GasNetworksRowOnEdit
        network={network}
        openEditAddress={openEditAddress}
        updateAddress={updateAddress}
      />
    );
  }
  return (
    <GasNetworksRowRead
      network={network}
      openEditAddress={openEditAddress}
      onNetworkChecked={onNetworkChecked}
    />
  );
}

function GasNetworksRowOnEdit({ network, openEditAddress, updateAddress }) {
  const [val, setVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setAddressError] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoading(true);

      let isCorrect = isCorrectAddress(val, network.route);
      setAddressError(!isCorrect);

      if (isCorrect) {
        updateAddress(network, val);
      }
      // setUpdated(message);
      setLoading(false);
    } else {
      setAddressError(false);
    }
  };

  const inputReference = useRef(null);

  useEffect(() => {
    inputReference.current.focus();
  }, []);

  return (
    <div
      className={
        "gas-station__networks-table-row " +
        (hasError
          ? "gas-station__networks-table-row_error"
          : "gas-station__networks-table-row_warning")
      }
    >
      <div className="gas-station__networks-table-row-content">
        <img
          className="gas-station__networks-error"
          src="../img/error-icon.svg"
          alt=""
        />
        <GasNetworksRowLine
          network={network}
          openEditAddress={openEditAddress}
          hasCheckbox={false}
        />
      </div>
      <div className="gas-station__input-cnt">
        {loading && (
          <span className="gas-station__input-loader loader-white"></span>
        )}
        {val &&
          val.length > 0 &&
          network.route &&
          isCorrectAddress(val, network.route) && (
            <button
              className="btn-apply btn"
              onClick={() => updateAddress(network, val)}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3092_49021)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.957 5.12286V0.885742H10.957V5.12286C10.957 6.1304 10.1403 6.94717 9.13273 6.94717H2.18531L4.51374 4.61874C4.709 4.42348 4.709 4.10689 4.51374 3.91163C4.31848 3.71637 4.00189 3.71637 3.80663 3.91163L0.624652 7.09361C0.42939 7.28887 0.42939 7.60546 0.624652 7.80072L3.80663 10.9827C4.00189 11.178 4.31848 11.178 4.51374 10.9827C4.709 10.7874 4.709 10.4709 4.51374 10.2756L2.18531 7.94717H9.13273C10.6925 7.94717 11.957 6.68268 11.957 5.12286Z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3092_49021">
                    <rect width="12" height="12" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          )}
        {network.route && (
          <button
            className={
              "erase-btn " +
              (isCorrectAddress(val, network.route)
                ? "erase-btn_apply"
                : "gas-station__erase-btn")
            }
            onClick={() => updateAddress(network, null)}
          ></button>
        )}
        <input
          className={"gas-station__input " + (hasError ? "error" : "warning")}
          type="text"
          name=""
          id=""
          placeholder="Enter address"
          value={val}
          onChange={(event) => {
            setVal(event.target.value);
          }}
          ref={inputReference}
          onKeyDown={handleKeyDown}
        />
        {hasError && <span className="error-message">Incorrect address</span>}
      </div>
    </div>
  );
}

function GasNetworksRowRead({ network, openEditAddress, onNetworkChecked }) {
  return (
    <div
      className={
        "gas-station__networks-table-row " +
        (network.isChecked &&
        (network.isAmountOverContractBalance || !network.address)
          ? "gas-station__networks-table-row_has_error"
          : "") +
        (network.route.isComingSoon
          ? " gas-station__networks-table-row_disabled "
          : "")
      }
    >
      <GasNetworksRowLine
        network={network}
        openEditAddress={openEditAddress}
        onNetworkChecked={onNetworkChecked}
      />
    </div>
  );
}

function GasNetworksRowLine({
  network,
  openEditAddress,
  onNetworkChecked,
  hasCheckbox = true,
}) {
  // let walletInfo = useSelector(
  //   (state) => state.walletServiceProvider.walletInfo
  // );
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const formatNativeAmount = (amount) => {
    var parts = (amount.toFixed(18) + "").split(".");
    if (parseInt(parts[0]) > 0) {
      return amount.toFixed(2);
    } else {
      let arr = parts[1].split("");
      let index2 = arr.findIndex(
        (v, index) =>
          parseInt(v) > 0 && (!arr[index + 1] || parseInt(arr[index + 1]) == 0)
      );
      return amount.toFixed(index2 != -1 ? index2 + 1 : 3);
    }
  };

  return (
    <>
      {hasCheckbox && (
        <label className="checkbox">
          <input
            className="checkbox__input"
            type="checkbox"
            name=""
            id=""
            checked={network.isChecked}
            disabled={network.route.isComingSoon}
            onChange={() => onNetworkChecked(network)}
          />
          <span className="checkbox__button"></span>
        </label>
      )}
      <div className="gas-station__networks-table-row-item i-asset">
        <span className="gas-station__network-icon">
          <img src={getNetworkImage(network.route)} alt="" />
        </span>
        <div className="gas-station__networks-table-row-item-title">
          <span>
            {network.isChecked && network.amountNative
              ? formatNativeAmount(network.amountNative)
              : null}{" "}
            {getNetworkSymbol(network.route)}
          </span>
          <span>on {getNetworkName(network.route)}</span>
        </div>
      </div>
      <span className="gas-station__networks-table-row-item i-type">
        {getNetworkType(network.route)}
      </span>
      <span
        className={
          "gas-station__networks-table-row-item i-amount " +
          (network.amountUSDT && network.isChecked ? "" : "empty")
        }
      >
        {network.amountUSDT && network.isChecked && "$" + network.amountFiat}{" "}
        {network.amountFiat > 0 && network.amountPercent && network.isChecked
          ? "(" + network.amountPercent + "%" + ")"
          : ""}
      </span>
      <span className="gas-station__networks-table-row-item i-address">
        {network.canEdit && (
          <button
            className="edit-button"
            disabled={network.route.isComingSoon}
            onClick={() => openEditAddress(network)}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_369)">
                <path d="M8.03363 0.0914958C7.97503 0.0329111 7.89555 0 7.81269 0C7.72983 0 7.65036 0.0329111 7.59175 0.0914958L6.56238 1.12087L8.87925 3.43775L9.90863 2.409C9.93773 2.37997 9.96082 2.34548 9.97657 2.30752C9.99233 2.26955 10.0004 2.22885 10.0004 2.18775C10.0004 2.14664 9.99233 2.10594 9.97657 2.06797C9.96082 2.03001 9.93773 1.99552 9.90863 1.9665L8.03363 0.0914958ZM8.43738 3.87962L6.1205 1.56275L2.058 5.62525H2.18738C2.27026 5.62525 2.34974 5.65817 2.40835 5.71677C2.46695 5.77538 2.49988 5.85487 2.49988 5.93775V6.25025H2.81238C2.89526 6.25025 2.97474 6.28317 3.03335 6.34177C3.09195 6.40038 3.12488 6.47987 3.12488 6.56275V6.87525H3.43738C3.52026 6.87525 3.59974 6.90817 3.65835 6.96677C3.71695 7.02538 3.74988 7.10487 3.74988 7.18775V7.50025H4.06238C4.14526 7.50025 4.22474 7.53317 4.28335 7.59177C4.34195 7.65038 4.37488 7.72987 4.37488 7.81275V7.94212L8.43738 3.87962ZM3.76988 8.54712C3.75673 8.51215 3.74995 8.47511 3.74988 8.43775V8.12525H3.43738C3.3545 8.12525 3.27501 8.09232 3.21641 8.03372C3.1578 7.97511 3.12488 7.89563 3.12488 7.81275V7.50025H2.81238C2.7295 7.50025 2.65001 7.46732 2.59141 7.40872C2.5328 7.35011 2.49988 7.27063 2.49988 7.18775V6.87525H2.18738C2.1045 6.87525 2.02501 6.84232 1.96641 6.78372C1.9078 6.72511 1.87488 6.64563 1.87488 6.56275V6.25025H1.56238C1.52502 6.25018 1.48797 6.24341 1.453 6.23025L1.34113 6.3415C1.31135 6.37149 1.28796 6.40721 1.27238 6.4465L0.0223788 9.5715C-0.000352871 9.62829 -0.00591756 9.6905 0.0063746 9.75042C0.0186668 9.81034 0.0482752 9.86534 0.0915292 9.9086C0.134783 9.95185 0.189781 9.98146 0.249703 9.99375C0.309626 10.006 0.371839 10.0005 0.428629 9.97775L3.55363 8.72775C3.59292 8.71217 3.62864 8.68878 3.65863 8.659L3.76988 8.54712Z" />
              </g>
              <defs>
                <clipPath id="clip0_1_369">
                  <rect width="10" height="10" />
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
        {!network.canEdit || network.address == null
          ? "Not set"
          : network.address.slice(0, 3) + "..." + network.address.slice(-3)}
      </span>
    </>
  );
}
