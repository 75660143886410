import React from "react";
import GasStationBlock from "../../components/Gas/GasStationBlock";

export default function GasStation() {
  return (
    <main className="main" style={{background: 'none'}}>
          <div className="gas-station-wrapper">
            <GasStationBlock />
            </div>
    </main>
  );
}
