import { ethers, utils } from "ethers";
import { Contract, Provider } from "ethers-multicall";
import ERC20Abi from "../../utils/ERC20Abi";
import { cutNumber, isZeroAddress, truncateDecimals } from "./utils";
import { Contract as Contract2 } from "ethers";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../../constants/ChainTypes";
import { EverWalletWebProvider } from "../../services/everWallet/everWalletWebProvider";
import {
  checkNetworkWalletType,
  isEverWallet,
  isEvmWallet,
  isVenomWallet,
} from "../../utils/WalletUtil";
import { PROVIDER_EVERWALLET, PROVIDER_METAMASK } from "./walletService";
import { VenomWalletWebProvider } from "../../services/venom/venomWalletWebProvider";
import { PROVIDER_VENOM } from "../../constants/ProviderTypes";

const MAX_CHUNK_LIMIT = 100;
export const getWalletTokenAmounts = async (
  network,
  tokens,
  walletAddress,
  venomProvider = null
) => {
  if (isEvmWallet(network)) {
    return await getEVMWalletTokenAmounts(network, tokens, walletAddress);
  } else if (isEverWallet(network)) {
    return await getEVErWalletTokenAmounts(network, tokens, walletAddress);
  } else if (isVenomWallet(network)) {
    return await getVenomWalletTokenAmounts(
      network,
      tokens,
      walletAddress,
      venomProvider
    );
  }
  return null;
};

export const getEVMWalletTokenAmounts = async (
  network,
  tokens,
  walletAddress
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const ethcallProvider = new Provider(provider);
  await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor
  let methodsList = [];

  let chunks = [];
  let num = 0;

  tokens.forEach((token, index) => {
    let tokenBalanceCall;
    if (isZeroAddress(token.contractAddress)) {
      tokenBalanceCall = ethcallProvider.getEthBalance(walletAddress);
    } else {
      const contract = new Contract(token.contractAddress, ERC20Abi);
      tokenBalanceCall = contract.balanceOf(walletAddress);
    }
    // if (chunks.length == 0)
    if (tokenBalanceCall) methodsList.push(tokenBalanceCall);
    num++;
    if (num == MAX_CHUNK_LIMIT) {
      chunks.push(methodsList);
      methodsList = [];
      num = 0;
      return;
    }
  });

  if (methodsList.length > 0) chunks.push(methodsList);

  let networkBalance = {
    walletAddress: walletAddress,
    id: network.id,
    tokens: [],
  };

  for (let index = 0; index < chunks.length; index++) {
    try {
      let res = await ethcallProvider.all(chunks[index]);
      if (res) {
        res.forEach((balance, sub_index) => {
          let formatedBalance = parseFloat(
            utils.formatUnits(
              balance,
              tokens[index * MAX_CHUNK_LIMIT + sub_index].decimals
            )
          );
          tokens[index * MAX_CHUNK_LIMIT + sub_index].balance =
            formatedBalance.toFixed(formatedBalance > 1 ? 2 : 4);

          if (formatedBalance != 0)
            tokens[index * MAX_CHUNK_LIMIT + sub_index].formatedBalance =
              formatedBalance.toFixed(formatedBalance > 1 ? 2 : 4);

          tokens[index * MAX_CHUNK_LIMIT + sub_index].isBalanceLoaded = true;

          networkBalance.tokens.push({
            contractAddress:
              tokens[index * MAX_CHUNK_LIMIT + sub_index].contractAddress,
            balance: formatedBalance.toFixed(formatedBalance > 1 ? 2 : 4),
          });
        });
      }
    } catch (error) {
      for (let num = 0; num < chunks[index].length; num++) {
        tokens[index * MAX_CHUNK_LIMIT + num].isBalanceLoaded = true;
        networkBalance.tokens.push({
          contractAddress:
            tokens[index * MAX_CHUNK_LIMIT + num].contractAddress,
          balance: 0,
          isBalanceLoaded: true,
        });
      }
    }
    // const element = array[index]
  }
  return networkBalance;
};

export const getEVErWalletTokenAmounts = async (
  network,
  tokens,
  walletAddress
) => {
  const isWalletConnected = await EverWalletWebProvider.isWalletConnected();
  if (!isWalletConnected) {
    return tokens;
  }

  let networkBalance = {
    walletAddress: walletAddress,
    id: network.id,
    tokens: [],
  };

  for (let index = 0; index < tokens.length; index++) {
    let token = tokens[index];
    let balance = await EverWalletWebProvider.getTokenBalance(
      token.contractAddress
    );
    let formatedBalance = parseFloat(
      utils.formatUnits(balance, token.decimals)
    );
    tokens[index].balance = formatedBalance.toFixed(
      formatedBalance > 1 ? 2 : 4
    );

    if (formatedBalance != 0)
      tokens[index].formatedBalance = formatedBalance.toFixed(
        formatedBalance > 1 ? 2 : 4
      );

    tokens[index].isBalanceLoaded = true;

    networkBalance.tokens.push({
      contractAddress: token.contractAddress,
      balance: formatedBalance.toFixed(formatedBalance > 1 ? 2 : 4),
    });
    // const element = array[index]
  }
  return networkBalance;
};

export const getVenomWalletTokenAmounts = async (
  network,
  tokens,
  walletAddress,
  venomProvider
) => {
  const isWalletConnected = await VenomWalletWebProvider.isWalletConnected(
    venomProvider
  );
  if (!isWalletConnected) {
    return tokens;
  }

  let networkBalance = {
    walletAddress: walletAddress,
    id: network.id,
    tokens: [],
  };

  for (let index = 0; index < tokens.length; index++) {
    let token = tokens[index];
    let balance = await VenomWalletWebProvider.getTokenBalance(
      token.contractAddress,
      venomProvider
    );
    let formatedBalance = parseFloat(
      utils.formatUnits(balance, token.decimals)
    );
    tokens[index].balance = formatedBalance.toFixed(
      formatedBalance > 1 ? 2 : 4
    );

    if (formatedBalance != 0)
      tokens[index].formatedBalance = formatedBalance.toFixed(
        formatedBalance > 1 ? 2 : 4
      );

    tokens[index].isBalanceLoaded = true;

    networkBalance.tokens.push({
      contractAddress: token.contractAddress,
      balance: formatedBalance.toFixed(formatedBalance > 1 ? 2 : 4),
    });
    // const element = array[index]
  }
  console.log(networkBalance, "networkBalance");
  return networkBalance;
};

export const getBalanceByJsonRpcOld = async (
  networkRpc,
  tokenContractAddress,
  tokenDecimals,
  walletAddress
) => {
  let balance = 0;
  // const provider = new ethers.providers.Web3Provider(window.ethereum)
  const provider = new ethers.providers.JsonRpcProvider(networkRpc);
  const ethcallProvider = new Provider(provider);
  await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor

  let tokenBalanceCall;
  if (isZeroAddress(tokenContractAddress)) {
    tokenBalanceCall = ethcallProvider.getEthBalance(walletAddress);
  } else {
    const contract = new Contract(tokenContractAddress, ERC20Abi);
    tokenBalanceCall = contract.balanceOf(walletAddress);
  }

  try {
    let res = await ethcallProvider.all([tokenBalanceCall]);
    if (res) {
      balance = res[0];
      balance = parseFloat(utils.formatUnits(balance, tokenDecimals));
      balance = truncateDecimals(balance); //.toFixed(4);
    }
  } catch (error) {
    balance = 0;
  }
  return balance;
};

export const getBalanceByJsonRpc = async (
  getBalanceDto = {
    rpcUrl: null,
    networkType: null,
    contractAddress: null,
    decimals: null,
    walletAddress: null,
    network: null,
    venomProvider: null,
  }
) => {
  let result = {
    hasError: false,
    error: null,
    balance: 0,
  };
  if (checkNetworkWalletType(getBalanceDto.network, [PROVIDER_METAMASK])) {
    result = await getEvmBalance(getBalanceDto);
  }
  if (checkNetworkWalletType(getBalanceDto.network, [PROVIDER_EVERWALLET])) {
    result = await getEverBalance(
      getBalanceDto.contractAddress,
      getBalanceDto.decimals
    );
  }
  if (checkNetworkWalletType(getBalanceDto.network, [PROVIDER_VENOM])) {
    result = await getVenomBalance(
      getBalanceDto.contractAddress,
      getBalanceDto.decimals,
      getBalanceDto.venomProvider
    );
  }
  return result.balance;
};

export const getEvmBalance = async (
  getBalanceDto = {
    rpcUrl: null,
    networkType: null,
    contractAddress: null,
    decimals: null,
    walletAddress: null,
  }
) => {
  const provider = new ethers.providers.JsonRpcProvider(getBalanceDto.rpcUrl);
  let tokenBalanceCall;
  if (isZeroAddress(getBalanceDto.contractAddress)) {
    tokenBalanceCall = provider.getBalance(getBalanceDto.walletAddress);
  } else {
    const contract = new Contract2(
      getBalanceDto.contractAddress,
      ERC20Abi,
      provider
    );
    tokenBalanceCall = contract.balanceOf(getBalanceDto.walletAddress);
  }

  let result = {
    hasError: false,
    error: null,
    balance: 0,
  };
  try {
    let balance = await tokenBalanceCall;
    balance = parseFloat(utils.formatUnits(balance, getBalanceDto.decimals));
    balance = truncateDecimals(balance); //.toFixed(4);
    result.balance = balance;
  } catch (error) {
    result.hasError = true;
    result.error = error;
  }
  return result;
};

export const getEverBalance = async (contractAddress, decimals) => {
  let result = {
    hasError: false,
    error: null,
    balance: 0,
  };
  try {
    let balance = await EverWalletWebProvider.getTokenBalance(contractAddress);
    balance = parseFloat(utils.formatUnits(balance, decimals));
    balance = truncateDecimals(balance); //.toFixed(4);
    result.balance = balance;
  } catch (error) {
    result.hasError = true;
    result.error = error;
  }
  return result;
};

export const getVenomBalance = async (
  contractAddress,
  decimals,
  venomProvider
) => {
  let result = {
    hasError: false,
    error: null,
    balance: 0,
  };
  try {
    let balance = await VenomWalletWebProvider.getTokenBalance(
      contractAddress,
      venomProvider
    );
    balance = parseFloat(utils.formatUnits(balance, decimals));
    balance = truncateDecimals(balance); //.toFixed(4);
    result.balance = balance;
  } catch (error) {
    result.hasError = true;
    result.error = error;
  }
  return result;
};
