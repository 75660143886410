import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { Address } from "everscale-inpage-provider";
import { EverWalletWebProvider } from "../../services/everWallet/everWalletWebProvider";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { TronLinkWebProvider } from "../../services/tronLink/TronLinkWebProvider";
import { isClientWelcomeRead, setWelcomRead } from "../../utils/BrowserUtil";
import { localStorageObj } from "../localstorage";
import { CHAIN_TYPE_EVM } from "../../constants/ChainTypes";
import { getNetworkWallet, getWalletType } from "../../utils/WalletUtil";
import { PROVIDER_VENOM } from "../../constants/ProviderTypes";
import { venomConnectInit } from "./venomwalletService";
import { VenomWalletWebProvider } from "../../services/venom/venomWalletWebProvider";
import { getNetworkByChainIdAndWallet } from "../../utils/NetworkUtil";

export const PROVIDER_METAMASK = "metamask";
export const PROVIDER_EVERWALLET = "everWallet";
export const PROVIDER_TRON = "tronWallet";

export const CONNECTED_PROVIDERS = "connectedProviders";
export const getWalletAmount = async (walletInfo, formData) => {
  let balance = 0;
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      balance = await MetamaskWebProvider.getBalance(
        formData.cryptoFrom,
        walletInfo,
        formData.routeFrom
      );
      break;
    case PROVIDER_EVERWALLET:
      balance = await EverWalletWebProvider.getBalance(
        new Address(walletInfo.accountAddress)
      );
      break;
  }
  return balance;
};

export const sendWalletTransaction = async (
  walletInfo,
  transactionData,
  dispatch
) => {
  let transData = null;
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      transData = await MetamaskWebProvider.sendTransaction(
        transactionData,
        dispatch
      );
      break;
    case PROVIDER_EVERWALLET:
      break;
  }
  return transData;
};

export const autoConnectWallet = createAsyncThunk(
  "autoconnect/wallet",
  async (__, { dispatch }) => {
    let param = localStorageObj.get(CONNECTED_PROVIDERS)
    let connectedProviders = param ? JSON.parse(param) : [];
    let walletInfo = { ...initialState.walletInfo };
    if (connectedProviders.length == 0) {
      return walletInfo;
    }
    
    for (let index = 0; index < connectedProviders.length; index++) {
      let walletItem = { ...initialState.walletInfo };
      const providerType = connectedProviders[index];
      switch (providerType) {
        case PROVIDER_METAMASK:
          walletItem = await MetamaskWebProvider.autoConnect(
            dispatch,
            walletItem
          );
          break;
        case PROVIDER_EVERWALLET:
          walletItem = await EverWalletWebProvider.connect(
            dispatch,
            walletItem
          );
          break;
        case PROVIDER_TRON:
          walletItem = await TronLinkWebProvider.connect(dispatch, walletItem);
          break;
        case PROVIDER_VENOM:
          dispatch(venomConnectInit())
          break
        default:
          break;
      }

      walletItem.providerType = providerType;
      if (walletItem && walletItem.isConnected) {
        // console.log(walletItem,'walletItem')
        dispatch(setWallet(walletItem));
      }
  
    }

  }
);

export const connectWallet = createAsyncThunk(
  "connect/wallet",
  async (providerType, { dispatch, getState }) => {
    dispatch(
      setConnectingWallet({
        status: true,
        providerType: providerType,
      })
    );

    let isConnected = true;
    if (providerType) {
      switch (providerType) {
        case PROVIDER_METAMASK:
          isConnected = await MetamaskWebProvider.isConnected();
          break;
        case PROVIDER_EVERWALLET:
          isConnected = await EverWalletWebProvider.isConnected();
          break;
        case PROVIDER_TRON:
          isConnected = await TronLinkWebProvider.isConnected();
          break;
        case PROVIDER_VENOM:
          isConnected = true;
        default:
          break;
      }
    }
    if (!providerType || !isConnected) {
      localStorageObj.remove(CONNECTED_PROVIDERS);
      dispatch(
        setConnectingWallet({
          status: false,
          providerType: providerType,
        })
      );
      dispatch(toggleInstallingPopup(providerType));
      return { ...initialState.walletInfo };
    }
    const state = getState();
    let walletInfo = {
      accountAddress: null,
      balance: null,
      isConnected: false,
      providerType: providerType,
    };
    let isHookConnect = false;
    switch (providerType) {
      case PROVIDER_METAMASK:
        walletInfo = await MetamaskWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_EVERWALLET:
        walletInfo = await EverWalletWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_TRON:
        walletInfo = await TronLinkWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_VENOM:
        isHookConnect = true;
        dispatch(venomConnectInit())
        break
      default:
        break;
    }
    if (walletInfo && walletInfo.isConnected) {

      // storeConnectedWallets(providerType);
      dispatch(setWallet(walletInfo));
    }
    if(!isHookConnect)
    dispatch(
      setConnectingWallet({
        status: false,
        providerType: providerType,
      })
    );

    return walletInfo;
  }
);

const storeConnectedWallets = (providerType) =>{
  let text = localStorageObj.get(CONNECTED_PROVIDERS);
  let connectedProviders = text ? JSON.parse(text) : [];
  if(!connectedProviders.some(v => v == providerType)){
    connectedProviders.push(providerType);
  }
  localStorageObj.set(CONNECTED_PROVIDERS, JSON.stringify(connectedProviders));
}

export const getTokenBalance = async (
  provider,
  accountAddress,
  routeFrom,
  cryptoFrom
) => {
  let balance = 0;
  switch (provider) {
    case PROVIDER_METAMASK:
      balance = await MetamaskWebProvider.getTokenBalanceByContractAddress(
        routeFrom,
        cryptoFrom,
        accountAddress
      );
      break;
  }
  return balance;
};

export const initialState = {
  walletInfo: {
    networkId:null,
    accountAddress: null,
    balance: null,
    isConnected: false,
    providerType: null,
    networkChainId: null,
  },
  allWallets: [], //{providerKey:, address:networkData:}
  showWalletTypePopup: false,
  showAgreementPopup: false,
  connectingWallet: false,
  connectingWalletkey: null,
  showWalletInfoPopup: false,
  showWalletDisconnectPopup: false,
  showWelcomePopup: false,
  switchNetwork: {
    isOpen: false,
    networkId: null,
  },
  status: "idle",
  error: null,
  preselectedWalletKey: null,
  showWalletConnect: false,
  showTVMConnectWalletPopup: false,
  showTVMConnectWalletType: null,
  installWalletKey: null,
};

export const walletServiceProvider = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    accountChanged: (state, action) => {
      if (action.payload) state.walletInfo.accountAddress = action.payload;
    },
    networkChanged: (state, action) => {
      // if(action.payload.networkType == CHAIN_TYPE_EVM){
        
      // }
      const walletTypes =  getWalletType(action.payload.networkType);
      const newWallets = state.allWallets.map( wallet=> {
        if(walletTypes.includes(wallet.providerType)){
          const network = getNetworkByChainIdAndWallet(action.payload.networkChainId,wallet.providerType,action.payload.connection);
          wallet.networkChainId = action.payload.networkChainId;
          wallet.networkId = network ? network.id : 0;
        }
        return wallet;
      })
      state.allWallets = newWallets;
      // state.walletInfo.networkChainId = parseInt(action.payload);
    },
    walletTypeDialogToggle: (state) => {
      state.showWalletTypePopup = !state.showWalletTypePopup;
    },
    showAgreementDialog: (state, action) => {
      state.showWalletTypePopup = false;
      state.showAgreementPopup = true;
      state.preselectedWalletKey = action.payload;
    },
    showWelcomeConnectDialog: (state, action) => {
      if (action?.payload != null) {
        state.showWelcomePopup = action.payload;
      } else {
        state.showWelcomePopup = !state.showWelcomePopup;
      }
      // if (state.showWelcomePopup == false) {
      //   setWelcomRead();
      // }
    },
    agreementDialogToggle: (state) => {
      state.showAgreementPopup = !state.showAgreementPopup;
    },
    showConnectTvmPopup: (state,action) => {
      state.showTVMConnectWalletPopup = !state.showTVMConnectWalletPopup;
      state.showTVMConnectWalletType = state.showTVMConnectWalletPopup ? action.payload : null
    },
    providerSelected: (state, provider) => {
      state.providerType = provider;
    },
    walletDisconnectDialogToggle: (state) => {
      state.showWalletDisconnectPopup = !state.showWalletDisconnectPopup;
    },
    walletInfoDialogToggle: (state) => {
      state.showWalletInfoPopup = !state.showWalletInfoPopup;
    },
    openSwitchNetwork: (state, action) => {
      state.switchNetwork = {
        isOpen: true,
        network: action.payload,
      };
    },
    closeSwitchNetwork: (state) => {
      state.switchNetwork = {
        isOpen: false,
        networkId: null,
      };
    },
    disconnect: (state, action) => {
      if (state.allWallets.length == 1) {
        localStorageObj.remove(CONNECTED_PROVIDERS);
        state.walletInfo = { ...initialState };
      }else{
        let providers = localStorageObj.get(CONNECTED_PROVIDERS);
        let connected = providers ? JSON.parse(providers) : [];
        let findIndex = connected.findIndex( v=> v== action.payload);
        if(findIndex != -1){
          connected.splice(findIndex,1)
          localStorageObj.set(CONNECTED_PROVIDERS,JSON.stringify(connected));
        }
      }
      state.allWallets = state.allWallets.filter(
        (v) => v.providerType != action.payload
      );
      state.walletInfo = state.allWallets.length > 0 ? state.allWallets[0] : initialState.walletInfo; 
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setConnectingWallet: (state, action) => {
      state.connectingWallet = action.payload.status;
      state.connectingWalletkey = action.payload.providerType;
      if (!state.connectingWallet && state.showWalletConnect) {
        state.showWalletConnect = false;
      }
    },
    toggleInstallingPopup: (state, action) => {
      state.installWalletKey = action.payload;
    },
    setWallet: (state, action) => {
      if (action.payload.providerType == PROVIDER_METAMASK) {
        state.walletInfo = { ...action.payload };
      }

      let isHas = state.allWallets.findIndex(
        (v) => v.providerType == action.payload.providerType
      );
      storeConnectedWallets(action.payload.providerType);
      if (!isClientWelcomeRead()) {
        state.showWelcomePopup = true;
      }
      if (isHas == -1) {
        let wallets = [...state.allWallets]; 
        wallets.push({
          networkId:action.payload.networkId,
          providerType: action.payload.providerType,
          accountAddress: action.payload.accountAddress,
          networkChainId: action.payload.networkChainId,
          balance: action.payload.balance,
        });
        state.allWallets = wallets;
        // console.log(state.allWallets,'state.allWallets')
      }
    },
    walletConnectDialogToggle: (state) => {
      state.showWalletConnect = !state.showWalletConnect;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(connectWallet.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(connectWallet.fulfilled, (state, action) => {
        state.status = "succeeded";
        // if (action.payload) state.walletInfo = action.payload;
        state.showWalletTypePopup = false;
        state.showAgreementPopup = false;
      })
      .addCase(connectWallet.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.showWalletTypePopup = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  accountChanged,
  walletTypeDialogToggle,
  walletInfoDialogToggle,
  walletDisconnectDialogToggle,
  providerSelected,
  disconnect,
  setError,
  networkChanged,
  setWallet,
  setConnectingWallet,
  agreementDialogToggle,
  showAgreementDialog,
  walletConnectDialogToggle,
  openSwitchNetwork,
  closeSwitchNetwork,
  showWelcomeConnectDialog,
  showConnectTvmPopup,
  toggleInstallingPopup
} = walletServiceProvider.actions;

export default walletServiceProvider.reducer;
