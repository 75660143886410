import { useEffect, useState } from "react";
import GasQuizStep1 from "./GasQuizStep1";
import GasQuizStep2 from "./GasQuizStep2";
import GasQuizStep3 from "./GasQuizStep3";

export default function GasQuiz() {
  useEffect(() => {
    setQuizStep(1);
  }, []);

  const [quizStep, setQuizStep] = useState(1);

  return (
    <div className="quiz">
      {quizStep == 1 && <GasQuizStep1 setQuizStep={setQuizStep} />}
      {quizStep == 2 && <GasQuizStep2 />}
      {quizStep == 3 && <GasQuizStep3 />}
    </div>
  );
}
