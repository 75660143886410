import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getMenuItems } from "../../../data/menu";
import { toggleMobileMenu } from "../../../features/menu/menuSlice";

function MobileMenuButton() {
  let dispatch = useDispatch();

  return (
    <button
      className="mobile-menu-btn mobile-menu-btn_dark"
      onClick={() => dispatch(toggleMobileMenu())}
    >
      <svg
        width="20"
        height="11"
        viewBox="0 0 20 11"
        fill="#142438"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.74228e-08 1.74846e-06L20 0L20 1L0 1L-8.74228e-08 1.74846e-06Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.74228e-08 5L20 5L20 6L0 6L-8.74228e-08 5Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.74228e-08 10L20 10L20 11L0 11L-8.74228e-08 10Z"
        />
      </svg>
    </button>
  );
}
export default MobileMenuButton;
