import { VENOM_TESTNET } from "../services/venom/venomWalletWebProvider";
import {
  CHAIN_TYPE_EVM,
  CHAIN_TYPE_TVM,
  CHAIN_TYPE_TVM_VENOM,
} from "./ChainTypes";
import { PROVIDER_EVERWALLET, PROVIDER_METAMASK, PROVIDER_VENOM } from "./ProviderTypes";

export const DEF_TESTNET_CHAINS = [
  {
    id: 10,
    isInUse: true,
    wallets : [PROVIDER_EVERWALLET],
    chainType: CHAIN_TYPE_TVM,
    chainId: 42,
    connection: "testnet",
    name: "Everscale",
    sendChainId: 20001,
    chainName: "Ever",
    img: "ever-24x24.png",
    smallImg: "ever-24x24.png",
    formClass: "everscale",

    server: {
      symbol: "EVER",
      title: "Everscale Testnet",
    },

    hasContract: true,
    gasSenderAddress:
      "0:11a7ea3c198a07b9488e3f01709d35dcf7f4bdb99ce5495e72b1d1069e2646dc",

    // rpcUrls: [
    //   "https://endpoints.omniatech.io/v1/eth/goerli/public",
    //   "https://eth-goerli.public.blastapi.io",
    // ],
    nativeCurrency: {
      name: "EVER",
      symbol: "EVER", // 2-6 characters long
      decimals: 9,
    },
    blockExplorerUrls: ["https://testnet.everscan.io"],
    scannName: "Ethercan",

    cryptos: [
      {
        name: "ATT",
        symbol: "ATT",
        contractAddress:
          "0:8c6dcaa30727458527e99a479dae92a92a51c24e235e5b531659e201204d79ee",
        decimals: 9,
        isShowOnSend: true,
        logoURI: null,
      },
    ],
  },
  {
    id: 12,
    isInUse: true,
    wallets : [PROVIDER_VENOM],
    chainType: CHAIN_TYPE_TVM,
    chainId: VENOM_TESTNET,
    connection: "testnet",
    name: "VENOM",
    sendChainId: 30001,
    chainName: "Venom",
    img: "venom.svg",
    smallImg: "venom.svg",
    walletImg:'img/venom-icon.svg',
    formClass: "venom",

    server: {
      symbol: "VNM",
      title: "Venom Testnet",
    },

    hasContract: true,
    gasSenderAddress:
      "0:f5718ca386cfd431dff84a01ec0801751d0a9e69252b69cbe8ba3a4cd001f8ec",

    // rpcUrls: [
    //   "https://endpoints.omniatech.io/v1/eth/goerli/public",
    //   "https://eth-goerli.public.blastapi.io",
    // ],
    nativeCurrency: {
      name: "VENOM",
      symbol: "VENOM", // 2-6 characters long
      decimals: 9,
    },
    blockExplorerUrls: ["https://testnet.venomscan.live"],
    scannName: "Venomscan",

    cryptos: [
      {
        name: "ATT",
        symbol: "ATT",
        contractAddress:
          "0:4a2219d92ed7971c16093c04dc2f442925fcfb4f1c7f18fc4b6b18cf100b27aa",
        decimals: 9,
        isShowOnSend: true,
        logoURI: null,
      },
    ],
  },
  {
    id: 20,
    isInUse: false,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 420,
    name: "Optimism",
    sendChainId: 420,
    chainName: "Optimism Goerli Testnet",
    img: "Optimism-24x24.svg",
    smallImg: "Optimism-24x24.svg",
    formClass: "optimism",

    gasSenderAddress: "0x18BBfC07b344FF85Fb8e165b51F8BFE5c83Fea30",

    rpcUrls: ["https://goerli.optimism.io"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },

    blockExplorerUrls: ["https://goerli-optimism.etherscan.io"],
    checkUrl: "https://api-goerli-optimistic.etherscan.io/",
    apiKey: "59439IY7RQHZJ5KW3BY3C6YKJUSSH7HQXW",

    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x95e8df0299F57b9DaBcEDf9e307407234e5A5bD3",
        decimals: 6,
        logoURI: null,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xC108c33731a62781579A28F33b0Ce6AF28a090D2",
        decimals: 6,
        logoURI: null,
      },
    ],
  },
  {
    id: 21,
    isInUse: true,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 80001,
    name: "Polygon",
    sendChainId: 80001,
    chainName: "Mumbai",
    img: "polygon-24x24.svg",
    smallImg: "polygon-14x14.svg",
    formClass: "polygon",

    server: {
      symbol: "POL",
      title: "Polygon Mumbai",
    },

    hasContract: true,
    gasSenderAddress: "0x897A0Dc3555a76434Ee96df813f583c346b4bc87",

    rpcUrls: [
      "https://rpc-mumbai.maticvigil.com/",
      "https://matic-testnet-archive-rpc.bwarelabs.com",
      "https://polygon-testnet.public.blastapi.io	",
      "https://polygontestapi.terminet.io/rpc",
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    scannName: "Polygonscan",
    checkUrl: "https://api-testnet.polygonscan.com/",
    apiKey: "9EJ8QGQI17F6563HASRB2KYF5AN999I82N",

    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0xA02f6adc7926efeBBd59Fd43A84f4E0c0c91e832",
        decimals: 6,
        logoURI: null,
        isStable: true,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xE097d6B3100777DC31B34dC2c58fB524C2e76921",
        decimals: 6,
        logoURI: null,
        isStable: true,
        isShowOnSend: true,
      },
      {
        name: "ATT",
        symbol: "ATT",
        contractAddress: "0x7f4F94A70e5E7236c7a14D04fd749FF5b7023bE8",
        decimals: 6,
        logoURI: null,
        isStable: true,
        isShowOnSend: true,
      },
    ],
  },
  {
    id: 22,
    isInUse: true,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 11155111,
    name: "Sepolia",
    sendChainId: 11155111,
    chainName: "Sepolia",
    img: "ethereum-24x24.svg",
    smallImg: "ethereum-24x24.svg",
    formClass: "optimism",

    server: {
      symbol: "ETH",
      title: "Ethereum",
    },

    hasContract: true,
    gasSenderAddress: "0x7Aeb6eA9F31c9d976A2486968879d8d784a4468E",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/eth/sepolia/public",
      "https://rpc2.sepolia.org",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://sepolia.etherscan.io"],
    scannName: "Sepoliascan",
    // checkUrl: "https://api-testnet.polygonscan.com/",
    // apiKey: "9EJ8QGQI17F6563HASRB2KYF5AN999I82N",

    cryptos: [
      // {
      //   name: "USDT",
      //   symbol: "USDT",
      //   contractAddress: '0x509Ee0d083DdF8AC028f2a56731412edD63223B9',
      //   decimals: 6,
      //   logoURI: null
      // },
      // {
      //   name: "USDC",
      //   symbol: "USDC",
      //   contractAddress: '0x08209a62C202c2DAF27d2796bb574779cC345889',
      //   decimals: 6,
      //   logoURI: null
      // },
      {
        name: "ATT",
        symbol: "ATT",
        contractAddress: "0xc8e37E456c517D682ca8F343e46BF4DEFFd24D13",
        decimals: 6,
        logoURI: null,
        isStable: true,
        isShowOnSend: true,
      },
    ],
  },
  {
    id: 23,
    isInUse: false,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 4918,
    name: "Venidium",
    sendChainId: 4918,
    chainName: "Venidium testnet",
    img: "venidium.svg",
    smallImg: "venidium.svg",
    formClass: "venidium",

    server: {
      symbol: "XVM",
      title: "Venidium Testnet",
    },

    hasContract: true,
    gasSenderAddress: "0x372824586fEe6388208D55021D6eeaE9f88d636B",

    rpcUrls: ["https://rpc-evm-testnet.venidium.io"],
    nativeCurrency: {
      name: "FELICETTE",
      symbol: "XVM",
      decimals: 18,
    },
    blockExplorerUrls: ["https://evm-testnet.venidiumexplorer.com"],
    scannName: "Venidumscan",
    // checkUrl: "https://api-testnet.polygonscan.com/",
    // apiKey: "9EJ8QGQI17F6563HASRB2KYF5AN999I82N",

    cryptos: [
      {
        name: "XVM",
        symbol: "XVM",
        contractAddress: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI: null,
        isStable: false,
        isShowOnSend: false,
      },
    ],
  },
  {
    id: 24,
    isInUse: false,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 5,
    name: "Goerli",
    sendChainId: 5,
    chainName: "Goerli",
    img: "ethereum-24x24.svg",
    smallImg: "ethereum-24x24.svg",
    formClass: "optimism",

    server: {
      symbol: "ETH",
      title: "Ethereum Goerli",
    },

    hasContract: true,
    gasSenderAddress: "0x353E7f6CfdfbF15F20896A15a09703E28A88b8A3",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/eth/goerli/public",
      "https://eth-goerli.public.blastapi.io",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://goerli.etherscan.io"],
    scannName: "Ethercan",
    checkUrl: "https://goerli.etherscan.io",
    apiKey: "9EJ8QGQI17F6563HASRB2KYF5AN999I82N",

    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x509Ee0d083DdF8AC028f2a56731412edD63223B9",
        decimals: 6,
        logoURI: null,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x08209a62C202c2DAF27d2796bb574779cC345889",
        decimals: 6,
        logoURI: null,
      },
      {
        name: "AUSTD",
        symbol: "AUSTD",
        contractAddress: "0xB528B84BBcc7c6737F984dfD8ed7C9A77C530a4D",
        decimals: 6,
        logoURI: null,
      },
    ],
  },
  {
    id: 25,
    isInUse: true,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 4002,
    name: "Fantom",
    sendChainId: 4002,
    chainName: "Fantom",
    img: "fantom-24x24.svg",
    smallImg: "fantom-24x24.svg",
    formClass: "fantom",

    server: {
      symbol: "FTM",
      title: "Fantom Testnet",
    },

    hasContract: true,
    gasSenderAddress: "0xaa7C1D2f415C4647C5E4fC47b83cA123D4F1b647",

    rpcUrls: [
      "https://rpc.testnet.fantom.network"
    ],
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.ftmscan.com"],
    scannName: "Fantomscan",

    cryptos: [
    ],
  },
  {
    id: 26,
    isInUse: true,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 97,
    name: "BNB",
    sendChainId: 97,
    chainName: "BNB",
    img: "bsc-24x24.svg",
    smallImg: "bsc-24x24.svg",
    formClass: "bnb",

    server: {
      symbol: "BSC",
      title: "BSC",
    },

    hasContract: true,
    gasSenderAddress: "0xE6C80c2858f7EA5bB325d41D6e205ea3b13364FF",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    ],
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    scannName: "BscScan",

    cryptos: [
    ],
  },
  {
    id: 27,
    isInUse: false,
    wallets : [PROVIDER_METAMASK],
    chainType: CHAIN_TYPE_EVM,
    chainId: 5611,
    name: "opBNB",
    sendChainId: 5611,
    chainName: "opBNB",
    img: "bsc-24x24.svg",
    smallImg: "bsc-24x24.svg",
    formClass: "bnb",

    server: {
      symbol: "OPBNB",
      title: "opBNB Testnet",
    },

    hasContract: true,
    gasSenderAddress: "0xc8e37E456c517D682ca8F343e46BF4DEFFd24D13",

    rpcUrls: [
      "https://opbnb-testnet-rpc.bnbchain.org"
    ],
    nativeCurrency: {
      name: "tBNB",
      symbol: "tBNB", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://opbnb-testnet.bscscan.com"],
    scannName: "opBNBScan",

    cryptos: [
    ],
  },
 
];
