import { BigNumber, utils } from "ethers";
import {
  ProviderRpcClient,
  hasEverscaleProvider,
  Address,
} from "everscale-inpage-provider";
import { setError } from "../../features/walletService/walletService";
import { EverContractAbi } from "../../abis/EvetContract";
import { EverTokenWalletAbi } from "../../abis/EverTokenWalletAbi";
import { EverTokenRootAbi } from "../../abis/EverTokenRootAbi";
import { PROVIDER_EVERWALLET } from "../../constants/ProviderTypes";
import { getNetworks } from "../../utils/NetworkUtil";
import { MIN_NETWORK_TRANSFER_AMOUNT } from "../../constants/TransferConstants";

export const EverWalletWebProvider = {
  ever: function () {
    return new ProviderRpcClient();
  },
  isWalletInstalled: async function () {
    return await hasEverscaleProvider();
  },
  isConnected: async function () {
    const isInstalled = await this.isWalletInstalled();
    if (!isInstalled) {
      return isInstalled;
    }

    const ever = this.ever();
    let isConnected = false;
    if (await ever.hasProvider()) {
      try {
        await ever.ensureInitialized();
        isConnected = true;
      } catch (error) {}
    }
    return isConnected;
  },
  connect: async function (dispatch, walletInfo) {
    const ever = this.ever();
    if (!(await ever.hasProvider())) {
      dispatch(setError("Extension Ever is not installed"));
      return;
    }

    const hasPermission = await this.checkIsGivedPermission();
    if(hasPermission){
      const { accountInteraction } = await ever.requestPermissions({
        permissions: ["basic", "accountInteraction"],
      });
  
      if (accountInteraction == null) {
        dispatch(setError("Insufficient permissions"));
        return;
      }
    }
    const currentProviderState = await ever.getProviderState();

    const selectedAddress = currentProviderState.permissions.accountInteraction.address;

    const loadedNetwork = getNetworks().find(
      (v) =>
        v.wallets.includes(PROVIDER_EVERWALLET) &&
        v.chainId == currentProviderState.networkId &&
        v.connection == currentProviderState.selectedConnection
    );
    let balance = await this.getBalance(selectedAddress);
    walletInfo.networkId = loadedNetwork?.id; 
    walletInfo.accountAddress = selectedAddress.toString();
    walletInfo.isConnected = true;
    walletInfo.networkChainId = currentProviderState.networkId;
    walletInfo.isConnected = true;
    walletInfo.balance = balance;
    return walletInfo;
  },
  listenNetworChanges: async function () {
    return await this.ever().subscribe("networkChanged");
  },
  getBalance: async function (address) {
    const ever = this.ever();
    const { state } = await ever.getFullContractState({
      address: address,
    });
    return state ? parseFloat(utils.formatUnits(BigNumber.from(state.balance),9)) : 0;
    // return state ? state.balance : 0
  },
  disconnect: async function () {
    await this.ever().disconnect();
  },
  checkNetwork: async function () {
    const currentProviderState = await this.ever().getProviderState();
    if (
      currentProviderState.networkId !== 42 ||
      currentProviderState.selectedConnection !== "testnet"
    ) {
      // Ask user to change the network
    } else {
      // Everything is okay
    }
  },
  checkIsGivedPermission: async function(){
    const currentProviderState = await this.ever().getProviderState();
    return currentProviderState && currentProviderState.supportedPermissions && currentProviderState.supportedPermissions.includes("accountInteraction") ;
  },
  makeTransaction: async function (
    contractAddress = "0:debf6cfa1ac0d78df4824ea5c7a10e41818436452b9ae16967f8c98ac4deacc7"
  ) {
    let ever = this.ever();
    const currentProviderState = await ever.getProviderState();
    const userAddress =
      currentProviderState.permissions.accountInteraction.address;
    const publicKey =
      currentProviderState.permissions.accountInteraction.publicKey;

    const TokenDiceContractAddress = new Address(contractAddress);
    const tokenDiceContract = new ever.Contract(
      EverContractAbi,
      TokenDiceContractAddress
    );
    // const t = await tokenDiceContract.methods.buildGasPayload({_chainIds:['80001'],_amounts:['100000000000'],_receivers:['104977116852773033473302213193565098772534650229']}).sendExternal({
    //   publicKey: publicKey
    // });
    // const t = await tokenDiceContract.methods.buildGasPayload({_chainIds:['80001'],_amounts:['100000000000'],_receivers:['104977116852773033473302213193565098772534650229']}).sendWithResult({
    //   amount: "1500000000",
    //   // bounce: true,
    //   from: userAddress
    // });

    // console.log(t,'resp')
    // const exampleContract = new ever.Contract(exampleAddress, exampleAbi);

    // Create a subscriber
    const subscriber = new ever.Subscriber();

    // Subscribe to contract events
    const contractEvents = tokenDiceContract.events(subscriber);


    const firstTx = await tokenDiceContract.methods
      .buildGasPayload({
        _chainIds: ["80001"],
        _amounts: ["100000000000"],
        _receivers: ["104977116852773033473302213193565098772534650229"],
      })
      .send({
        amount: "0",
        bounce: true,
        from: userAddress,
      });

    if (firstTx) {
      // At this point, the external message we sent is already
      // included in the block, and we just need to wait for all
      // transactions in the chain to be finished

      // Update user's UI to notify "the transaction has already
      // been added to the blockchain, waiting for finalization"

      // Wait until all transactions are finished
      // and watch for the Game event.

      let playTx;
      const subscriber = new ever.Subscriber();
      await subscriber
        .trace(firstTx)
        .tap((tx_in_tree) => {
          playTx = tx_in_tree;
        })
        .finished();

      // Decode events by using abi
      // we are looking for event Game(address player, uint8 bet, uint8 result, uint128 prize);

      let events = await tokenDiceContract.decodeTransactionEvents({
        transaction: playTx,
      });
      // if (events.length !== 1 || events[0].event !== 'Game') {
      //   throw new Error('Something is wrong');
      // }

      // if (events[0].data.result === events[0].data.bet) {
      //   // User won, update UI
      //   const amountOfPrizeBeauty = `${new BigNumber(events[0].data.prize).shiftedBy(-1 * decimals).toFixed(2, BigNumber.ROUND_DOWN)} ${symbol}`;
      // } else {
      //   // User lose, update UI.
      // }
    }

    // const t2 = await tokenDiceContract.methods.buildGasPayload({_chainIds:['80001'],_amounts:['100000000000'],_receivers:['104977116852773033473302213193565098772534650229']}).call();
    // const subscriber = new ever.Subscriber();
    // console.log(tokenDiceContract,'resp')

    // const TokenRootContractAddress = new Address(
    //   "0:e56ffdc692d7fa68534bab03e62e13fc2fb7b2be8aff1da94fdbf580290eb952"
    // );
    // const tokenRootContract = new ever.Contract(
    //   TokenDiceAbi,
    //   TokenRootContractAddress
    // );
    // const {value0: symbol} = await tokenRootContract.methods
    //   .symbol({ answerId: 0 })
    //   .call();
    // const resp2 = await tokenRootContract.methods
    //   .decimals({ answerId: 0 })
    //   .call();
    // console.log(resp1, resp2, "value0");
  },
  getContractBalance: async function (contractAddress) {
    let ever = this.ever();
    const TokenRootContractAddress = new Address(contractAddress);
    let resp = await ever.getBalance(TokenRootContractAddress);
    return BigNumber.from(resp);
  },
  getTotalSupply: async function () {
    let ever = this.ever();
    const TokenRootContractAddress = new Address(
      "0:8c6dcaa30727458527e99a479dae92a92a51c24e235e5b531659e201204d79ee"
    );
    const tokenRootContract = new ever.Contract(
      EverTokenRootAbi,
      TokenRootContractAddress
    );
    const { value0: totalSupply } = await tokenRootContract.methods
      .totalSupply({ answerId: 0 })
      .call();
    this.getTokenBalance();
  },
  getDecimals: async function () {
    let ever = this.ever();
    const TokenRootContractAddress = new Address(
      "0:8c6dcaa30727458527e99a479dae92a92a51c24e235e5b531659e201204d79ee"
    );
    const tokenRootContract = new ever.Contract(
      EverTokenRootAbi,
      TokenRootContractAddress
    );
    const symbol = await tokenRootContract.methods
      .symbol({ answerId: 0 })
      .call();
    const decimals = await tokenRootContract.methods
      .decimals({ answerId: 0 })
      .call();
  },
  isWalletConnected: async function () {
    let isWalletInstalled = await this.isWalletInstalled();
    if (!isWalletInstalled) {
      return false;
    }
    let ever = this.ever();
    let isConnected = false;
    try {
      const currentProviderState = await ever.getProviderState();
      isConnected = currentProviderState.permissions.accountInteraction.address
        ? true
        : false;
    } catch (error) {}
    return isConnected;
  },
  getCurrentWalletAddress: async function () {
    const currentProviderState = await this.ever().getProviderState();
    return currentProviderState.permissions.accountInteraction.address;
  },
  getTokenBalance: async function (contractAddress) {
    // let contractAddress =
    //   "0:8c6dcaa30727458527e99a479dae92a92a51c24e235e5b531659e201204d79ee";

    let balance = 0;

    let ever = this.ever();

    const TokenRootContractAddress = new Address(contractAddress);
    const tokenRootContract = new ever.Contract(
      EverTokenRootAbi,
      TokenRootContractAddress
    );

    const userAddress = await this.getCurrentWalletAddress();

    const { value0: userTokenWalletAddress } = await tokenRootContract.methods
      .walletOf({ answerId: 0, walletOwner: userAddress })
      .call();

    const userTokenWalletContract = new ever.Contract(
      EverTokenWalletAbi,
      userTokenWalletAddress
    );

    const { state } = await userTokenWalletContract.getFullState();
    const isDeployed = state?.isDeployed;
    if (isDeployed) {
      const { value0: tokenBalance } = await userTokenWalletContract.methods
        .balance({ answerId: 0 })
        .call();
      balance = tokenBalance;
    }
    return balance;
  },
  makePayload: async function (chainIds, amounts, receivers) {
    let ever = this.ever();
    const payload = (
      await ever.packIntoCell({
        data: {
          _chainIds: chainIds,
          _amounts: amounts,
          _receivers: receivers,
        },
        structure: [
          { name: "_chainIds", type: "uint64[]" },
          { name: "_amounts", type: "uint256[]" },
          { name: "_receivers", type: "uint256[]" },
        ],
      })
    ).boc;
    return payload;
  },
  makeSendTransaction: async function (
    chainIds,
    amounts,
    receivers,
    contractAddress,
    gasAddress,
    amountOfTokens
  ) {
    let ever = this.ever();

    const currentProviderState = await ever.getProviderState();
    const userAddress =
      currentProviderState.permissions.accountInteraction.address;
    const publicKey =
      currentProviderState.permissions.accountInteraction.publicKey;

    const TokenRootContractAddress = new Address(contractAddress);
    const tokenRootContract = new ever.Contract(
      EverTokenRootAbi,
      TokenRootContractAddress
    );

    const { value0: userTokenWalletAddress } = await tokenRootContract.methods
      .walletOf({ answerId: 0, walletOwner: userAddress })
      .call();

      const payload = await this.makePayload(chainIds, amounts, receivers);

    const userTokenWalletContract = new ever.Contract(
      EverTokenWalletAbi,
      userTokenWalletAddress
    );

    const transaction = await userTokenWalletContract.methods
      .transfer({
        amount: amountOfTokens,
        recipient: gasAddress,
        deployWalletValue: "100000000", //0.1 ever
        remainingGasTo: userAddress,
        notify: true,
        payload: payload,
      })
      .send({
        amount: "500000000",
        from: userAddress,
      })
      .catch((e) => {
        if (e.code === 3) {
          // rejected by a user
          return Promise.resolve(null);
        } else {
          // The message has expired or some other
          // perform any necessary error handling
          return Promise.reject(e);
        }
      });
    return transaction;
  },
  checkTransactionState: function (transactionState) {
    return transactionState && !transactionState?.aborted && transactionState.endStatus == "active";
  },
  waitTransactionEnd: async function (transaction) {
    let ever = this.ever();
    let subTransactions = [];
    const subscriber = new ever.Subscriber();
    await subscriber
      .trace(transaction)
      .tap((tx_in_tree) => {
        subTransactions.push(tx_in_tree.id.hash)
      })
      .finished();
    return subTransactions;
  },
  getProvider: function () {
    return this.ever();
  },
  getContractListener: function (contractAddress) {
    const provider = this.getProvider();
    const subscriber = new provider.Subscriber();

    const exampleContract = new provider.Contract(
      EverContractAbi,
      contractAddress
    );

    return exampleContract.events(subscriber);
  },
  makeContractListener: async function () {
    let provider = this.getProvider();
    const subscriber = new provider.Subscriber();

    const exampleContract = new provider.Contract(
      EverContractAbi,
      "0:27f815a231115000955b651fec1c954bb43265a5364d2326c3bfc5de558347a3"
    );

    const contractEvents = exampleContract.events(subscriber);

  },
  checkTransactionEvent: async function () {
    let ever = this.ever();
    const contract = new ever.Contract(
      EverContractAbi,
      "0:27f815a231115000955b651fec1c954bb43265a5364d2326c3bfc5de558347a3"
    );

    // Decode transaction
    // const decodedTransaction = await contract.decodeTransaction({
    //   transaction: tx,
    //   methods: ["setVariable"],
    // });

    const decodedTransactionEvents = await contract.decodeTransactionEvents({
      transaction:
        "2f68bf540228002e8f25b5e80c169af65432d1ba2cbc4b148fb6f0575d3325c5",
    });


    // console.log('Decoded transaction:', decodedTransaction);
  },
  showPastEvents: async function (contractAddress) {
    const provider = this.ever()
    const subscriber = new provider.Subscriber();

    const exampleContract = new provider.Contract(
      EverContractAbi,
      contractAddress
    );

    const contractEvents = exampleContract.events(subscriber);

    // console.log(contractEvents,'contractEvents')
    // contractEvents.unsubscribe();

    // const pastEvents = await exampleContract.getPastEvents({
    //   // filter: 'StateChanged',
    //   // range: {
    //   //   fromUtime: 1682651393814,
    //   // },
    // });
    // console.log(pastEvents, "pastEvents");
  },
  getNetworkMinAmount: async function (currentNetwork) {
    let ever = this.ever();

    const gasContract = new ever.Contract(
      EverContractAbi,
      currentNetwork.gasSenderAddress
    );

    let amount = MIN_NETWORK_TRANSFER_AMOUNT;

    try {
      const {minUsdAmountPerChain:networkAmount } = await gasContract.methods
      .minUsdAmountPerChain()
      .call();
      amount = networkAmount;
    } catch (error) {
      
    }

    return amount
  },

};
