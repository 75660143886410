import { SHOW_DECIMAL_CNT } from "../../constants/TransferConstants";
import {
  getExploreName,
  getNetworkExplorer,
  getNetworkImage,
  getNetworkName,
  getNetworkSymbol,
  getNetworkTransactionListUrl,
  getNetworkTransactionUrl,
  getNetworks,
} from "../../utils/NetworkUtil";
import { ymClickTxScan } from "../../utils/YandexUtl";

const PENDING = "Pending";
const COMPLETED = "Completed";
const REJECTED = "Rejected";

export default function GasTransfersRow({ transfer }) {
  return (
    <div
      className={
        "gas-station__networks-table-row " +
        (transfer.isRejected
          ? "gas-station__networks-table-row_rejected"
          : "gas-station__networks-table-row_check")
      }
    >
      {transfer.isRejected && (
        <img
          className="gas-station__networks-table-row-icon"
          src="img/rejected-icon.svg"
          width="34"
          height="34"
          alt=""
        />
      )}

      {!transfer.isRejected && !transfer.isRecieved && (
        <div className="gas-station__networks-table-row-icon gas-station__networks-table-row-icon_pending">
          <span className="loader"></span>
        </div>
      )}

      {transfer.isRecieved && (
        <img
          className="gas-station__networks-table-row-icon"
          src={
            "/img/" +
            (transfer.isRecieved ? "completed-icon.svg" : "pending-icon.svg")
          }
          width="34"
          height="34"
          alt=""
        />
      )}

      <div className="gas-station__networks-table-row-item i-asset">
        <span className="gas-station__network-icon">
          <img src={getNetworkImage(transfer.route)} alt="" />
        </span>
        <div className="gas-station__networks-table-row-item-title">
          <span className="gas-station__networks-table-row-item-name">
            {transfer.amountNative
              ? transfer.amountNative.toFixed(SHOW_DECIMAL_CNT)
              : null}{" "}
            {getNetworkSymbol(transfer.route)}
            <span
              className={
                "check-label " +
                (transfer.isRejected
                  ? "check-label_rejected"
                  : transfer.isRecieved
                  ? "check-label_completed"
                  : "check-label_pending blink")
              }
            >
              {transfer.isRejected
                ? REJECTED
                : transfer.isRecieved
                ? COMPLETED
                : PENDING}
            </span>
          </span>
          <span className="gas-station__networks-table-row-item-status">
            on {getNetworkName(transfer.route)}
            <span className="gas-station__networks-table-row-item-percent">
              {transfer.amountPercent}%
            </span>
          </span>
        </div>
      </div>
      {transfer.inTransactionHash && (
        <a
          className="gas-station__networks-table-row-item white-link i-ether-link"
          href={getNetworkTransactionUrl(
            transfer.route,
            transfer.inTransactionHash
          )}
          target={"_blank"}
          rel={"noreferrer"}
          onClick={() => ymClickTxScan()}
        >
          <img src="../img/link-icon.svg" alt="" />
          Check on {getExploreName(transfer.route)}
        </a>
      )}
      {!transfer.inTransactionHash && (
        <a
          className="gas-station__networks-table-row-item white-link i-ether-link"
          href={getNetworkTransactionListUrl(
            transfer.route,
            transfer.address
          )}
          target={"_blank"}
          rel={"noreferrer"}
          onClick={() => ymClickTxScan()}
        >
          <img src="../img/link-icon.svg" alt="" />
          Check on {getExploreName(transfer.route)}
        </a>
      )}
    </div>
  );
}
