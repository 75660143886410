import { BigNumber, Contract, ethers, utils } from "ethers";
import { showErrorDialog } from "../../features/errorService";
import { isZeroAddress } from "../../features/walletService/utils";
// import { setError } from "../../features/walletService/walletService";
import ERC20Abi from "../../utils/ERC20Abi";
import { getNetworks } from "../../utils/NetworkUtil";
import { showNotifaction } from "../../features/dialogs/notificationPopupSlice";
import { ALERT_WARNING } from "../../constants/AlertTypes";
import detectEthereumProvider from '@metamask/detect-provider'
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";
import { EvmAbiContractAbi } from "../../abis/EvmContractAbi";
import { MAX_NETWORK_TRANSFER_AMOUNT, MAX_TOTAL_TRANSFER_AMOUNT, MIN_NETWORK_TRANSFER_AMOUNT, MIN_TOTAL_TRANSFER_AMOUNT } from "../../constants/TransferConstants";
const ERROR_METAMASK_NOT_INSTALLED = "Metamask extension is not installed";
const ERROR_METAMASK_IS_NOT_MAIN = "You have many wallet extension and metamask is not main. Please, turn off other wallets in browser extension";
const CHAIN_CHANGE_ALREADY_PROCCESS_ERROR = 'Please check metamask. Another request for changing chain wait your action'

export const MetamaskWebProvider = {
  ethereum: window.ethereum,
  provider: null,
  isMetamaskInstalled:async function(){
    const provider = await detectEthereumProvider();
    this.provider = provider;
    return (typeof this.provider == 'object'  ? true : false);
  },
  isMetaMainWallet:function(){
    return this.provider == window.ethereum && this.provider.isMetaMask;
  },
  isConnected: async function(){
    let isInstalled = await this.isMetamaskInstalled();
    return  this.isMetaMainWallet()
  },
  checkingMetaExtension: async function(){
    let res = {
      hasError: false,
      errorMessage:null
    }
    let isInstalled = await this.isMetamaskInstalled();
    if (!isInstalled) {
      res.hasError = true;
      res.errorMessage = ERROR_METAMASK_NOT_INSTALLED;
    }
    if (!res.hasError && !this.isMetaMainWallet()) {
      res.hasError = true;
      res.errorMessage = ERROR_METAMASK_IS_NOT_MAIN;
    }
    return res; 
  },
  autoConnect: async function (dispatch, walletInfo) {
    let validate = await this.checkingMetaExtension();
    if(validate.hasError){
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: validate.errorMessage }));
      return walletInfo;
    }
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (accounts.length > 0) {
      const loadedNetwork = getNetworks().find( v=> v.wallets.includes(PROVIDER_METAMASK) && v.chainId == this.ethereum.networkVersion);
      console.log(loadedNetwork,'loadedNetwork')
      walletInfo.networkId = loadedNetwork ? loadedNetwork.id : 0; 
      walletInfo.accountAddress = accounts[0];
      walletInfo.networkChainId = this.ethereum.networkVersion;
      walletInfo.isConnected = true;
      walletInfo.balance = await this.getNativeBalance(accounts[0])
    }
    return walletInfo;
  },
  connect: async function (dispatch, walletInfo) {
    let validate = await this.checkingMetaExtension();
    if(validate.hasError){
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: validate.errorMessage }));
      return walletInfo;
    }
    try {
      let accounts = await this.ethereum.request({
        method: "eth_requestAccounts",
      });
      const loadedNetwork = getNetworks().find( v=> v.wallets.includes(PROVIDER_METAMASK) && v.chainId == this.ethereum.networkVersion);
      walletInfo.networkId = loadedNetwork ? loadedNetwork.id : 0; 
      walletInfo.accountAddress = accounts[0];
      walletInfo.networkChainId = this.ethereum.networkVersion;
      walletInfo.isConnected = true;
    } catch (error) {
      let errorMessage  = (error && error?.code == -32002) ? 'Please unlock metamask' : (error?.message || error)
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: errorMessage }));
    }
    return walletInfo;
  },
  getNativeBalance: async function (address) {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    let bal = await provider.getBalance(address);

    return parseFloat(ethers.utils.formatEther(bal)).toFixed(4);
  },
  requestApprove: async function (tokenAddress, accountAddress, amount) {
    let abi = [
      "function approve(address _spender, uint256 _value) public returns (bool success)",
    ];
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    // let provider = ethers.getDefaultProvider('ropsten')
    let contract = new ethers.Contract(tokenAddress, abi, provider);
    const res = await contract.approve(accountAddress, amount);
    return res;
  },
  getBalance: async function (token, walletInfo, network) {
    return isZeroAddress(token.contractAddress)
      ? this.getNativeBalance(walletInfo.accountAddress)
      : this.getTokenBalance(token, walletInfo);
  },
  getTokenBalance: async function (token, walletInfo) {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    const contract = new Contract(token.contractAddress, ERC20Abi, provider);
    const balance = await contract.balanceOf(walletInfo.accountAddress);
    return utils.formatUnits(balance, token.decimals);
  },
  addChain: async function (selectedNetwork, dispatch) {
    let isAdded = false;
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [selectedNetwork],
      });
      isAdded = true;
    } catch (error) {
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: error?.message || error  }));
    }
    return isAdded;
  },
  addChainById: async function (networkChainId, dispatch) {
    const network = getNetworks().find((v) => v.chainId == networkChainId);
    let selectedNetwork = this.makeAddNetwork(network);
    let isAdded = false;
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [selectedNetwork],
      });
      isAdded = true;
    } catch (error) {
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: error?.message || error  }));
    }
    return isAdded;
  },
  makeAddNetwork: function (network) {
    return {
      chainId: ethers.utils.hexValue(parseInt(network.chainId)),
      chainName: network.chainName,
      nativeCurrency: {
        name: network.nativeCurrency.name,
        decimals: network.nativeCurrency.decimals,
        symbol: network.nativeCurrency.symbol,
      },
      rpcUrls: network.rpcUrls,
      blockExplorerUrls: network.blockExplorerUrls,
    };
  },
  changeChain: async function (walletInfo, dispatch, network) {
    let isChanged = false;
    let selectedNetwork = this.makeAddNetwork(network);
    if (this.ethereum.networkVersion !== walletInfo.chainId) {
      try {
        await this.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: selectedNetwork.chainId }],
          // params: [{ chainId: utils.toHex(chainId) }],
        });
        isChanged = true;
      } catch (err) {
        if (err.code == 4902) {
          isChanged = this.addChain(selectedNetwork, dispatch);
        } else {
          this.processChainError(err,dispatch);
        }
      }
    }
    return isChanged;
  },
  processChainError:function(error,dispatch){
    let errMessage;
    if(error.code && error.code == -32002){
      errMessage = CHAIN_CHANGE_ALREADY_PROCCESS_ERROR;
    }else{
      errMessage = error.message || error
    }
    dispatch(showNotifaction({alertType:ALERT_WARNING,caption:errMessage}))
  },
  calcGas: async function () {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    const price = await provider.getGasPrice();
    const str = ethers.utils.formatEther(price);
    const eth = str * 2;
    const estimation = ethers.utils.parseEther(eth.toFixed(18));
    return estimation._hex;
  },
  calsTransGas: async function (transactionData) {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    const price = await provider.estimateGas({
      to: transactionData.to,

      // `function deposit() payable`
      data: transactionData.data,

      // 1 ether
      // value: parseEther("1.0")
    });
    return price;
  },
  sendTransaction: async function (transactionData, dispatch) {
    dispatch(showErrorDialog(null));
    let hasError = true;
    let txHash = null;

    const gasPrice = transactionData.gasPrice
      ? transactionData.gasPrice
      : await this.calcGas();

    // const gas = transactionData.gasLimit
    //   ? transactionData.gasLimit
    //   : await this.calsTransGas(transactionData);

    const transactionParameters = {
      nonce: "0x00", // ignored by MetaMask
      gasPrice: gasPrice, //transactionData.gasPrice ? transactionData.gasPrice : '0x09184e72a000', // customizable by user during MetaMask confirmation.
      gas: transactionData.gasLimit, // ?  transactionData.gasLimit : '0x5208', // customizable by user during MetaMask confirmation.
      to: transactionData.to, // Required except during contract publications.
      from: this.ethereum.selectedAddress, // must match user's active address.
      value: transactionData.value, // Only required to send ether to the recipient from the initiating external account.
      data: transactionData.data, // Optional, but used for defining smart contract creation and interaction.
      chainId: transactionData.chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    try {
      txHash = await this.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      hasError = false;
    } catch (error) {
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: error?.message || error  }));
    }
    return {
      hasError: hasError,
      txHash: txHash,
    };
  },
  getNetworkMinAmount:async function(currentNetwork){
    const provider = new ethers.providers.JsonRpcProvider(
      currentNetwork.rpcUrls[0]
    );
    const signer = provider.getSigner(0);

    const customContract = new ethers.Contract(
      currentNetwork.gasSenderAddress,
      EvmAbiContractAbi,
      provider
    );

    let amount = MIN_NETWORK_TRANSFER_AMOUNT;
    try {
      amount = await customContract.minUsdAmountPerChain();
      amount = BigNumber.from(amount).toString();
    } catch (error) {
    }
    return amount;
  },
  getNetworkLimits:async function(currentNetwork){
    const provider = new ethers.providers.JsonRpcProvider(
      currentNetwork.rpcUrls[0]
    );
    const signer = provider.getSigner(0);

    const customContract = new ethers.Contract(
      currentNetwork.gasSenderAddress,
      EvmAbiContractAbi,
      provider
    );
    let limits = {
      minUsdAmount: MIN_TOTAL_TRANSFER_AMOUNT,
      maxUsdAmount: MAX_TOTAL_TRANSFER_AMOUNT,
      minUsdAmountPerChain: MIN_NETWORK_TRANSFER_AMOUNT,
      maxUsdAmountPerChain: MAX_NETWORK_TRANSFER_AMOUNT,
    };
    

    try {
      let amount = await customContract.minUsdAmountPerChain();
      limits.minUsdAmountPerChain = BigNumber.from(amount).toNumber();

      amount = await customContract.maxUsdAmountPerChain();
      limits.maxUsdAmountPerChain = BigNumber.from(amount).toNumber();

      amount = await customContract.minUsdAmount();
      limits.minUsdAmount = BigNumber.from(amount).toNumber();

      amount = await customContract.maxUsdAmount();
      limits.maxUsdAmount = BigNumber.from(amount).toNumber();
    } catch (error) {
    }
    return limits;
  }

};
