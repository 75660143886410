import { useDispatch, useSelector } from "react-redux";
import { cookieTogglePopup } from "../../features/cookiePopupSlice";

export default function CookiePopup({}) {
  let cookiePopupManager = useSelector((state) => state.cookiePopupManager);
  let dispatch = useDispatch();

  if (!cookiePopupManager.show) {
    return null;
  }

  return (
    <div className="cookies-popup">
      <span className="cookies-popup__caption">
        We use cookies to improve your experience
      </span>
      <div className="cookies-popup__cta">
        <button className="btn btn_dark" onClick={() => dispatch(cookieTogglePopup(false))}>Ok</button>
        <button className="btn btn_gray" onClick={() => dispatch(cookieTogglePopup(false))}>Necessary cookies only</button>
      </div>
    </div>
  );
}
