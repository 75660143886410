import React from "react";
import { useDispatch } from "react-redux";
import { PROVIDER_EVERWALLET, PROVIDER_METAMASK } from "../../../features/walletService/walletService";
import { MetamaskWebProvider } from "../../../services/metamask/MetamaskWebProvider";
import { isMobileDevice } from "../../../utils/BrowserUtil";

function ChooseWalletItem({ walletService, onSelect, isTermAgreed }) {
  const dispatch = useDispatch();

  const isMobile = isMobileDevice();

  if (isMobile) {
    if (
      walletService.key == PROVIDER_METAMASK &&
      !MetamaskWebProvider.ethereum
    ) {
      const metamaskAppDeepLink =
        "https://metamask.app.link/dapp/" + window.location.host;
      return (
        walletService.key == PROVIDER_METAMASK && (
          <a href={metamaskAppDeepLink}>
            <button
              className="choose-wallet-popup__wallet-item"
              // onClick={() => window.open(metamaskAppDeepLink,'_self')}
            >
              <div className="choose-wallet-popup__wallet-item-title">
                <img src={walletService.img} alt={walletService.title} />
                <span>{walletService.title}</span>
              </div>
            </button>
          </a>
        )
      );
    }
    if (walletService.key == PROVIDER_EVERWALLET) {
      return <></>;
    }
  }

  return (
    <button
      key={walletService.key}
      className="choose-wallet-popup__wallet-item"
      onClick={() => onSelect(walletService.key)}
    >
      {!isTermAgreed && (
        <>
          <span>{walletService.title}</span>
          <picture>
            <img src={walletService.img} alt={walletService.title} />
          </picture>
        </>
      )}
      {isTermAgreed && (
        <div className="choose-wallet-popup__wallet-item-title">
          <img src={walletService.img} alt={walletService.title} />
          <span>{walletService.title}</span>
        </div>
      )}
    </button>
  );
}
export default ChooseWalletItem;
