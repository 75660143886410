import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  type: null,
};

export const TYPE_DISCLAIMER = "disclaimer";

export const PopupManagerSlice = createSlice({
  name: " PopupManagerSlice",
  initialState: [],
  reducers: {
    popupToggleByName: (state, action) => {
      let index = state.findIndex((v) => v.type == action.payload.type);
      if (index == -1) {
        state.push({
          type: action.payload.type,
          show: action.payload.isShow,
        });
      } else {
        const netStates = [...state]; //making a new array
        netStates[index].show = action.payload.isShow;
        state = [...netStates];
      }
    },
  },
});

export const { popupToggleByName } = PopupManagerSlice.actions;

export default PopupManagerSlice.reducer;
