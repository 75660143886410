import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showErrorPopup: false,
  error: null,
};
export const errorService = createSlice({
  name: "error",
  initialState,
  reducers: {
    showErrorDialog: (state, action) => {
      state.showErrorPopup = true;
      state.error = action.payload;
    },
    closeErrorDialog: (state) => {
      state.showErrorPopup = false;
      state.error = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showErrorDialog, closeErrorDialog } = errorService.actions;

export default errorService.reducer;
