import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  venomProvider: null,
  isConnected: false,
  makeConnectRequested: false,
};

export const venomwalletService = createSlice({
  name: "venomWalletService",
  initialState,
  reducers: {
    venomConnectInit: (state) => {
      state.makeConnectRequested = true;
    },
    venomConnected: (state) => {
      state.makeConnectRequested = false;
    },
    connected: (state,) => {
      state.makeConnectRequested = false;
    },
    setVenomProvider:(state,action)=>{
      state.venomProvider = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { venomConnectInit,setVenomProvider,venomConnected } = venomwalletService.actions;

export default venomwalletService.reducer;
