import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";
import {
  connectWallet,
  disconnect,
  walletConnectDialogToggle,
} from "../../features/walletService/walletService";
import { MouseClickOutside } from "../../hooks/MouseClickOutside";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  getNetworkImageByChainId,
  getNetworkTokenSymbol,
  WalletList,
} from "../../utils/NetworkUtil";
import {
  TYPE_DISCLAIMER,
  popupToggleByName,
} from "../../features/PopupManagerSlice";

export function WalletListPopup() {
  let show = useSelector(
    (state) => state.walletServiceProvider.showWalletConnect
  );
  let dispatch = useDispatch();

  // }

  return (
    <div
      className="btn-select__popup connect-wallets-popup connect-wallets-popup_dark"
      id="walletlist"
    >
      <div className="connect-wallets-popup__content">
        <h3 className="connect-wallets-popup__title">Select a wallet</h3>
        <p className="connect-wallets-popup__description">
          By connecting your wallet, you agree to our {" "}
          <a
            className="white-link"
            href="#"
            onClick={() =>
              dispatch(
                popupToggleByName({
                  isShow: true,
                  type: TYPE_DISCLAIMER,
                })
              )
            }
          >
            disclaimer
          </a>
        </p>
        <div className="connect-wallets-popup__wallets">
          {WalletList &&
            WalletList.map((walletService) => (
              <div
                className="connect-wallets-popup__wallet"
                key={walletService.key}
              >
                <WalletListItem walletService={walletService} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function WalletListItem({ walletService }) {
  let dispatch = useDispatch();
  let allWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );
  const [tokenBalance, setTokenBalance] = useState(0.0);
  let show = useSelector(
    (state) => state.walletServiceProvider.showWalletConnect
  );

  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  useEffect(() => {
    getBalanceFromMeta();
  }, [show]);

  const getBalanceFromMeta = async () => {
    if (walletInfo.networkChainId && walletService.key == PROVIDER_METAMASK) {
      let balance = await MetamaskWebProvider.getNativeBalance(
        walletInfo.accountAddress
      );
      setTokenBalance(balance);
    }
  };

  if (allWallets.findIndex((v) => v.providerType == walletService.key) != -1) {
    let walletInfo = allWallets.find(
      (v) => v.providerType == walletService.key
    );

    return (
      <>
        <div className="connect-wallets-popup__wallet-connected">
          <div className="connect-wallets-popup__wallet-icons">
            <img
              className="connect-wallets-popup__wallet-icon"
              src={
                WalletList.find((v) => v.key == walletInfo.providerType)
                  ?.smallImg
              }
              alt=""
            />
            <img
              className="connect-wallets-popup__wallet-network-icon"
              src={getNetworkImageByChainId(walletInfo.networkChainId)}
              alt=""
            />
          </div>
          <div className="connect-wallets-popup__wallet-title">
            {walletInfo.accountAddress && (
              <span className="connect-wallets-popup__wallet-name">
                {`${walletInfo.accountAddress.slice(
                  0,
                  6
                )}...${walletInfo.accountAddress.slice(-4)}`}
              </span>
            )}
            <span className="connect-wallets-popup__wallet-network">
              {walletInfo.balance}{" "}
              {getNetworkTokenSymbol(walletInfo.networkChainId)}
            </span>
          </div>
        </div>
        <button
          className="connect-wallets-popup__disconnect-btn"
          onClick={() => dispatch(disconnect(walletService.key))}
        >
          Disconnect
        </button>
      </>
    );
  }

  return (
    <>
      <img
        className="connect-wallets-popup__wallet-icon"
        src={walletService.img}
        alt=""
      />
      <span className="connect-wallets-popup__wallet-name">
        {walletService.title}
      </span>
      <button
        className="connect-wallets-popup__wallet-btn btn btn_blue"
        onClick={() => dispatch(connectWallet(walletService.key))}
        disabled={walletService.disabled}
      >
        Connect wallet
      </button>
    </>
  );
}
