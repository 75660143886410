import { createSlice } from "@reduxjs/toolkit";
import { isCoockieSeted, isCookeAccept, setCookieForever } from "../utils/BrowserUtil";

const initialState = {
  show: !isCoockieSeted(isCookeAccept),
};

export const cookiePopupSlice = createSlice({
  name: "cookiePopupTogler",
  initialState,
  reducers: {
    cookieTogglePopup: (state, action) => {
      state.show = action.payload;
      if (!state.show) {
        setCookieForever(isCookeAccept);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { cookieTogglePopup } = cookiePopupSlice.actions;

export default cookiePopupSlice.reducer;
