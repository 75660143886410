import { useDispatch, useSelector } from "react-redux";
import {
  connectWallet,
  setConnectingWallet,
  showConnectTvmPopup,
} from "../../features/walletService/walletService";
import { WalletList, getNetworkImage } from "../../utils/NetworkUtil";

export default function TvmConnectPopup({}) {
  let walletService = useSelector((state) => state.walletServiceProvider);
  let dispatch = useDispatch();

  return (
    <div
      className={
        "connect-venom-popup popup dark " +
        (walletService.showTVMConnectWalletPopup ? "active" : "")
      }
    >
      {walletService.showTVMConnectWalletType && (
        <div className="popup__container">
          <a
            className="popup__close"
            href="#"
            onClick={() => dispatch(showConnectTvmPopup())}
          ></a>

          <h1 className="popup__title">Connect to the wallet</h1>

          <span className="connect-venom-popup__description">
            It is necessary to connect{" "}
            {walletService.showTVMConnectWalletType.chainName} Wallet to
            identify the address
          </span>

          <button
            className="btn btn_dark btn_primary"
            onClick={() => {
              dispatch(showConnectTvmPopup());
              if (walletService.showTVMConnectWalletType.wallets.length > 0) {
                dispatch(
                  connectWallet(
                    walletService.showTVMConnectWalletType.wallets[0]
                  )
                );
              }
            }}
          >
            <img src={walletService.showTVMConnectWalletType.walletImg} alt="" />
            Connect {walletService.showTVMConnectWalletType.chainName} Wallet
          </button>
        </div>
      )}
    </div>
  );
}
